import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useLanguage } from '../../../../Translations/LenguageContext';
import Spinner from '../../../../components/Spinner';


const DeletePropertyModal = ({ show, handleClose, onDeleteConfirmed, propertyName }) => {
    const { t } = useLanguage();
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const handleDelete = () => {
        setLoading(true);
        onDeleteConfirmed()
            .finally(() => handleModalClose());
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleModalClose = () => {
        setInputValue("");
        setErr({ message: "", display: false });
        setLoading(false)
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} className="modal_contacts_delete">
            <div className='header_modal'>
                <div className='modal_title_cont_prop_delete'>
                    <p>{t.new_property.modal.title_delete}</p>
                </div>
                <button className='close_x_modale' onClick={handleModalClose}>
                    <i className='fa-solid fa-x'></i>
                </button>
            </div>
            <div className='body_modal_delete'>
                <p className='body_delete_modal'>{propertyName}</p>
                <p className='modal_delete_mex' dangerouslySetInnerHTML={{ __html: t.new_property.modal.action_title }}></p>
                <p className='modal_delete_mex'>{t.new_property.modal.type_delete}</p>

                <input
                    className='delete_confirmation_input'
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={propertyName}
                />

                {err.display && <p className="error_message">{err.message}</p>}
            </div>

            <div className='footer_modal'>
                <div className='footer_btn_cont'>
                    <button
                        className={`save_modal_btn delete_btn ${loading || inputValue !== propertyName ? "loading_opacity_btn" : ""}`}
                        onClick={handleDelete}
                        disabled={loading || inputValue !== propertyName}
                    >
                        {t.new_property.modal.delete_btn}
                    </button>
                    <button
                        className='close_modal_btn'
                        onClick={handleModalClose}
                        disabled={loading}
                    >
                        {t.new_property.modal.back_btn}
                    </button>
                </div>
                {loading && <Spinner loading={loading} err={err}></Spinner>}
            </div>
        </Modal>
    );
};

export default DeletePropertyModal;
