import React, { useEffect, useState } from "react";
import TransactionsAnimations from "./TransactionsAnimations/TransactionsAnimations";
import { useLanguage } from "../Translations/LenguageContext";

const HalfPhoneAnimations = () => {
    const {t} = useLanguage()
    const [startAnimations, setStartAnimations] = useState(false);

    // Start animations immediately after the component mounts
    useEffect(() => {
        setStartAnimations(true);
    }, []); // Run once on mount

    return (
        <div className="payment_transactions_cont">
            <div className="payment_transaction_title_cont">
                <TransactionsAnimations start={startAnimations} />
            </div>
            <div className="payment_transaction_title_cont">
                <p className="transaction_title">{t.transactions.title}</p>
                <p className="transaction_subtitle">{t.transactions.subtitle}</p>
                <div className="transaction_desc">
                    <div className="bullet_points">
                        <p className="bullet_point_explanation_title">{t.transactions.first_title}</p>
                        <p className="bullet_point_explanation">{t.transactions.first_desc}</p>
                    </div>
                    <div className="bullet_points">
                        <p className="bullet_point_explanation_title">{t.transactions.second_title}</p>
                        <p className="bullet_point_explanation">{t.transactions.second_desc}</p>
                    </div>
                    <div className="bullet_points">
                        <p className="bullet_point_explanation_title">{t.transactions.third_title}</p>
                        <p className="bullet_point_explanation">{t.transactions.third_desc}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HalfPhoneAnimations;
