import React from "react";

function TenantPayments(){
    return(
        <div className="main_view_section">
            <p className="title_main_view">Richieste di pagamento</p>
            <div className="tenant_payment_req">

            </div>
        </div>
    )
}

export default TenantPayments