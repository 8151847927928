import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { BiHomeAlt2, BiCalendar, BiUser, BiSolidReport } from "react-icons/bi";
import { BsHouses, BsPeople } from "react-icons/bs";
import { RiTranslate2 } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { FaBuildingUser } from "react-icons/fa6";
import { TbUserScan } from "react-icons/tb";
import { PiHouseSimple } from "react-icons/pi";
import { PiCubeFocusFill } from "react-icons/pi";
import { BsShopWindow } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa6";

function AgencySidebar({ onNavigate, userData }) {
    const { t, changeLanguage, language } = useLanguage();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState('');
    const [isPropertiesExpanded, setPropertiesExpanded] = useState(false);
    const [isTentansExpanded, setTenantsExpanded] = useState(false);
    const [properties, setProperties] = useState({})
    const userId = sessionStorage.getItem("user_id");


    useEffect(() => {
        const pathName = location.pathname.split('/').pop();
        setActiveLink(pathName);
    }, [location.pathname]);

    const handleLanguageChange = (event) => {
        changeLanguage(event.target.value);
    };

    const handleLinkClick = (link) => {
        setActiveLink(link);
        onNavigate(link);
    };

    const getLinkStyle = (link) => {
        return activeLink === link ? { color: "var(--textColor)" } : {};
    };

    console.log(userData)

    const fetchProperties = async () => {
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_properties', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProperties(data.properties || {});
            console.log(data.properties)
        } catch (error) {
            console.log(error)
        } finally {
            console.log("OK");
        }
    };

    useEffect(() => {
        fetchProperties();
    }, []);

    const togglePropertiesSection = (link) => {
        fetchProperties()
        setPropertiesExpanded(!isPropertiesExpanded);
        setActiveLink(link);
    };

    const toggleTenantsSection = (link) => {
        setTenantsExpanded(!isTentansExpanded);
        setActiveLink(link)
    }


    return (
        <div className="sidebar_cont">
            <div className="sidebar_body">
                <div className="link_container">
                    <p className="side_link" onClick={() => handleLinkClick('home')} style={getLinkStyle('home')}><i><BiHomeAlt2 /></i>{t.sidebar.home}</p>
                    <p className="side_link" onClick={() => handleLinkClick('bookings')} style={getLinkStyle('bookings')}><i><BiCalendar /></i>{t.sidebar.bookings}</p>
                    <p className="side_link" onClick={() => handleLinkClick('contacts')} style={getLinkStyle('contacts')}><i><BsPeople /></i>{t.sidebar.contacts}</p>
                </div>

                <div
                    className="side_link"
                    onClick={() => {
                        handleLinkClick('properties');
                    }}
                    style={getLinkStyle('properties')}
                >
                    <div className="main_side_link_cont">
                        <i><BsShopWindow /></i>
                        Agenzie e Sedi
                    </div>

                    <i onClick={() => {
                        togglePropertiesSection('properties')
                    }}
                        className={`fa-solid ${isPropertiesExpanded ? "fa-chevron-up" : "fa-chevron-down"} chevron_side_link`}></i>
                </div>
                <div className={`link_container_anim ${isPropertiesExpanded ? "link_container_expanded" : ""}`}>
                    <div className="side_link_opened" onClick={() => handleLinkClick('screening')}>
                        <div className="side_link_bar">
                            <div
                                className="side_link_bar_top"
                            ></div>
                            <div
                                className="side_link_bar_bottom"
                            ></div>
                        </div>
                        <p>Milano</p>
                    </div>
                    <div className="side_link_opened" onClick={() => handleLinkClick('screening')}>
                        <div className="side_link_bar">
                            <div
                                className="side_link_bar_top"
                            ></div>
                            <div
                                className="side_link_bar_bottom"
                            ></div>
                        </div>
                        <p>Firenze</p>
                    </div>
                    <div className="side_link_opened" onClick={() => handleLinkClick('screening')}>
                        <div className="side_link_bar">
                            <div
                                className="side_link_bar_top"
                            ></div>
                            <div
                                className="side_link_bar_bottom"
                            ></div>
                        </div>
                        <p>Torino</p>
                    </div>
                    <div className="side_link_opened" onClick={() => handleLinkClick('screening')}>
                        <div className="side_link_bar">
                            <div
                                className="side_link_bar_top"
                            ></div>
                            <div
                                className="side_link_bar_bottom"
                            ></div>
                        </div>
                        <p>Roma</p>
                    </div>
                </div>

                <div
                    className="side_link"
                    onClick={() => {
                        handleLinkClick('properties');
                    }}
                    style={getLinkStyle('properties')}
                >
                    <div className="main_side_link_cont">
                        <i><FaUserTie /></i>
                        Utenti
                    </div>

                    <i onClick={() => {
                        togglePropertiesSection('properties')
                    }}
                        className={`fa-solid ${isPropertiesExpanded ? "fa-chevron-up" : "fa-chevron-down"} chevron_side_link`}></i>
                </div>
                <div className={`link_container_anim ${isPropertiesExpanded ? "link_container_expanded" : ""}`}>
                    <div className="side_link_opened" onClick={() => handleLinkClick('screening')}>
                        <div className="side_link_bar">
                            <div
                                className="side_link_bar_top"
                            ></div>
                            <div
                                className="side_link_bar_bottom"
                            ></div>
                        </div>
                        <p>Simone Iannelli</p>
                    </div>
                    <div className="side_link_opened" onClick={() => handleLinkClick('screening')}>
                        <div className="side_link_bar">
                            <div
                                className="side_link_bar_top"
                            ></div>
                            <div
                                className="side_link_bar_bottom"
                            ></div>
                        </div>
                        <p>Marco Brumana</p>
                    </div>
                    <div className="side_link_opened" onClick={() => handleLinkClick('screening')}>
                        <div className="side_link_bar">
                            <div
                                className="side_link_bar_top"
                            ></div>
                            <div
                                className="side_link_bar_bottom"
                            ></div>
                        </div>
                        <p>Il lupo</p>
                    </div>
                </div>

                <div
                    className="side_link"
                    onClick={() => {
                        handleLinkClick('properties');
                    }}
                    style={getLinkStyle('properties')}
                >
                    <div className="main_side_link_cont">
                        <i className="fa-solid fa-file-pen"></i>
                        Finance
                    </div>

                    <i onClick={() => {
                        togglePropertiesSection('properties')
                    }}
                        className={`fa-solid ${isPropertiesExpanded ? "fa-chevron-up" : "fa-chevron-down"} chevron_side_link`}></i>
                </div>
                <div className={`link_container_anim ${isPropertiesExpanded ? "link_container_expanded" : ""}`}>
                    <div className="side_link_opened" onClick={() => handleLinkClick('screening')}>
                        <div className="side_link_bar">
                            <div
                                className="side_link_bar_top"
                            ></div>
                            <div
                                className="side_link_bar_bottom"
                            ></div>
                        </div>
                        <p>Fatturato</p>
                    </div>
                    <div className="side_link_opened" onClick={() => handleLinkClick('screening')}>
                        <div className="side_link_bar">
                            <div
                                className="side_link_bar_top"
                            ></div>
                            <div
                                className="side_link_bar_bottom"
                            ></div>
                        </div>
                        <p>Cash flow</p>
                    </div>
                </div>


                <div
                    className="side_link"
                    onClick={() => {
                        handleLinkClick('properties');
                    }}
                    style={getLinkStyle('properties')}
                >
                    <div className="main_side_link_cont">
                        <i><BsHouses /></i>
                        Portfolio
                    </div>

                    <i onClick={() => {
                        togglePropertiesSection('properties')
                    }}
                        className={`fa-solid ${isPropertiesExpanded ? "fa-chevron-up" : "fa-chevron-down"} chevron_side_link`}></i>
                </div>
                <div className={`link_container_anim ${isPropertiesExpanded ? "link_container_expanded" : ""}`}>
                    {isPropertiesExpanded && Object.entries(properties).map(([key, property], index, arr) => (
                        <div className="side_link_opened" key={key} onClick={() => handleLinkClick(key)}>
                            <div className="side_link_bar">
                                <div
                                    className="side_link_bar_top"
                                ></div>
                                <div
                                    className="side_link_bar_bottom"
                                ></div>
                            </div>
                            <p>
                                {property.title_property || "Le mie proprietà"}
                            </p>
                        </div>
                    ))}
                </div>

                <div
                    className="side_link"
                    style={getLinkStyle('tenants')}
                >
                    <div className="main_side_link_cont">
                        <i className="fa-solid fa-cube"></i>
                        Operations
                    </div>

                    <i onClick={() => {
                        toggleTenantsSection('tenants')
                    }}
                        className={`fa-solid ${isTentansExpanded ? "fa-chevron-up" : "fa-chevron-down"} chevron_side_link`}></i>
                </div>
                <div className={`link_container_anim ${isTentansExpanded ? "link_container_expanded" : ""}`}>
                    <div className="side_link_opened" onClick={() => handleLinkClick('screening')}>
                        <div className="side_link_bar">
                            <div
                                className="side_link_bar_top"
                            ></div>
                            <div
                                className="side_link_bar_bottom"
                            ></div>
                        </div>
                        <p><i><TbUserScan /></i>Screening</p>
                    </div>
                    <div className="side_link_opened" onClick={() => handleLinkClick('screening')}>
                        <div className="side_link_bar">
                            <div
                                className="side_link_bar_top"
                            ></div>
                            <div
                                className="side_link_bar_bottom"
                            ></div>
                        </div>
                        <p><i className="fa-solid fa-question"></i>Richieste</p>
                    </div>
                    <div className="side_link_opened" onClick={() => handleLinkClick('tenants')} >
                        <div className="side_link_bar">
                            <div
                                className="side_link_bar_top"
                            ></div>
                            <div
                                className="side_link_bar_bottom"
                            ></div>
                        </div>
                        <p><i><FaBuildingUser /></i>Affitti</p>
                    </div>
                </div>

            </div>

            <div className="sidebar_footer">
                <div className="link_container link_cont_footer_sidebar">
                    <div className="profile_cont_link">
                        <i><RiTranslate2 /></i>
                        <select className="language_select_sidebar" onChange={handleLanguageChange} value={language}>
                            <option value="en">English</option>
                            <option value="it">Italiano</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AgencySidebar;
