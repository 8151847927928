import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { useNavigate } from "react-router-dom";
import TenantCharts from "./TenantCharts";
import PersonalizeTableModal from "./PersonalizeTableModal";
import TenantAlert from "./TenantAlert";
import TenantsTable from "./TenantsTable";
import CompleteRegistration from "../CompleteRegistration/CompleteRegistration";
import TenantsPayment from "./TenantsPayment";

const Tenants = () => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [userId, setUserId] = useState(sessionStorage.getItem("user_id"));
    const [tenants, setTenants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [missingInfoCount, setMissingInfoCount] = useState(0);
    const [registrationStatus, setRegistrationStatus] = useState(3);

    useEffect(() => {
        let isMounted = true;

        const fetchProgress = async () => {
            const user_id = sessionStorage.getItem("user_id");
            if (!user_id) {
                setTimeout(() => {
                    navigate(`/login`);
                }, 3000);
                return;
            }

            try {
                const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/check_registration", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                if (data.completed_steps !== undefined && isMounted) {
                    setRegistrationStatus(data.completed_steps);
                }
            } catch (error) {
                console.error("Error fetching registration progress:", error);
            }
        };

        fetchProgress();

        return () => {
            isMounted = false;
        };
    }, [navigate]);


    const handleShow = () => {
        setShowModal(true);
    };
    const handleClose = () => setShowModal(false);

    useEffect(() => {
        CheckMissingInfo();
    }, [tenants]);

    function CheckMissingInfo() {
        const tenantsWithMissingInfo = Object.entries(tenants).filter(
            ([tenantId, tenant]) => {
                return (
                    !tenant.personal_information ||
                    !tenant.tenant_property_information ||
                    !tenant.tenant_document_information ||
                    !tenant.tenant_account
                );
            }
        );

        const count = tenantsWithMissingInfo.length;
        setMissingInfoCount(count);

        if (count > 0) {
            setIsAlertVisible(true);
            setShowAlert(true);
        } else {
            setIsAlertVisible(false);
            setShowAlert(false);
        }
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
        setTimeout(() => {
            setIsAlertVisible(false);
        }, 500);
    };

    const fetchTenantData = async () => {
        try {
            const response = await fetch(
                "https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_tenant_data",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id: userId }),
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            if (data.tenants) {
                setTenants(data.tenants);
            } else {
                throw new Error("No tenants found");
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTenantData();
    }, [userId]);

    const AddNewUserClick = () => {
        navigate("/control_panel/tenants/new-tenant");
    };

    const handleDropdownSelect = (action, tenantId) => {
        switch (action) {
            case "update":
                console.log("Edit tenant with ID:", tenantId);
                UpdateTenantClick(tenantId);
                break;
            case "complete":
                console.log("Complete tenant with ID:", tenantId);
                UpdateTenantClick(tenantId);
                break;
            default:
                break;
        }
    };

    const UpdateTenantClick = (tenant_id) => {
        navigate(`/control_panel/tenants/update-tenant/${tenant_id}`);
    };

    const getPaymentStatus = (tenant) => {
        if (!tenant.personal_information ||
            !tenant.tenant_property_information ||
            !tenant.tenant_document_information ||
            !tenant.tenant_account) {
            return (<div className="warning_table_cont">
                <i className="fa-solid fa-warning"></i>
                <p>Dati non presenti</p>
            </div>);
        }

        const { paymentType, personalizedDetail } = tenant.tenant_property_information;

        if (paymentType === "monthly") {
            return "Long-term";
        } else if (paymentType === "personalized") {
            if (personalizedDetail && personalizedDetail.selectedStartDate && personalizedDetail.selectedEndDate) {
                const currentDate = new Date();
                const startDate = new Date(personalizedDetail.selectedStartDate);
                const endDate = new Date(personalizedDetail.selectedEndDate);

                const oneDayAfterEndDate = new Date(endDate);
                oneDayAfterEndDate.setDate(endDate.getDate() + 1);

                if (currentDate >= startDate && currentDate < oneDayAfterEndDate) {
                    const remainingDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
                    return `${remainingDays} giorn${remainingDays > 1 ? "i" : "o"} rimast${remainingDays > 1 ? "i" : "o"}`;
                } else if (endDate < currentDate) {
                    return "Done";
                } else if (startDate > currentDate) {
                    const remainingDays = Math.ceil((currentDate - startDate) / (1000 * 60 * 60 * 24));
                    return `${remainingDays} giorni al check-in`;
                }
            }
        }

        return "Dati non presenti";
    };

    if (loading) {
        return (
            <div className="loading_mex_cont">
                <p className="loading_mex">{t.loading}</p>
                <i className="fa-solid fa-box-open"></i>
            </div>
        );
    }

    if (error) {
        return (
            <div className="loading_mex_cont_err">
                <p>{t.error}</p>
                <i className="fa-solid fa-box-open"></i>
            </div>
        );
    }

    return (
        <div className="main_view_section">
            {isAlertVisible && <TenantAlert onClose={handleCloseAlert} count={missingInfoCount} className={showAlert ? 'show' : ''} />}
            <p className="title_main_view">{t.tenants.tenants}</p>
            {registrationStatus !== 3 ? (
                <div className="tenants_payment">
                    <CompleteRegistration />
                    <TenantsPayment />
                </div>

            ) : (
                <div className="tenants_view">

                    <TenantCharts />
                    <div className="tenants_filter_cont">
                        <button className="add_property_btn" onClick={AddNewUserClick}>
                            <i className="fa-solid fa-plus"></i> Add tenant
                        </button>
                    </div>
                    <TenantsTable
                        tenants={tenants}
                        handleDropdownSelect={handleDropdownSelect}
                        getPaymentStatus={getPaymentStatus}
                        section={"tenant_section"}
                        fetchTenantData={fetchTenantData}
                    />
                    <PersonalizeTableModal
                        show={showModal}
                        handleClose={handleClose}
                        onChangesAdded={""}
                    />
                </div>
            )}
        </div>
    );
};

export default Tenants;
