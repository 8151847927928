import React from "react";
import stripeNation from "../../../../Geo/SupportedStripeCountries.json";
import { useLanguage } from "../../../../Translations/LenguageContext";

const BusinessInformationTenantForm = ({ formData, handleChange, errorFields }) => {
  const { t } = useLanguage();

  return (
    <div className="tenant_info_cont">
      <div className="tenant_info_single_cont">
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.business_name}</label>
          <input
            id="business_name"
            name="business_name"
            className={`tenant_input ${errorFields.business_name ? "error_border" : ""}`}
            value={formData.business_name}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.vat}</label>
          <input
            id="vat"
            name="vat"
            className={`tenant_input ${errorFields.vat ? "error_border" : ""}`}
            value={formData.vat}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.country}</label>
          <select
            id="country_business"
            name="country_business"
            className={`tenant_input ${errorFields.country_business ? "error_border" : ""}`}
            value={formData.country_business}
            onChange={handleChange}
          >
            <option value="">-</option>
            {stripeNation.map((country, index) => (
              <option key={index} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.city}</label>
          <input
            id="city_business"
            name="city_business"
            className={`tenant_input ${errorFields.city_business ? "error_border" : ""}`}
            value={formData.city_business}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.province}</label>
          <input
            id="province_business"
            name="province_business"
            className={`tenant_input ${errorFields.province_business ? "error_border" : ""}`}
            value={formData.province_business}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.street}</label>
          <input
            id="street_business"
            name="street_business"
            className={`tenant_input ${errorFields.street_business ? "error_border" : ""}`}
            value={formData.street_business}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.n}</label>
          <input
            id="n_business"
            name="n_business"
            className={`tenant_input ${errorFields.n_business ? "error_border" : ""}`}
            value={formData.n_business}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.complete_account.zip}</label>
          <input
            id="zip_business"
            name="zip_business"
            className={`tenant_input ${errorFields.zip_business ? "error_border" : ""}`}
            value={formData.zip_business}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="tenant_info_single_cont">
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">{t.register_form.phone}</label>
          <select
            className={`tel_select_prefix ${errorFields.phone_prefix ? "error_border" : ""}`}
            value={formData.phone_prefix}
            onChange={handleChange}
            id="phone_prefix"
            name="phone_prefix"
            >
            <option value={""}>-</option>
            <option value={"123"}>+ 123</option>
          </select>
          <input
            id="phone"
            name="phone"
            type="phone"
            className={`tenant_input_phone ${errorFields.phone ? "error_border" : ""}`}
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="tenant_lab_in_cont">
          <label className="tenant_label">Email</label>
          <input
            id="email"
            name="email"
            className={`tenant_input ${errorFields.email ? "error_border" : ""}`}
            value={formData.email}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessInformationTenantForm;
