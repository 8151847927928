import React, { useEffect, useState } from "react";
import PersonalInfoForm from "./PersonalInfoForm";
import BusinessInfoForm from "./BusinessInfoForm";
import DepositStripeForm from "./DepositStripeForm";
import Spinner from "../../../components/Spinner"

function MainSteps({ currentStep, setCurrentStep }) {
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const userId = sessionStorage.getItem("user_id");
        fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/check_registration", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_id: userId }),
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false);
            if (data.error) {
                console.error("Error fetching registration progress:", data.error);
            } else {
                // Determine which step to set based on the completed steps
                const { completed_steps, total_steps } = data;
                setCurrentStep(completed_steps + 1 <= total_steps ? completed_steps + 1 : total_steps);
            }
        })
        .catch(error => {
            console.error("Error during fetch:", error);
        });
    }, [setCurrentStep]);

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return <PersonalInfoForm setCurrentStep={setCurrentStep} />;
            case 2:
                return <BusinessInfoForm setCurrentStep={setCurrentStep}/>;
            case 3:
                return <DepositStripeForm />;
            default:
                return <div>Select a step</div>;
        }
    };

    return (
        <div className="main_steps_cont">
        {loading ? (
            <div className="spinner_load_steps_cont">
                <Spinner loading={loading} err={err}></Spinner>
            </div>
        ) : (
            renderStepContent()
        )}
    </div>
    );
}

export default MainSteps;
