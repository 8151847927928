import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../../Translations/LenguageContext";
import PersonalInformationTenantForm from "./PersonalInformationTenantForm";
import BusinessInformationTenantForm from "./BusinessInformationTenantForm";
import FindContacts from "./FindContacts";
import Spinner from "../../../../components/Spinner";

const TenantPersonalInformation = ({ tenant, new_update, tenantId, toggleComponent, isActive }) => {
    const { t } = useLanguage();

    const [tenantType, setTenantType] = useState("person");
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const [userId] = useState(sessionStorage.getItem("user_id"));
    const [selectedContactKey, setSelectedContactKey] = useState(null);
    const [check, setCheck] = useState(false)

    const [formData, setFormData] = useState({
        name: "",
        surname: "",
        birthday: "",
        sex: "",
        country: "",
        city: "",
        province: "",
        street: "",
        n: "",
        zip: "",
        business_name: "",
        vat: "",
        country_business: "",
        city_business: "",
        province_business: "",
        street_business: "",
        n_business: "",
        zip_business: "",
        phone: "",
        phone_prefix: "",
        email: "",
        tenantType: "person",
        guarantor: "",
        cf: ""
    });

    const [errorFields, setErrorFields] = useState({});

    const handleTenantTypeChange = (type) => {
        setTenantType(type);
        setFormData((prevData) => ({
            ...prevData,
            tenantType: type,
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setErrorFields((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const handleContactSelect = (key) => {
        setSelectedContactKey(key);
    };

    const validateFields = () => {
        const requiredFields =
            tenantType === "person"
                ? ["name", "surname", "birthday", "sex", "country", "city", "province", "street", "n", "zip", "phone", "phone_prefix", "email", "guarantor", "cf"]
                : ["business_name", "vat", "country_business", "city_business", "province_business", "street_business", "n_business", "zip_business", "phone", "phone_prefix", "email"];

        const errorFields = {};

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                errorFields[field] = true;
            }
        });

        setErrorFields(errorFields);

        return Object.keys(errorFields).length === 0;
    };

    const HandleCheckandSave = () => {
        if (tenantType === "find_contact" && !selectedContactKey) {
            setErr({ message: t.register_form.select_contact, display: true });
            return;
        }

        if (tenantType !== "find_contact" && !validateFields()) {
            setErr({ message: t.register_form.fill_required, display: true });
            return;
        }

        if (tenantType === "find_contact" && selectedContactKey.contactKey === "") {
            setErr({ message: t.register_form.fill_required, display: true });
            const requiredFields = ["contact_tenant"]

            const errorFields = {};

            requiredFields.forEach((field) => {
                if (!formData[field]) {
                    errorFields[field] = true;
                }
            });

            setErrorFields(errorFields);

            return Object.keys(errorFields).length === 0;
        } else if (tenantType === "find_contact" && selectedContactKey.cf === "") {
            setErr({ message: t.register_form.fill_required, display: true });
            const requiredFields = ["cf"]

            const errorFields = {};

            requiredFields.forEach((field) => {
                if (!formData[field]) {
                    errorFields[field] = true;
                }
            });

            setErrorFields(errorFields);

            return Object.keys(errorFields).length === 0;
        } else if (tenantType === "find_contact" && selectedContactKey.guarantor === "") {
            setErr({ message: t.register_form.fill_required, display: true });
            const requiredFields = ["guarantor"]

            const errorFields = {};

            requiredFields.forEach((field) => {
                if (!formData[field]) {
                    errorFields[field] = true;
                }
            });

            setErrorFields(errorFields);

            return Object.keys(errorFields).length === 0;
        } else {
            setErrorFields({})
        }

        SaveData();
    };

    const SaveData = () => {
        setLoading(true);
    
        let dataToSend;
    
        if (new_update === "update" && userId) {
            console.log("Sto aggiornando");
    
            dataToSend = {
                ...formData,
                user_id: userId,
                tenant_id: tenantId || null
            };
        } else if (new_update === "new") {
            dataToSend = tenantType === "find_contact"
                ? { contact_key: selectedContactKey, user_id: userId }
                : { ...formData, user_id: userId, tenant_id: null };
        }
    
        fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/tenant_personal_information", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSend),
        })
            .then((response) => response.json())
            .then((data) => {
                setLoading(false);
                if (data.message === "OK") {
                    tenant(data.tenant_id);
                    setErr({ message: "", display: false });
                    toggleComponent("propertyInfo");
                    setCheck(true);
                } else if (data.error) {
                    setErr({ message: t.register_form.errors_back, display: true });
                }
            })
            .catch((error) => {
                setLoading(false);
                setErr({ message: t.register_form.problem_back, display: true });
            });
    };
    
    

    useEffect(() => {
        if (new_update === "update" && tenantId) {
            setLoading(true);
            fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_specific_tenant_data", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ userId: userId, tenant_id: tenantId })
            })
                .then(response => response.json())
                .then(data => {
                    setLoading(false);
                    setErr({ message: "", display: false });
                    const tenantData = data.tenant?.personal_information || {};
                    setFormData({
                        name: tenantData.name || "",
                        surname: tenantData.surname || "",
                        birthday: tenantData.birthday || "",
                        sex: tenantData.sex || "",
                        country: tenantData.country || "",
                        city: tenantData.city || "",
                        province: tenantData.province || "",
                        street: tenantData.street || "",
                        n: tenantData.n || "",
                        zip: tenantData.zip || "",
                        business_name: tenantData.business_name || "",
                        vat: tenantData.vat || "",
                        country_business: tenantData.country_business || "",
                        city_business: tenantData.city_business || "",
                        province_business: tenantData.province_business || "",
                        street_business: tenantData.street_business || "",
                        n_business: tenantData.n_business || "",
                        zip_business: tenantData.zip_business || "",
                        phone: tenantData.phone || "",
                        phone_prefix: tenantData.phone_prefix || "",
                        email: tenantData.email || "",
                        tenantType: tenantData.tenantType || "person",
                        guarantor: tenantData.guarantor || "",
                        cf: tenantData.cf || "",
                    });
                    if (Object.keys(tenantData).length > 0) {
                        toggleComponent("propertyInfo");
                        setCheck(true);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setErr({ message: t.register_form.problem_back, display: true });
                });
        }
    }, [new_update, tenantId, userId, t]); // Add necessary dependencies


    return (
        <div className={`tenant_label_input_cont ${check ? "label_input_cont_done" : ""}`}>
            <div className={`new_tenant_title_chevron_cont ${isActive ? "new_tenant_title_chevron_cont_open" : ""}`} onClick={() => toggleComponent("personalInfo")}>
                <div className="new_tenant_title_desc_cont">
                    <p className="new_tenant_title">{t.tenants.tenant_personal_info_title}</p>
                    <p className="description_tenant_title">{t.tenants.tenant_personal_info_desc}</p>
                </div>
                <div className="new_tenant_check_chevron_cont">
                    <i className={`fa-regular ${check ? "fa-circle-check check_tenant" : "fa-circle circle_tenant"}`}></i>
                    <i className={`fa-solid ${isActive ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                </div>
            </div>

            <div className={`toggle_tenant_info_cont ${isActive ? "active_tenant_info" : ""}`}>
                <div className="tenant_type_btn_cont">
                    <button
                        className={`tenant_type_btn ${tenantType === "person" ? "active" : ""}`}
                        onClick={() => handleTenantTypeChange("person")}
                    >
                        <i className="fa-solid fa-user"></i> {t.tenants.individual}
                    </button>
                    <button
                        className={`tenant_type_btn ${tenantType === "business" ? "active" : ""}`}
                        onClick={() => handleTenantTypeChange("business")}
                    >
                        <i className="fa-solid fa-shop"></i> {t.tenants.business}
                    </button>
                    {/* {new_update === "new" && (
                        <button
                            className={`tenant_type_btn_contacts ${tenantType === "find_contact" ? "active" : ""}`}
                            onClick={() => handleTenantTypeChange("find_contact")}
                        >
                            <i className="fa-solid fa-magnifying-glass"></i>
                            {t.tenants.search_contacts}
                        </button>
                    )} */}
                </div>

                <div className="tenant_info_container">
                    {tenantType === "person" && (
                        <PersonalInformationTenantForm
                            formData={formData}
                            handleChange={handleChange}
                            errorFields={errorFields}
                        />
                    )}

                    {tenantType === "business" && (
                        <BusinessInformationTenantForm
                            formData={formData}
                            handleChange={handleChange}
                            errorFields={errorFields}
                        />
                    )}

                    {tenantType === "find_contact" && (
                        <FindContacts
                            handleContactSelect={handleContactSelect}
                            errorFields={errorFields}
                        />
                    )}
                </div>
                <div className="save_tenant_info_btn_container">
                    <button className="save_tenant_info_btn" onClick={HandleCheckandSave}>
                        <Spinner loading={loading} err={err} />
                        <span>{t.tenants.save_btn}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TenantPersonalInformation;
