import React from 'react';
import StripePayment from '../Payments/StripePayment';


const DepositStripeForm = () => {


  return (
    <div className='stripe_complete_reg'>
      <StripePayment/>
    </div>
  );
};

export default DepositStripeForm;
