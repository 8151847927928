import React from 'react';
import { useNavigate } from 'react-router-dom';

const Legal_Notes = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  return (
    <div className='policies_container'>
      <div onClick={goToHome} className='policy_title_logo'>
        <i className="fa-solid fa-layer-group"></i>
        <p>Paypermate</p>
      </div>
      <div className='policies_cont'>
        <p className='title_policy'>Legal Notes</p>
        <p><strong>Last updated: September 30, 2024</strong></p>

        <p className='declaration_policy'>
          These legal notes provide important information regarding the ownership and use of the Paypermate website and services. By using this website, you agree to comply with these legal notes, as well as our Terms and Conditions and Privacy Policy.
        </p>

        <p className='section_titles'>1. Ownership of the Website</p>
        <p className='description_policy'>
          The website www.paypermate.com (the "Website") is owned and operated by Paypermate Srl, with its registered office located at Via Emilio Gola 5, Milano - 20143 (MI), Italy.
        </p>

        <p className='section_titles'>2. Intellectual Property</p>
        <p className='description_policy'>
          All content on the Website, including text, images, graphics, logos, icons, software, and other materials (collectively, the "Content"), is the exclusive property of Paypermate Srl or its licensors and is protected by intellectual property laws. You may not reproduce, distribute, modify, or exploit the Content without our prior written consent.
        </p>

        <p className='section_titles'>3. Limitation of Liability</p>
        <p className='description_policy'>
          Paypermate Srl makes no representations or warranties about the accuracy, reliability, or completeness of the information provided on the Website. In no event shall Paypermate Srl, its directors, employees, or affiliates be liable for any damages, including direct, indirect, incidental, consequential, or punitive damages arising from your use of the Website.
        </p>

        <p className='section_titles'>4. External Links</p>
        <p className='description_policy'>
          The Website may contain links to external websites that are not operated or controlled by Paypermate Srl. We are not responsible for the content, privacy policies, or practices of any third-party websites.
        </p>

        <p className='section_titles'>5. Applicable Law and Jurisdiction</p>
        <p className='description_policy'>
          These legal notes, as well as any disputes arising from your use of the Website, shall be governed by and construed in accordance with the laws of Italy. Any legal action or proceeding related to your use of the Website shall be brought exclusively in the courts of Milan, Italy.
        </p>

        <p className='section_titles'>6. Changes to Legal Notes</p>
        <p className='description_policy'>
          We may update these legal notes from time to time. Any changes will be posted on this page with an updated revision date. You are advised to review these legal notes periodically for any updates.
        </p>

        <p className='section_titles'>7. Contact Us</p>
        <p className='description_policy'>
          If you have any questions or concerns about these legal notes, please contact us at: <strong>hello@paypermate.com</strong>
        </p>
      </div>
    </div>
  );
};

export default Legal_Notes;
