import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { FaStripe } from "react-icons/fa";
import { FaCcPaypal } from "react-icons/fa6";
import { SiSepa } from "react-icons/si";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BiCreditCard } from "react-icons/bi";
import { SiAdyen } from "react-icons/si";
import { BsPaypal } from "react-icons/bs";

const StripePayment = () => {
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const [visibleDesc, setVisibleDesc] = useState(null);
    const { t } = useLanguage();

    const [completed_steps, setCompleted_steps] = useState("");
    const [total_steps, setTotal_steps] = useState("");
    const [bank_details, setBank_details] = useState(false);
    const [account_id_stripe, setAccount_id_stripe] = useState("");

    const user_id = sessionStorage.getItem("user_id");

    useEffect(() => {
        const fetchProgress = async () => {
            try {
                const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/check_stripe_reg", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                if (data.completed_steps !== undefined) {
                    setCompleted_steps(data.completed_steps);
                    setTotal_steps(data.total_steps);
                    setBank_details(data.bank_details);
                    setAccount_id_stripe(data.account_id_stripe);
                } else {
                    console.error(data.error);
                }
            } catch (error) {
                console.error("Error fetching registration progress:", error);
            }
        };

        fetchProgress();
    }, [user_id]);

    const handleStripeSetup = async () => {
        setLoading(true);
        setErr({ message: "", display: false });

        try {
            const createAccountResponse = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/create_account_stripe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id }),
            });

            if (!createAccountResponse.ok) {
                setLoading(false);
                setErr({ message: "Network error while creating account", display: true });
                throw new Error('Network response was not ok');
            }

            const accountData = await createAccountResponse.json();
            const accountId = accountData.account_id;

            const connectAccountResponse = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/create_account_link', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ account_id: accountId, user_id }),
            });

            if (!connectAccountResponse.ok) {
                setLoading(false);
                setErr({ message: "Network error while connecting account", display: true });
                throw new Error('Network response was not ok');
            }

            const connectData = await connectAccountResponse.json();
            window.location.href = connectData.url;
        } catch (error) {
            console.error("Error setting up Stripe account:", error);
            setLoading(false);
        }
    };

    const toggleDesc = (method) => {
        setVisibleDesc(prev => (prev === method ? null : method));
    };


    return(
        <div className="payments_cont_app">
        <p className='payments_stripe_logo'><FaStripe /></p>
        <p className="paymets_cont_app_title">{t.complete_account.stripe_title}</p>
        <p className="payments_cont_desc">{t.complete_account.stripe_desc}</p>

        {/* SEPA Payment Method */}
        <div className="desc_payments">
            <div className="pay_method" onClick={() => toggleDesc('sepa')}>
                <i className="sepa_logo"><SiSepa /></i>
                <i className="fa-chevron">
                    {visibleDesc === 'sepa' ? <FaChevronUp /> : <FaChevronDown />}
                </i>
            </div>
            <div className={`sepa_desc ${visibleDesc === 'sepa' ? 'visible' : ''}`}>
                <p>
                    {t.complete_account.sepa}
                </p>
                <div className="fees_container">
                    <div className="fee_cont">
                        <p>{t.complete_account.sepa_fee_1}</p>
                    </div>
                    <div className="fee_cont">
                        <p>{t.complete_account.sepa_fee_2}</p>
                    </div>
                </div>
            </div>
        </div>

        {/* Credit Card Payment Method */}
        <div className="desc_payments">
            <div className="pay_method" onClick={() => toggleDesc('creditCard')}>
                <p className="logo_card"><i><BiCreditCard /></i> Credit/Debit card</p>
                <i className="fa-chevron">
                    {visibleDesc === 'creditCard' ? <FaChevronUp /> : <FaChevronDown />}
                </i>
            </div>
            <div className={`sepa_desc ${visibleDesc === 'creditCard' ? 'visible' : ''}`}>
                <p>
                    {t.complete_account.card_payment}
                </p>
                <div className="fees_container">
                    <div className="fee_cont">
                        <p>{t.complete_account.card_fee_1}</p>
                    </div>
                    <div className="fee_cont">
                        <p>{t.complete_account.card_fee_2}</p>
                    </div>
                </div>
            </div>
        </div>

        <button className='stripe_btn_payments' onClick={handleStripeSetup} disabled={loading}>
            {loading ? t.complete_account.process_loading : t.complete_account.stripe_button}
        </button>
    </div>
    )
}

export default StripePayment