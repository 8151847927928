import React, { useState } from 'react';
import SignatureModal from '../Modals/SignatureModal';

const CanoneLibero = ({ userData }) => {
    const [showModal, setShowModal] = useState(false);
    const [currentSigner, setCurrentSigner] = useState(null);
    const [signatures, setSignatures] = useState({
        'locatrice_1': null,
        'conduttrice_1': null,
        'garante_1': null,
        'locatrice_2': null,
        'conduttrice_2': null,
        'garante_2': null,
    });

    const isOwner = userData && userData.user_type === 'owner';


    const handleSignerClick = (signer) => {
        setCurrentSigner(signer);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSaveSignature = (signatureData) => {
        setSignatures((prevSignatures) => ({
            ...prevSignatures,
            [currentSigner]: signatureData,
        }));
        setShowModal(false);
    };

    return (
        <div className='contract_container'>
            <div className='contract_content'>

                <p className='contract_title'>CONTRATTO DI LOCAZIONE AD USO ABITATIVO <span>ai sensi dell’art.2, 1° comma, legge n° 431 del 09/12/1998</span> </p>
                <input className='input_contracts' placeholder='Luogo e data'></input>,

                <p className='section_splitter'>TRA:</p>

                {/* La signora Rimoldi Serafina nata a Milano il 13/06/1934, domiciliata in
                Milano in Via Giotto n. 3, C.F.: RMLSFN34H53F205B, di seguito denominata
                “parte locatrice” */}

                E:

                {/* e il sig. Pireci Zyber, nato in Kosovo (EE) il 11/08/2001, C.F.:
                PRCZBR01M11Z160Q, domiciliato in Milano in Via Gola Emilio n. 5, di seguito
                denominato “parte conduttrice” */}

                <p className='section_splitter'>Le parti convengono e stipulano:</p>

                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>1 - OGGETTTO DELLA LOCAZIONE:</p>
                    <p className='section_policies_contract_parag'>
                        {/* appartamento sito in Milano in Via Gola
                        Emilio n. 5, piano 3°, composto da n. 2,5 vani accessori, riportato nel catasto
                        urbano di Milano alla partita 70772, foglio 521, al mappale 23, sub. 13,
                        categoria A/4, mq 51, rendita catastale €. 296,96=.
                        Sono escluse pertinenze di alcun tipo. La parte conduttrice è stata informata
                        sulla certificazione A.P.E.. */}
                    </p>
                </div>

                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>2 - DURATA DELLA LOCAZIONE:</p>
                    <p className='section_policies_contract_parag'>
                        Anni 4 ai sensi dell’art.2 legge n.431 del
                        09/12/1998, a partire dal 01/07/2024 con scadenza al 30/06/2028, rinnovabile
                        per la stessa durata se non interviene disdetta, tramite raccomandata R.R.,
                        almeno sei mesi prima della scadenza. Alla seconda scadenza la parte locatrice
                        invierà raccomandata a.r. sei mesi prima della scadenza del contratto stesso,
                        per comunicare l’intenzione di stipulare un nuovo contratto a nuove condizioni.
                        In mancanza di risposta della parte conduttrice entro 60 giorni, o in mancanza
                        di un accordo sulle nuove condizioni tra le parti, il contratto si intenderà risolto
                        alla sua naturale scadenza e lasciato libero da persone e cose.
                        Resta espressamente pattuito e convenuto tra le parti che la parte conduttrice
                        avrà diritto di recesso dal presente contratto in qualsiasi momento e per
                        qualsiasi motivo con preavviso alla parte locatrice mediante lettera
                        raccomandata r.r. di almento 6 (sei) mesi prima della data in cui il recesso
                        deve avere efficacia.
                    </p>
                </div>

                <div className='parag_contract'>

                    <p className='section_policies_contract_title'>3 - CANONE:</p>
                    <p className='section_policies_contract_parag'>
                        {/* Il canone annuale per l’appartamento è pattuito in €. 6.600,00=
                        (euro seimilaseicento/00), oltre €.3.600,00= (euro tremilaseicento/00) per
                        rimborso spese ordinarie, calcolate in modo forfettario. Tali importi sono da
                        pagarsi in 12 rate mensili anticipate scadenti il giorno 01 di ogni mese,
                        puntualmente e comunque con tolleranza massima di ulteriori gg. 3 di ritardo.
                        Ad ogni ritardo di pagamento, verranno applicati ed aggiunti al debito,
                        gli interessi legali. Conseguentemente alla pattuizione forfettaria del
                        suddetto importo, lo stesso non sarà soggetto a conguaglio né a favore della
                        parte locatrice né a favore della parte conduttrice. */}
                    </p>
                </div>

                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>4 - MODALITA&#39; DI PAGAMENTO:</p>
                    <p className='section_policies_contract_parag'>
                        Con bonifico bancario. Gli avvisi di
                        pagamento mensili verranno inviati, alla parte conduttrice, all’inizio di ogni
                        trimestre di riferimento (01/01 – 01/04 – 01/07 - 01/10).
                    </p>
                </div>
                <div className='parag_contract'>
                    <p className='section_policies_contract_title'> 5 - DESTINAZIONE D&#39;USO:</p>
                    <p className='section_policies_contract_parag'>
                        L’immobile sarà adibito esclusivamente ad uso
                        abitativo del conduttore stipulante e dei familiari con lui residenti.
                        Il presente contratto viene stipulato per n° 1 persona, eventuali
                        variazioni vanno comunicate tempestivamente alla parte locatrice.
                        E’ fatto espresso divieto di sub-locare o comodare, in tutto o in parte,
                        la casa locata. L’inosservanza del presente patto determina la
                        risoluzione immediata del contratto ai sensi dell’art. 1456 c.c..
                    </p>
                </div>

                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>6 – INADEMPIMENTO:</p>
                    <p className='section_policies_contract_parag'>
                        Il mancato pagamento di una sola mensilità
                        costituisce motivo di risoluzione del contratto a norma dell’art. 1455 c.c..
                    </p>
                </div>

                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>7 – CONSEGNA E CONDIZIONI DELL’IMMOBILE:</p>
                    <p className='section_policies_contract_parag'>
                        La parte locatrice
                        consegna i locali nello stato di fatto. I locali vengono accettati dalla parte
                        conduttrice, senza alcuna pretesa di alcunché (formula visto, piaciuto ed
                        accettato). La parte conduttrice si obbliga a riconsegnare l’immobile, oggetto
                        del presente contratto, alla risoluzione tra le parti della locazione, in uno stato
                        decoroso, pena la perdita del deposito cauzionale infruttifero e
                        l’eventuale richiesta di rimborso per danni riscontrati, qualora il
                        ripristino dell’immobile stesso comportasse una spesa maggiore del deposito
                        cauzionale infruttifero versato. La parte conduttrice provvederà a sue
                        spese all’installazione di scaldabagno a norma di legge, sia esso a gas o
                        elettrico, consegnando alla parte locatrice copia della certificazione di
                        corretta installazione e, si impegna, inoltre, ad installare un piano
                        cottura gas provvisto di dispositivo secur-gas e forno elettrico, come
                        previsto da norma di legge. Le parti pattuiscono altresì che la parte conduttrice
                        non potrà richiedere alcunché alla parte locatrice a titolo di indennizzo e/o
                        risarcimento e/o di rimborso spese per gli interventi da essa stessa eseguiti
                        all’interno dell’immobile locato; le relative migliorie e/o addizioni resteranno
                        quindi acquisite alla proprietà senza alcun obbligo di indennizzo e/o rimborso.
                        Le parti dichiarano espressamente che i canoni di locazione annui pattuiti
                        all’articolo n.3, che precede, sono stati determinati e convenuti dalle parti
                        tenendo in considerazione le obbligazioni assunte dalla parte conduttrice nel
                        presente articolo n.7.
                    </p>
                </div>

                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>8 – DIVIETO DI MIGLIORIE ED ADDIZIONI:</p>
                    <p className='section_policies_contract_parag'>
                        Ogni aggiunta o modifica che
                        non possa essere tolta senza danneggiare gli immobili non potrà essere
                        effettuata dalla parte conduttrice, senza il preventivo consenso scritto da
                        parte della proprietaria. Questi resteranno comunque a beneficio dell’immobile
                        senza che nulla sia dovuto alla parte conduttrice, neanche a titolo di rimborso
                        spese.
                    </p>
                </div>

                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>9 – MANUTENZIONE E RIPARAZIONI ORDINARIE DELLA COSA LOCATA:</p>
                    <p className='section_policies_contract_parag'>
                        La parte conduttrice è costituita custode della casa locata e dovrà mantenerla
                        con la diligenza del buon padre di famiglia. Essa è tenuta ad eseguire tutte le
                        riparazioni conseguenti a danni provocati dalla sua negligenza nell’uso della
                        casa locata e delle apparecchiature ivi esistenti, nonché le riparazioni di cui
                        agli art. 1576 e 1609 c.c.. In esse rientrano, per patto espresso, quelle inerenti
                        le parti degli impianti igienico-sanitari, elettrico, idrico, del gas, dell’acqua
                        calda, di pertinenza esclusiva dell’immobile locato, nonché le riparazioni alle
                        condutture idrauliche di scarico ed alle consequenziali opere di ripristino
                        nonché la manutenzione periodica degli infissi interni ed esterni, (porte e
                        finestre, nonché persiane/tapparelle, porta d’ingresso).
                        Quando la casa locata ha bisogno di riparazioni che non sono a carico della
                        parte conduttrice, quest’ultima è tenuta a darne tempestiva comunicazione
                        scritta alla parte locatrice, all’amministratore o a loro incaricati.
                    </p>
                </div>
                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>10 - MANUTENZIONI STRAORDINARIE:</p>
                    <p className='section_policies_contract_parag'>
                        Le opere di manutenzione
                        straordinaria sono a carico della parte locatrice, la quale, per rivalersi, ha la
                        facoltà di chiedere alla parte conduttrice un aumento del canone non superiore
                        all’interesse legale sul capitale impiegato nella esecuzione delle opere.
                    </p>
                </div>

                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>11 - REGOLAMENTO CONDOMINIALE:</p>
                    <p className='section_policies_contract_parag'>
                        La parte conduttrice si obbliga a
                        rispettare e a far rispettare ai propri familiari, le norme del regolamento
                        interno del condominio che dichiara di conoscere ed accettare e comunque le
                        regole di buon vicinato e del corretto vivere civile.
                        L’infrazione delle norme del Regolamento Condominiale, se contestate
                        almeno tre volte alla parte conduttrice mediante lettera raccomandata
                        r.r., comporterà per la parte locatrice il diritto di agire in giudizio per
                        ottenere la risoluzione del presente contratto di locazione per
                        colpa/inadempimento della parte conduttrice.
                    </p>
                </div>
                <div className='parag_contract'>

                    <p className='section_policies_contract_title'>12 – VISITA DEGLI IMMOBILI:</p>
                    <p className='section_policies_contract_parag'>
                        La parte locatrice e/o l’amministratore in
                        carica, potrà ispezionare i locali affittati, allo scopo di eseguire modifiche
                        impiantistiche o altri lavori necessari, nei tempi e con le modalità che saranno
                        ragionevolmente concordati con la parte conduttrice.
                    </p>
                </div>

                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>13 - SPESE DI REGISTRAZIONE E AGGIORNAMENTO ISTAT:</p>
                    <p className='section_policies_contract_parag'>
                        {/* La parte
                        locatrice esercita l’opzione per la Cedolare Secca, ai sensi e per gli effetti del
                        comma 11 dell’art.3 del D.L. n.23/2011. A tal fine, la parte conduttrice dichiara
                        di aver ricevuto le informazioni sui propri diritti derivanti dalla scelta della
                        parte locatrice sulla Cedolare Secca, ovvero quanto segue: per effetto di tale
                        opzione non si renderà più applicabile, limitatamente al periodo di validità
                        dell’opzione stessa, l’imposta di registrazione per il seguente contratto
                        (imposta di registro 2% sull’importo annuo del canone di locazione + marche
                        da bollo), così come non saranno più dovute le imposte per le annualità
                        successive (imposta di registro pari al 2% del canone annuo di locazione).
                        Inoltre, l’aggiornamento annuale del canone di locazione sulla base delle
                        variazioni dei coefficienti Istat non verrà applicato ed i canoni annui stabiliti
                        all’art. 3 del presente contratto, nella misura di €. 6.600,00= (euro
                        seimilaseicento/00), resterà invariato.
                        La presente comunicazione si intende valida fino alla naturale scadenza
                        contrattuale, salvo eventuale revoca da parte della locatrice, che si impegna
                        sin d’ora a comunicare tempestivamente tale decisione mediante
                        raccomandata a.r.. */}
                    </p>
                </div>

                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>14 – TENUTA DI ANIMALI:</p>
                    <p className='section_policies_contract_parag'>
                        Piccoli animali come uccellini, pesci ornamentali,
                        tartarughe, criceti, conigli nani o simili possono essere tenuti dalla parte
                        conduttrice senza l’autorizzazione della parte locatrice, purché in numero
                        usuale per la vita domestica. La tenuta di altri animali da parte della parte
                        conduttrice, in particolare di cani, è consentita solo previo consenso
                        scritto del locatore. In difetto di consenso nei termini sopra convenuti, la
                        parte locatrice potrà risolvere il contratto ai sensi e per gli effetti di cui all’art.
                        1456 c.c. mediante l’invio di raccomandata a.r..
                    </p>
                </div>
                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>15 – RESPONSABILITA’:</p>
                    <p className='section_policies_contract_parag'>
                        La parte conduttrice esonera la parte locatrice da
                        ogni responsabilità per qualsiasi danno che potesse provenirgli da fatto di terzi,
                        compreso personale a servizio dello stabile e, segnatamente, per furti.
                        La parte locatrice è esonerata da responsabilità di alcun tipo in caso di
                        interruzione dei servizi per cause indipendenti dalla sua volontà.
                    </p>
                </div>
                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>16 – ASSICURAZIONE IMMOBILE LOCATO:</p>
                    <p className='section_policies_contract_parag'>
                        Si suggerisce alla parte
                        conduttrice di stipulare polizza assicurativa relativa al Rischio di responsabilità
                        civile verso terzi, che copra i danni materiali e diretti da essa arrecati a causa
                        di incendio, allagamento o altro evento.
                    </p>
                </div>
                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>17 – DISDETTA O PREAVVISO:</p>
                    <p className='section_policies_contract_parag'>
                        Dal giorno della comunicazione della disdetta
                        o nel caso in cui la proprietaria intendesse vendere lo stabile o le unità
                        immobiliari locate, la parte conduttrice dovrà lasciare visitare i locali agli
                        aspiranti, previo accordo con la parte locatrice o suoi incaricati, sotto pena del
                        risarcimento dei danni.
                    </p>
                </div>
                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>18 – DEPOSITO CAUZIONALE:</p>
                    <p className='section_policies_contract_parag'>
                        {/* A garanzia di tutte le obbligazioni che
                        assume con il presente contratto il sig. Pireci Zyber, versa alla parte
                        conduttrice la somma di €. 1.100,00= (euro millecento/00), quale deposito
                        cauzionale infruttifero. Il deposito cauzionale verrà restituito, al sig. Pireci
                        Zyber, alla risoluzione del presente contratto una volta verificato che le
                        condizioni dei locali siano buone, che l’appartamento sia libero da persone
                        e/o cose, quindi completamente vuoto ed imbiancato, (nel caso che alla
                        consegna l’appartamento fosse stato consegnato imbiancato), e dopo che
                        saranno state pagate, dal sig. Pireci Zyber, tutte le mensilità di affitto e spese
                        fino al giorno della risoluzione del contratto stesso. IL DEPOSITO CAUZIONALE
                        INFRUTTIFERO NON POTRA’ IN NESSUN CASO ESSERE UTILIZZATO QUALE
                        COPERTURA DI CANONI DI LOCAZIONE E/O SPESE INSOLUTI DA PAGARE, lo
                        stesso verrà restituito tramite bonifico bancario entro 30 giorni dal rilascio
                        dell’immobile, dopo adeguata verifica delle condizioni dei locali. */}
                    </p>
                </div>
                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>19 – GARANZIE PERSONALI:</p>
                    <p className='section_policies_contract_parag'>
                        {/* Il presente contratto viene altresì sottoscritto
                        dal sig. Pireci Ilami, nato in Kosovo (EE) il 26/03/1979, c.f.:
                        PRCLMI79C26Z160M, residente a Limana (BL) (cap 32020), in Via Roma n. 21,
                        che in tal modo si costituisce garante solidale del sig. Pireci Zyber, garantendo
                        il corretto adempimento di tutte le obbligazioni derivanti dal presente contratto
                        ed in particolare quelle relative al versamento del canone di locazione e delle
                        spese ordinarie forfettarie indicati nell’ articolo 3 che precede, (ossia €.
                        6.600,00 (euro seimilaseicento/00) per il canone di locazione annuale pattuito,
                        più €.3.600,00= (euro tremilaseicento/00) per rimborso spese ordinarie
                        forfettarie, nonché per quanto eventualmente dovuto per indennità di
                        occupazione e per risarcimento danni arrecati all’immobile qualora di
                        ammontare superiore alla somma versata a titolo di deposito cauzionale
                        infruttifero. Il rilascio della presente autonoma garanzia personale, accettata
                        dalla parte locatrice con la sottoscrizione del presente contratto, comporta per
                        il garante l’assunzione delle obbligazioni derivanti dal presente contratto in via
                        solidale con la parte conduttrice, pertanto è espressamente escluso ogni
                        beneficio di escussione preventiva della parte conduttrice stessa. Il sig. Pireci
                        Ilami, è tenuto a pagare, immediatamente e senza possibilità di opporre
                        eccezioni spettanti al debitore principale (sig. Pireci Zyber), alla parte locatrice,
                        a semplice prima richiesta scritta di quest’ultima, da effettuarsi a mezzo
                        raccomandata r.r., e comunque entro e non oltre 10 giorni dal ricevimento di
                        tale richiesta, anche in caso di opposizione del debitore principale garantito
                        (sig. Pireci Zyber), l’importo richiesto dalla parte locatrice, sino al limite
                        massimo di €. 18.000,00=. La presente garanzia autonoma, rilasciata dal
                        suddetto garante mediante sottoscrizione del presente contratto, avrà validità
                        sino al 30/06/2028 (data scadenza contratto) e si rinnoverà automaticamente
                        per ulteriori anni 4 a seguito dell’eventuale proroga del contratto di locazione;
                        in ogni caso la presente autonoma garanzia rimarrà valida per i trenta giorni
                        successivi al rilascio dell’immobile, da intendersi come perfezionato con
                        l’effettiva immissione della proprietà del possesso dell’unità immobiliare locata.
                        Trascorsi 30 giorni dalla scadenza della garanzia ovvero (se successiva) dalla
                        data del rilascio dell’immobile concesso in locazione, senza che sia stata inviata
                        dalla parte locatrice lettera raccomandata r.r. per chiederne
                        l’operatività/l’escussione, la seguente autonoma garanzia si intenderà decaduta
                        e priva di qualsiasi efficacia anche se non restituita. E’ obbligo del garante
                        comunicare alla parte locatrice ogni eventuale cambio di residenza, in difetto la
                        garanzia si riterrà comunque tempestivamente e validamente escussa/attivata
                        con l’invio della richiesta di escussione presso l’ultima residenza dichiarata dal
                        garante sottoscrivente. La sottoscrizione della garanzia personale avviene in
                        calce al presente contratto. */}
                    </p>
                </div>
                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>20 – EVENTUALE DIPARTITA E/O RINUNCIA DEL/DEI GARANTE/I PERSONALE/I:</p>
                    <p className='section_policies_contract_parag'>
                        Nel caso in cui, per qualsivoglia motivo non riconducibile alla
                        parte locatrice, dovesse venire meno e/o cessare e/o perdere di efficacia e/o
                        essere dichiarata nulla la garanzia prevista dall&#39;art. 19 che precede, la parte
                        conduttrice si obbliga espressamente ed inderogabilmente in tale eventualità
                        ad integrare e/o sostituire tale garanzia entro e non oltre il termine di giorni 30
                        (dicasi trenta) dal venir meno e/o dalla cessazione e/o dalla perdita di efficacia
                        e/o dalla dichiarazione di nullità della medesima. In difetto di integrazione e/o
                        sostituzione di detta garanzia con una nuova ed equipollente da parte del
                        conduttore/i nel termine convenuto, la parte locatrice potrà risolvere il
                        contratto ai sensi e per gli effetti di cui all&#39;art.1456 c.c. mediante l&#39;invio di
                        raccomandata a.r.
                    </p>
                </div>
                <div className='parag_contract'>
                    <p className='section_policies_contract_title'>21 – FORO COMPETENTE:</p>
                    <p className='section_policies_contract_parag'>
                        Foro competente per il seguente contratto sarà il
                        Foro di Milano.
                        Per quanto non espressamente indicato, nello stesso, si rinvia al codice civile e
                        alla legge n.431/1998.
                    </p>
                </div>

                <div className='parag_contract_sign'>
                    <p className='parag_contract_sign_parag'>Letto, approvato e sottoscritto.</p>

                    <div className='parag_contract_sign_counterparts_cont'>
                        <div className='parag_contract_sign_counterparts'>
                            <div className={`signer ${isOwner ? 'signer_disabled' : ''}`}
                                onClick={() => !isOwner && handleSignerClick('locatrice_1')}>
                                <p className='signer_label'>La parte locatrice</p>
                                {signatures['locatrice_1'] ? (
                                    <div className='signatures_contract_container'>
                                        <img className='signatures_contract' src={signatures['locatrice_1']} alt="Firma Locatrice 1" />
                                    </div>
                                ) : (
                                    <p className='no_signature'>Nessuna firma</p>
                                )}
                            </div>
                        </div>

                        <div className='parag_contract_sign_counterparts'>
                            <div className='signer' onClick={() => handleSignerClick('conduttrice_1')}>
                                <p className='signer_label'>La parte conduttrice</p>
                                {signatures['conduttrice_1'] ? (
                                    <div className='signatures_contract_container'>
                                        <img className='signatures_contract' src={signatures['conduttrice_1']} alt="Firma Conduttrice 1" />
                                    </div>
                                ) : (
                                    <p className='no_signature'>Nessuna firma</p>
                                )}
                            </div>
                            <div className={`signer ${isOwner ? 'signer_disabled' : ''}`}
                                onClick={() => !isOwner && handleSignerClick('granate_1')}>
                                <p className='signer_label'>Il Garante</p>
                                {signatures['granate_1'] ? (
                                    <div className='signatures_contract_container'>
                                        <img className='signatures_contract' src={signatures['granate_1']} alt="Firma Garante 1" />
                                    </div>
                                ) : (
                                    <p className='no_signature'>Nessuna firma</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <p className='policy_italic'>
                        Ai sensi e per gli effetti degli artt. 1341 e 1342 c.c. si approvano espressamente le
                        clausole n. 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17, 18, 19, 20 e 21 che sono
                        state rilette, dopo articolate trattative intercorse tra le parti.
                    </p>

                    <div className='parag_contract_sign_counterparts_cont'>
                        <div className='parag_contract_sign_counterparts'>
                            <div className={`signer ${isOwner ? 'signer_disabled' : ''}`}
                                onClick={() => !isOwner && handleSignerClick('locatrice_2')}>
                                <p className='signer_label'>La parte locatrice</p>
                                {signatures['locatrice_2'] ? (
                                    <div className='signatures_contract_container'>
                                        <img className='signatures_contract' src={signatures['locatrice_2']} alt="Firma Locatrice 2" />
                                    </div>
                                ) : (
                                    <p className='no_signature'>Nessuna firma</p>
                                )}
                            </div>
                        </div>

                        <div className='parag_contract_sign_counterparts'>
                            <div className='signer' onClick={() => handleSignerClick('conduttrice_2')}>
                                <p className='signer_label'>La parte conduttrice</p>
                                {signatures['conduttrice_2'] ? (
                                    <div className='signatures_contract_container'>
                                        <img className='signatures_contract' src={signatures['conduttrice_2']} alt="Firma Conduttrice 2" />
                                    </div>
                                ) : (
                                    <p className='no_signature'>Nessuna firma</p>
                                )}
                            </div>
                            <div className={`signer ${isOwner ? 'signer_disabled' : ''}`}
                                onClick={() => !isOwner && handleSignerClick('granate_2')}>
                                <p className='signer_label'>Il Garante</p>
                                {signatures['granate_2'] ? (
                                    <div className='signatures_contract_container'>
                                        <img className='signatures_contract' src={signatures['granate_2']} alt="Firma Garante 2" />
                                    </div>
                                ) : (
                                    <p className='no_signature'>Nessuna firma</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <SignatureModal
                        show={showModal}
                        onClose={handleCloseModal}
                        signer={currentSigner}
                        onSaveSignature={handleSaveSignature}
                    />
                </div>
            </div>
        </div>
    );
};

export default CanoneLibero;
