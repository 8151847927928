import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function WelcomeMessage() {
    const navigate = useNavigate();
    const [fadeIn, setFadeIn] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        setFadeIn(true);

        const fadeOutTimeout = setTimeout(() => {
            setFadeOut(true);
        }, 5000);

        const navigateTimeout = setTimeout(() => {
            navigate("/control_panel/home");
        }, 10000);

        return () => {
            clearTimeout(fadeOutTimeout);
            clearTimeout(navigateTimeout);
        };
    }, [navigate]);

    return (
        <div className={`fade-container ${fadeIn ? 'fade-in' : ''} ${fadeOut ? 'fade-out' : ''}`}>
            <div className="end_register">
                <div className="scroller_cont_welcome">
                    <p className="scrollable_titles_welcome">Benvenuto in</p>
                    <p className="scrollable_titles_welcome">Welcome in</p>
                    <p className="scrollable_titles_welcome">Bienvenido en</p>
                    <p className="scrollable_titles_welcome">Willkommen in</p>
                    <p className="scrollable_titles_welcome">Bienvenue en</p>
                    <p className="scrollable_titles_welcome">ようこそ</p>
                    <p className="scrollable_titles_welcome">欢迎</p>
                    <p className="scrollable_titles_welcome">Bem-vindo em</p>
                    <p className="scrollable_titles_welcome">Добро пожаловать в Русском</p>
                    <p className="scrollable_titles_welcome">خوش آمدید در فارسی</p>

                </div>
                <p id="welcome_title">
                    {/* <FaBoxOpen /> */}
                    <i className="fa-solid fa-layer-group"></i>
                    Paypermate
                </p>
                <span className="go_dashboard" onClick={() => navigate("/control_panel/home")}><i className="fa-solid fa-chevron-right"></i></span>
            </div>
        </div>
    );
}

export default WelcomeMessage;
