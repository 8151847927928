// MapComponent.js
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';

// Fix Leaflet marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const UnitMap = ({ country, city, street, number }) => {
    const address = `${street}, ${number}, ${city}, ${country}`;
    const [position, setPosition] = useState([45.4642, 9.1900]); // Default to Milan

    useEffect(() => {
        const fetchCoordinates = async () => {
            try {
                const response = await axios.get(
                    `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`
                );

                if (response.data.length > 0) {
                    const { lat, lon } = response.data[0];
                    setPosition([lat, lon]);
                }
            } catch (error) {
                console.error("Error fetching coordinates:", error);
            }
        };

        fetchCoordinates();
    }, [address]);

    // Create a custom circle marker
    const customCircle = L.divIcon({
        className: 'custom-circle-marker',
        html: `<div class="circle"></div>`,
        iconSize: [50, 50], // Size of the circle
        iconAnchor: [25, 50], // Anchor point for the icon
        popupAnchor: [0, -40], // Popup position
    });

    return (
        <MapContainer center={position} zoom={13} style={{ height: '100vh', width: '100%' }}>
            <TileLayer
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
            />
            <Marker position={position} icon={customCircle}>
                <Popup>{address}</Popup>
            </Marker>
        </MapContainer>
    );
};

export default UnitMap;
