import React from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { RiSecurePaymentFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const TenantCharts = () => {
    return (
        <div className="tenants_graphs">
            <div className="charts_cont">
                <p className="charts_title"><i><RiSecurePaymentFill /></i> Canoni d'affitto</p>
                <Stack className="stack_chart" direction="row" sx={{ width: '100%' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <SparkLineChart
                            colors={['var(--mainColor)']}
                            data={[0, 0, 0, 0, 0, 0, 0, 0]}
                            xAxis={{
                                scaleType: 'time',
                                data: [
                                    new Date(2022, 5, 1),
                                    new Date(2022, 5, 2),
                                    new Date(2022, 5, 5),
                                    new Date(2022, 5, 6),
                                    new Date(2022, 5, 7),
                                    new Date(2022, 5, 8),
                                    new Date(2022, 5, 11),
                                    new Date(2022, 5, 12),
                                ],
                                valueFormatter: (value) => value.toISOString().slice(0, 10),

                            }}
                            height={100}
                            showTooltip
                            showHighlight
                        />
                    </Box>
                </Stack>
            </div>
            <div className="charts_cont">
                <p className="charts_title"><i><RiSecurePaymentFill /></i> Incassi spese</p>
                <Stack className="stack_chart" direction="row" sx={{ width: '100%' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <SparkLineChart
                            colors={['var(--secondColor)']}
                            data={[0, 0, 0, 0, 0, 0, 0, 0]}
                            xAxis={{
                                scaleType: 'time',
                                data: [
                                    new Date(2022, 5, 1),
                                    new Date(2022, 5, 2),
                                    new Date(2022, 5, 5),
                                    new Date(2022, 5, 6),
                                    new Date(2022, 5, 7),
                                    new Date(2022, 5, 8),
                                    new Date(2022, 5, 11),
                                    new Date(2022, 5, 12),
                                ],
                                valueFormatter: (value) => value.toISOString().slice(0, 10),

                            }}
                            height={100}
                            showTooltip
                            showHighlight
                        />
                    </Box>
                </Stack>
            </div>
            <div className="charts_cont">
                <p className="charts_title"><i><RiSecurePaymentFill /></i> Depositi cauzionali</p>
                <Stack className="stack_chart" direction="row" sx={{ width: '100%' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <SparkLineChart
                            colors={['var(--azure)']}
                            data={[0, 0, 0, 0, 0, 0, 0, 0]}
                            xAxis={{
                                scaleType: 'time',
                                data: [
                                    new Date(2022, 5, 1),
                                    new Date(2022, 5, 2),
                                    new Date(2022, 5, 5),
                                    new Date(2022, 5, 6),
                                    new Date(2022, 5, 7),
                                    new Date(2022, 5, 8),
                                    new Date(2022, 5, 11),
                                    new Date(2022, 5, 12),
                                ],
                                valueFormatter: (value) => value.toISOString().slice(0, 10),

                            }}
                            height={100}
                            showTooltip
                            showHighlight
                        />
                    </Box>
                </Stack>
            </div>
        </div>
    )
}

export default TenantCharts