import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../Translations/LenguageContext";

const CheckEmail = () => {
    const {t} = useLanguage()

    return(
        <div className="check_email_cont">
            <p className="check_email_title">{t.check_email.title}</p>
            <i className="fa-solid fa-envelope-open"></i>

            <p className="close_home">({t.check_email.subtitle} <Link className="link_email" to={"/"}>Home-Page</Link>)</p>
        </div>
    )
}

export default CheckEmail