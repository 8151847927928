import React, { useRef, useEffect, useState } from "react";
import { RiSecurePaymentFill } from "react-icons/ri";
import { useLanguage } from "../../Translations/LenguageContext";

const TransactionsAnimations = ({ start }) => {
    const { t } = useLanguage()
    const phoneScreenRef = useRef(null);
    const rowsRef = useRef([]);
    const ballRef = useRef(null);
    const houseRef = useRef(null);
    const [resetAnimation, setResetAnimation] = useState(false);
    const [activeSection, setActiveSection] = useState(0);

    const handleScroll = (sectionIndex) => {
        if (!phoneScreenRef.current) return; // Check if the ref is null
        
        const sectionWidth = phoneScreenRef.current.offsetWidth;
        const scrollPosition = sectionWidth * sectionIndex;
        phoneScreenRef.current.scrollTo({
            left: scrollPosition,
            behavior: 'smooth'
        });
        setActiveSection(sectionIndex);
    };
    

    const resetFirstSectionAnimation = () => {
        rowsRef.current.forEach((row) => {
            if (row) {
                row.style.animation = '';
            }
        });
    };

    const triggerFirstSectionAnimation = () => {
        resetFirstSectionAnimation();
        let delay = 0;

        rowsRef.current.forEach((row, index) => {
            setTimeout(() => {
                if (row) {
                    row.style.animation = 'grow 0.5s ease-in-out forwards';
                }
            }, delay);

            delay += 1000;
        });

        setTimeout(() => {
            handleScroll(1);
            triggerSecondSectionAnimation();
        }, delay + 500);
    };

    const triggerSecondSectionAnimation = () => {
        if (ballRef.current && houseRef.current) {
            ballRef.current.classList.remove('animate-ball');
            houseRef.current.classList.remove('animate-house');
            houseRef.current.style.backgroundColor = 'var(--mainColor)';
            houseRef.current.style.border = '1px solid var(--mainColor)';

            setTimeout(() => {
                ballRef.current.classList.add('animate-ball');
                houseRef.current.classList.add('animate-house');
            }, 100);

            houseRef.current.addEventListener('animationend', () => {
                houseRef.current.style.backgroundColor = 'var(--azure)';
                houseRef.current.style.border = '1px solid var(--azure)';

                setTimeout(() => {
                    handleScroll(2);
                }, 1000);

                setResetAnimation(true);
            }, { once: true });
        }
    };

    useEffect(() => {
        if (start) {
            triggerFirstSectionAnimation();
        }
    }, [start]);

    useEffect(() => {
        if (resetAnimation) {
            const timeoutId = setTimeout(() => {
                setResetAnimation(false);
                handleButtonClick(0);
            }, 7000);

            return () => clearTimeout(timeoutId);
        }
    }, [resetAnimation]);

    const handleButtonClick = (sectionIndex) => {
        handleScroll(sectionIndex);

        if (sectionIndex === 0) {
            triggerFirstSectionAnimation();
        } else if (sectionIndex === 1) {
            triggerSecondSectionAnimation();
        }
    };

    return (
        <div className="phone_transactions_container">
            <div className="tenant_manager_container">
                <button
                    className="tenant_manager_btns"
                    onClick={() => handleButtonClick(0)}
                    style={{
                        borderColor: activeSection === 0 ? 'var(--mainColor)' : '',
                        backgroundColor: activeSection === 0 ? 'var(--mainColor)' : '',
                        color: activeSection === 0 ? 'white' : ''
                    }}
                >
                    {t.transactions.phone.tenant}
                </button>
                <button
                    className="tenant_manager_btns"
                    onClick={() => handleButtonClick(1)}
                    style={{
                        borderColor: activeSection === 1 ? 'var(--mainColor)' : '',
                        backgroundColor: activeSection === 1 ? 'var(--mainColor)' : '',
                        color: activeSection === 1 ? 'white' : ''
                    }}
                >
                    {t.transactions.phone.property}
                </button>
                <button
                    className="tenant_manager_btns"
                    onClick={() => handleButtonClick(2)}
                    style={{
                        borderColor: activeSection === 2 ? 'var(--mainColor)' : '',
                        backgroundColor: activeSection === 2 ? 'var(--mainColor)' : '',
                        color: activeSection === 2 ? 'white' : ''
                    }}
                >
                    {t.transactions.phone.payment}
                </button>
            </div>


            <div className="phone_transaction">
                <div ref={phoneScreenRef} className="phone_screen_transaction">

                    <div className="transaction_section">
                        <p className="transaction_section_title">{t.transactions.phone.find_tenant}</p>

                        <div className="creation_tenant_transaction_cont">
                            {[0, 1, 2].map((_, index) => (
                                <div
                                    key={index}
                                    className="rows_transactions"
                                    ref={(el) => (rowsRef.current[index] = el)}
                                >
                                    <div className="add_rows_transactions">
                                        <i className="fa-solid fa-plus"></i>
                                    </div>
                                    <div className="content_row_transaction">
                                        <div className="rows_transactions_img"></div>
                                        <div className="rows_transactions_tenant"></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="transaction_section">
                        <p className="transaction_section_title">{t.transactions.phone.combine}</p>
                        <div className="combine_transaction_property">
                            <div className="prop_tenant_transaction">
                                <div className="rows_transactions_img" id="ball" ref={ballRef}></div>
                            </div>
                            <div className="prop_tenant_transaction">
                                <div className="rows_transactions_img_house" id="house" ref={houseRef}></div>
                            </div>
                        </div>
                    </div>

                    <div className="transaction_section">
                        <p className="transaction_section_title">{t.transactions.phone.recieve_payments}</p>
                        <div className="payment_transaction_cont">
                            <i><RiSecurePaymentFill className="test" /></i>
                            <div className="shadow_pay"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionsAnimations;
