const UnitType = ({ icon, title, selected, onClick, error }) => (
    <div
        className={`unit_type_block ${selected ? "unit_type_selected" : ""}`}
        onClick={onClick}
        style={{
            border: selected ? "1px solid var(--azure)" : error ? "1px solid var(--danger)" : "",
            color: selected ? "var(--azure)" : "",
            cursor: "pointer"
        }}
    >
        <i>{icon}</i>
        <p className="unit_title">{title}</p>
    </div>
);

export default UnitType
