import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import { useLanguage } from "../Translations/LenguageContext";
import dialPhonePrefix from "../Geo/PhonePrefix.json"
import { Link } from "react-router-dom";

function Register() {
    const navigate = useNavigate();
    const { t } = useLanguage();
    const [activeStep, setActiveStep] = useState(0);
    const [activeStepConnector, setActiveStepConnector] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const [dialCodes, setDialCodes] = useState([]);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    
    const nameRef = useRef(null);
    const surnameRef = useRef(null);
    const emailRef = useRef(null);
    // const phoneRef = useRef(null);
    // const prefixRef = useRef(null);
    const passwordRef = useRef(null);
    const repeatPasswordRef = useRef(null);

    const steps = ['first_bubbly_step', 'second_bubbly_step', 'third_bubbly_step'];
    const connectors = ['first_bubbly_connector', 'second_bubbly_connector', 'third_bubbly_connector'];

    useEffect(() => {
        setDialCodes(dialPhonePrefix);

        const interval = setInterval(() => {
            setActiveStep((prevStep) => (prevStep + 1) % steps.length);
        }, 2000);

        const intervalConnector = setInterval(() => {
            setActiveStepConnector((prevStep) => (prevStep + 1) % connectors.length);
        }, 2000);

        return () => {
            clearInterval(interval);
            clearInterval(intervalConnector);
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                startRegister();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{10,20}$/;

    const validateEmail = (email) => {
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        return passwordRegex.test(password);
    };

    function startRegister() {
        setErr({ message: "", display: false })
        const name_reg = nameRef.current;
        const surname_reg = surnameRef.current;
        const email_reg = emailRef.current;
        // const phone_reg = phoneRef.current;
        // const prefix_reg = prefixRef.current;
        const password_reg = passwordRef.current;
        const repeat_password_reg = repeatPasswordRef.current;

        let error = false;
        let error_email = false;
        let error_password = false;
        let error_password_repeat = false;

        if (!name_reg.value) {
            error = true;
            name_reg.style.border = "1px solid var(--danger)";
        } else {
            name_reg.style.border = "";
        }

        if (!surname_reg.value) {
            error = true;
            surname_reg.style.border = "1px solid var(--danger)";
        } else {
            surname_reg.style.border = "";
        }

        if (!email_reg.value) {
            error = true;
            email_reg.style.border = "1px solid var(--danger)";
        } else if (!validateEmail(email_reg.value)) {
            error = true;
            error_email = true;
            email_reg.style.border = "1px solid var(--danger)";
        } else {
            email_reg.style.border = "";
        }

        // if (!phone_reg.value) {
        //     error = true;
        //     phone_reg.style.border = "1px solid var(--danger)";
        // } else {
        //     phone_reg.style.border = "";
        // }

        // if (!prefix_reg.value) {
        //     error = true;
        //     prefix_reg.style.border = "1px solid var(--danger)";
        // } else {
        //     prefix_reg.style.border = "";
        // }

        if (!password_reg.value) {
            error = true;
            password_reg.style.border = "1px solid var(--danger)";
        } else if (!validatePassword(password_reg.value)) {
            error = true;
            error_password = true;
            password_reg.style.border = "1px solid var(--danger)";
        } else {
            password_reg.style.border = "";
        }

        if (!repeat_password_reg.value) {
            error = true;
            repeat_password_reg.style.border = "1px solid var(--danger)";
        } else if (repeat_password_reg.value !== password_reg.value) {
            error = true;
            error_password_repeat = true;
            repeat_password_reg.style.border = "1px solid var(--danger)";
        } else {
            repeat_password_reg.style.border = "";
        }

        const privacy_policy_desc = document.getElementById("privacy_policy_desc");
        if (!privacyChecked) {
            error = true;
            privacy_policy_desc.style.color = "var(--danger)";
        }else{
            privacy_policy_desc.style.color = "";
        }

        if (error) {
            if (error_email) {
                setErr({ message: t.register_form.email_format, display: true });
            } else if (error_password) {
                setErr({ message: t.register_form.password_err, display: true });
            } else if (error_password_repeat) {
                setErr({ message: t.register_form.password_match, display: true });
            } else {
                setErr({ message: t.register_form.red_fields, display: true });
            }
        } else {
            SaveData(name_reg.value, surname_reg.value, email_reg.value, /*phone_reg.value, prefix_reg.value,*/ password_reg.value)
        }
    }

    function SaveData(name, surname, email, /*phone, prefix,*/ password) {
        setLoading(true);
        let dataToSave = {
            name: name,
            surname: surname,
            email: email,
            // phone: phone,
            // prefix: prefix,
            password: password,
        };

        fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/new_registration', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSave),
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.message === "OK") {
                    sessionStorage.setItem("user_id", data.user_id);
                    setErr({ message: "", display: false });
                    navigate(`/tll`);
                } else if (data.message === "Email_already_present") {
                    setErr({ message: t.register_form.email_already_present, display: true });
                } else if (data.message === "Invalid_email_address") {
                    setErr({ message: t.register_form.email_format, display: true });
                } else if (data.message === "All_fields_required") {
                    setErr({ message: t.register_form.red_fields, display: true });
                } else if (data.error) {
                    console.log(data);
                    setErr({ message: t.register_form.errors_back, display: true });
                }
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
                setErr({ message: t.register_form.problem_back, display: true });
            });
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="main_frame">
            <div className="register_page">
                <div className="register_conts_reg">
                    <div className="reg_cont">
                        <p className="title_form" onClick={() => navigate("/")}><span className="ia_span"><i className="fa-solid fa-layer-group"></i></span> Paypermate</p>
                        <div className="persona_reg">
                            <input ref={nameRef} type="text" id="name_reg" placeholder={t.register_form.name} className="register_form_first person_reg"></input>
                            <input ref={surnameRef} type="text" id="surname_reg" placeholder={t.register_form.surname} className="register_form_first person_reg"></input>
                        </div>
                        <input ref={emailRef} type="text" id="email_reg" placeholder="Email" className="register_form_first"></input>
                        {/* <div className="phone_number">
                            <select ref={prefixRef} id="prefix_reg" className="phone_prefix">
                                <option value="">+...</option>
                                {dialCodes.map((country, index) => (
                                    <option key={index} value={country.dial_code}>
                                        {country.dial_code}
                                    </option>
                                ))}
                            </select>
                            <input ref={phoneRef} type="text" id="phone_reg" placeholder={t.register_form.phone} className="register_form_first"></input>
                        </div> */}
                        <div className="password_eye">
                            <input ref={passwordRef} type={showPassword ? "text" : "password"} id="password_reg" placeholder="Password" className="register_form_first"></input>
                            <button className="show_passowrd" onClick={togglePasswordVisibility}>
                                <i className={`fa-regular fa-eye${showPassword ? "" : "-slash"}`}></i>
                            </button>
                        </div>
                        <input ref={repeatPasswordRef} type="password" id="repeat_password_reg" placeholder={t.register_form.repeat_password} className="register_form_first"></input>


                        <div className="second_cont_reg">
                            <div className="priv_mail_cont">
                                <input type="checkbox" checked={privacyChecked} onChange={() => setPrivacyChecked(!privacyChecked)} />
                                <p id="privacy_policy_desc">{t.register_form.privacy_policy_desc} <Link to={"/privacy-policy"}>{t.register_form.p_p}</Link></p>
                                {/* <p>{t.register_form.terms_conditions_desc} <Link to={"/terms-and-conditions"}>{t.register_form.t_c}</Link></p> */}
                            </div>
                            {/* <div className="priv_mail_cont">
                                <input type="checkbox"></input>
                                <p>Voglio ricevere email da FlatJourneyAI relative agli aggiornamenti sui prodotti</p>
                            </div> */}

                        </div>
                        <button onClick={startRegister} className="start_btn">{t.register_form.button_register}</button>
                        <p className="already_present">{t.register_form.already_registered} <span onClick={() => navigate("/login")}> {t.register_form.go_login}</span></p>

                    </div>
                        <Spinner loading={loading} err={err}></Spinner>
                </div>
            </div>
        </div>
    );
}

export default Register;
