import React, { useRef, useEffect, useState } from 'react';
import Globe from 'react-globe.gl';
import { useLanguage } from '../Translations/LenguageContext';

const HomeGlobe = () => {
    const {t} = useLanguage()
    const globeEl = useRef();
    const [arcs, setArcs] = useState([]);

    useEffect(() => {
        const globe = globeEl.current;
        globe.controls().autoRotate = true;
        globe.controls().autoRotateSpeed = 1;
        globe.controls().enableZoom = false; // Disable zoom
    }, []);

    useEffect(() => {
        const generateArcData = (numArcs) => {
            const arcData = [];
            for (let i = 0; i < numArcs; i++) {
                const startLat = Math.random() * 180 - 90;
                const startLng = Math.random() * 360 - 180;
                const endLat = Math.random() * 180 - 90;
                const endLng = Math.random() * 360 - 180;
                arcData.push({
                    startLat,
                    startLng,
                    endLat,
                    endLng,
                    color: [getRandomColor(), getRandomColor()],
                });
            }
            return arcData;
        };

        const getRandomColor = () => {
            // Define the RGB values for each of the colors
            const colors = [
                { r: 86, g: 89, b: 255 }, // #5659ff
                { r: 164, g: 131, b: 240 }, // #a483f0
                { r: 38, g: 154, b: 226 }, // #269ae2
            ];

            // Choose a random color from the array
            const randomColor = colors[Math.floor(Math.random() * colors.length)];

            // Convert RGB back to hex
            const rgbToHex = (r, g, b) => {
                return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;
            };

            // Return the random color in hex format
            return rgbToHex(randomColor.r, randomColor.g, randomColor.b);
        };


        setArcs(generateArcData(30));
    }, []);

    return (
        <div className='globe_home'>
            <div className='globe_elem title_cont_globe'>
                <div className='title_globe_cont'>
                    <p className='globe_title'>{t.property_globe.title}</p>
                    <p className='globe_subtitle'>{t.property_globe.desc}</p>
                </div>

            </div>
            <div className='globe_elem globe_cont'>
                <Globe
                    ref={globeEl}
                    globeImageUrl="https://dummyimage.com/1x1/eaedf0/eaedf0"
                    backgroundColor="#2e3242"
                    arcsData={arcs}
                    arcColor={(arc) => arc.color}
                    arcStroke={0.7}
                    arcAltitude={0.3}
                    arcDashLength={0.5}
                    arcDashGap={0.5}
                    arcDashAnimateTime={1500}
                    width={800}
                    height={800}
                />
            </div>
        </div>
    );
};

export default HomeGlobe;
