import React from 'react';
import { useNavigate } from 'react-router-dom';

const Terms_And_Conditions = () => {
  const navigate = useNavigate()

  const goToHome = () => {
    navigate("/")
  }
  return (
    <div className='policies_container'>
      <div onClick={goToHome} className='policy_title_logo'>
        <i className="fa-solid fa-layer-group"></i>
        <p>Paypermate</p>
      </div>
      <div className='policies_cont'>
        <p className='title_policy'>Terms and Conditions</p>
        <p><strong>Last updated: September 30, 2024</strong></p>

        <p className='declaration_policy'>
          These Terms and Conditions outline the rules and regulations for the use of Paypermate's Service.
        </p>

        <p className='declaration_policy'>
          By accessing this website we assume you accept these terms and conditions. Do not continue to use Paypermate if you do not agree to take all of the terms and conditions stated on this page.
        </p>

        <p className='section_titles'>1. Definitions</p>
        <p className='description_policy'>For the purposes of these Terms and Conditions:</p>
        <ul className='description_policy'>
          <li><strong>Service:</strong> Refers to the website Paypermate.</li>
          <li><strong>Company:</strong> Refers to Paypermate Srl, Milano, Via Emilio Gola 5 - 20143 (MI).</li>
          <li><strong>User:</strong> Any individual who accesses or uses the Service.</li>
          <li><strong>Terms:</strong> Refers to these Terms and Conditions.</li>
        </ul>

        <p className='section_titles'>2. Acceptance of Terms</p>
        <p className='description_policy'>
          By using the Service, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, you must not use the Service.
        </p>

        <p className='section_titles'>3. Changes to Terms</p>
        <p className='description_policy'>
          The Company reserves the right to modify or replace these Terms at any time. You are advised to review these Terms periodically for any changes.
        </p>

        <p className='section_titles'>4. User Obligations</p>
        <p className='description_policy'>
          Users must not use the Service for any illegal or unauthorized purpose. Users agree to comply with all applicable laws and regulations while using the Service.
        </p>

        <p className='section_titles'>5. Intellectual Property</p>
        <p className='description_policy'>
          The Service and its original content, features, and functionality are and will remain the exclusive property of Paypermate and its licensors.
        </p>

        <p className='section_titles'>6. Limitation of Liability</p>
        <p className='description_policy'>
          In no event shall Paypermate, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, use, goodwill, or other intangible losses, resulting from your use of the Service.
        </p>

        <p className='section_titles'>7. Governing Law</p>
        <p className='description_policy'>
          These Terms shall be governed by and construed in accordance with the laws of Italy, without regard to its conflict of law principles.
        </p>

        <p className='section_titles'>8. Changes to the Service</p>
        <p className='description_policy'>
          The Company reserves the right to withdraw or amend the Service, and any service or material we provide on the Service, in our sole discretion without notice.
        </p>

        <p className='section_titles'>9. Contact Us</p>
        <p className='description_policy'>
          If you have any questions about these Terms, please contact us at: <strong>hello@paypermate.com</strong>
        </p>
      </div>
    </div>
  );
};

export default Terms_And_Conditions;
