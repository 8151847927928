import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActionsData from "./ActionsData";
import UnitMap from "./UnitMap";
import { TbMeterSquare } from "react-icons/tb";
import { MdOutlineEnergySavingsLeaf, MdSensorWindow } from "react-icons/md";
import { useLanguage } from "../../../Translations/LenguageContext";
import GoogleMaps from "./GoogleMaps";
import ComfortOptions from "../../../components/ComfortOptions";

const SingleProp = () => {
    const { t } = useLanguage()
    const { prop_id } = useParams();
    const userId = sessionStorage.getItem("user_id");
    const [dataAvailable, setDataAvailable] = useState(false);
    const [rentals, setRentals] = useState([]);
    const [landlordProperties, setLandlordProperties] = useState({})
    const [tenantRentedProperties, setTenantRentedProperties] = useState({})
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        const fetchRentals = async () => {
            if (userId) {
                try {
                    const response = await fetch("http://127.0.0.1:5000/get_specific_rented_properties", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ user_id: userId, rent_id: prop_id }),
                    });

                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }

                    const result = await response.json();
                    if (result) {
                        const landlord_matching_properties = result.data.landlord_matching_properties
                        const tenant_rented_properties = result.data.tenant_rented_properties

                        setLandlordProperties(landlord_matching_properties || '')
                        setTenantRentedProperties(tenant_rented_properties || '')
                    } else {
                        console.log("error")
                    }
                } catch (error) {
                    console.error("Error fetching rentals:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                console.error("User ID not found in session storage");
                setLoading(false);
            }
        };

        fetchRentals();
    }, []);

    return (
        <div className="main_view_section">


            <div className="single_open_prop_cont">

                {landlordProperties &&
                    Object.keys(landlordProperties).map((key) => {
                        const property = landlordProperties[key];
                        const images = property.images;

                        return (
                            <div key={key} className="header_open_prop_cont">
                                {/* Main Image */}
                                <div
                                    className="main_img front_img"
                                    style={{
                                        backgroundImage: `url(${images[0]?.url || ''})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center'
                                    }}
                                ></div>

                                {/* Thumbnail Images */}
                                <div className="other_img">
                                    <div className="img_block">
                                        <div
                                            className="mini_img mini_one"
                                            style={{
                                                backgroundImage: `url(${images[1]?.url || ''})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center'
                                            }}
                                        ></div>
                                        <div
                                            className="mini_img mini_two"
                                            style={{
                                                backgroundImage: `url(${images[2]?.url || ''})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center'
                                            }}
                                        ></div>
                                    </div>

                                    <div className="img_block">
                                        <div
                                            className="mini_img mini_three"
                                            style={{
                                                backgroundImage: `url(${images[3]?.url || ''})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center'
                                            }}
                                        ></div>
                                        <div
                                            className="mini_img mini_four"
                                            style={{
                                                backgroundImage: `url(${images[4]?.url || ''})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center'
                                            }}
                                        ></div>
                                    </div>
                                </div>

                                {/* Show All Images Button */}
                                <div className="show_all_img_btn">
                                    <i className="fa-solid fa-clone"></i>
                                    <p>Show all images</p>
                                </div>
                            </div>
                        );
                    })
                }



                <div className="prop_details">
                    {tenantRentedProperties && (
                        <ActionsData tenantRentedProperties={tenantRentedProperties} />
                    )}

                    {landlordProperties && (
                        <div className="content_cont">
                            {/* Get the first property object dynamically */}
                            {console.log(landlordProperties)}
                            {Object.keys(landlordProperties).map((key) => {
                                const property = landlordProperties[key];
                                return (
                                    <div key={key}>
                                        <p className="content_cont_title">{property.property_name} <span className="prop_type">{property.unit_type}</span></p>
                                        <div className="content_subtitle_cont">

                                            {property.living_space > 0 && (
                                                <p className="content_cont_elem">
                                                    <i className="fa-solid fa-couch"></i> {property.living_space} Salotto
                                                </p>
                                            )}
                                            {property.bedrooms > 0 && (
                                                <p className="content_cont_elem">
                                                    <i className="fa-solid fa-bed"></i> {property.bedrooms} Camera da letto
                                                </p>
                                            )}
                                            {property.kitchen > 0 && (
                                                <p className="content_cont_elem">
                                                    <i className="fa-solid fa-fire-burner"></i> {property.kitchen} Cucina
                                                </p>
                                            )}
                                            {property.bathrooms > 0 && (
                                                <p className="content_cont_elem">
                                                    <i className="fa-solid fa-bath"></i> {property.bathrooms} bagno
                                                </p>
                                            )}
                                        </div>


                                        {property.selected_comforts && (
                                            <div className="comforts_container">
                                                {property.selected_comforts.map((comfort, index) => {
                                                    const comfortOptions = ComfortOptions(); // Call the function to get the options
                                                    const comfortOption = comfortOptions[comfort]; // Get the specific comfort option

                                                    return comfortOption ? ( // Check if the comfortOption exists
                                                        <div className="comfort_bubble" key={index}>
                                                            <i className={comfortOption.icon}></i> {/* Render the icon */}
                                                            <p>{comfortOption.label}</p> {/* Render the label */}
                                                        </div>
                                                    ) : null; // Render nothing if the comfortOption does not exist
                                                })}
                                            </div>
                                        )}




                                        <div className="desc_prop_cont">
                                            <p className="desc_prop" dangerouslySetInnerHTML={{ __html: property.description }} />
                                        </div>


                                        <p className="desc_prop_title">Dettagli immobile</p>
                                        <div className="property_details_cont">
                                            <div className="other_prop_info">
                                                {property.adults > 0 && (
                                                    <div className="other_prop_info_single"><i className="fa-solid fa-person-half-dress icon_prop_det"></i> <p> Adulti</p> <p>{property.adults}</p></div>
                                                )}
                                                {property.children > 0 && (
                                                    <div className="other_prop_info_single"><i className="fa-solid fa-children icon_prop_det"></i> <p> Bambini</p> <p>{property.children}</p></div>
                                                )}
                                                <div className="other_prop_info_single"><i className="fa-solid fa-elevator icon_prop_det"></i> <p> Piano</p> <p>{property.floor}</p></div>
                                                <div className="other_prop_info_single"><i className="icon_prop_det"><TbMeterSquare /></i> <p>Metri quadri</p> <p>{property.size}</p></div>
                                                <div className="other_prop_info_single"><i className="fa-solid fa-compass icon_prop_det"></i> <p>Esposizione</p> <p>{t.properties[property.exposure]}</p></div>
                                                <div className="other_prop_info_single"><i className="icon_prop_det"><MdOutlineEnergySavingsLeaf /></i> <p>Classe energetica</p> <p>{property.energy_class}</p></div>
                                                <div className="other_prop_info_single"><i className="fa-solid fa-temperature-high icon_prop_det"></i> <p>Ricaldamento</p> <p>{t.properties[property.heating]}</p></div>
                                                <div className="other_prop_info_single"><i className="icon_prop_det"><MdSensorWindow /></i> <p>Infissi</p> <p>{t.properties[property.windows]}</p></div>
                                                <div className="other_prop_info_single"><i className="fa-solid fa-heart icon_prop_det"></i> <p>Stato della proprieta</p> <p>{t.properties[property.condition]}</p></div>
                                                <div className="other_prop_info_single"><i className="fa-solid fa-calendar icon_prop_det"></i> <p>Anno di costruzione</p> <p>{property.year_built}</p></div>
                                                <div className="other_prop_info_single"><i className="fa-solid fa-dog icon_prop_det"></i> <p>Animali domestici </p> <p><i className={`fa-regular ${property.pet_permission === "true" ? "fa-circle-check checked_permission" : "fa-circle-xmark unchecked_permission"}`}></i></p></div>
                                                <div className="other_prop_info_single"><i className="fa-solid fa-smoking icon_prop_det"></i> <p>Fumatori</p> <p><i className={`fa-regular ${property.smoke_permission === "true" ? "fa-circle-check checked_permission" : "fa-circle-xmark unchecked_permission"}`}></i></p></div>
                                            </div>
                                        </div>

                                        <p className="desc_prop_title">Posizione</p>
                                        {/* <div className="map_cont"> */}
                                        {/* <UnitMap country={property.country} city={property.city} street={property.street} number={property.street_number} /> */}
                                        <GoogleMaps country={property.country} city={property.city} street={property.street} number={property.street_number} postal_code={property.postal_code} />
                                        {/* </div> */}

                                        <p className="desc_prop_title">Informazioni proprietario</p>
                                        <div className="owner_data_cont">
                                            <div className="owner_data">
                                                <div className="owner_img"></div>
                                                <div className="owner_data_info">
                                                    <p className="owner_name">Mario Rossi <i></i></p>
                                                    <p className="owner_description">Sono un giovane lavoratore di New York, appassionato di musica e teatro</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="last_cont_prop"></div>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}

export default SingleProp