import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { FaStripe } from "react-icons/fa";
import StripePayment from "./StripePayment";
import RevolutPayment from "./RevolutPayment";

function LLPayments() {
    const { t } = useLanguage();

    const [completed_steps, setCompleted_steps] = useState("");
    const [total_steps, setTotal_steps] = useState("");
    const [bank_details, setBank_details] = useState(false);
    const [account_id_stripe, setAccount_id_stripe] = useState("");
    const [registrationStatus, setRegistrationStatus] = useState(3);

    const user_id = sessionStorage.getItem("user_id");

    useEffect(() => {
        const fetchProgress = async () => {
            try {
                const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/check_stripe_reg", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                if (data.completed_steps !== undefined) {
                    setCompleted_steps(data.completed_steps);
                    setTotal_steps(data.total_steps);
                    setBank_details(data.bank_details);
                    setAccount_id_stripe(data.account_id_stripe);
                } else {
                    console.error(data.error);
                }
            } catch (error) {
                console.error("Error fetching registration progress:", error);
            }
        };

        fetchProgress();
    }, [user_id]);



    const shouldHidePaymentsContApp = completed_steps === 1 && total_steps === 1 && bank_details && account_id_stripe !== "";


    return (
        <div className="main_view_section">
            <p className="title_main_view">{t.sidebar.payments}</p>
            <div className="payments_container">

                {/* Only render payments_cont_app if the condition is not met */}
                {!shouldHidePaymentsContApp && (
                   <StripePayment/>
                )}
                {/* <RevolutPayment/> */}
            </div>

            <p className="open_accounts_title">Your active payment accounts</p>
            <div className="open_accounts">
                <div className="open_account_info">
                    <i className="fa-solid fa-circle-check checkicon"></i>
                    <i className="open_account_logo"><FaStripe /></i>
                    <div className="account_det_label">
                        <label>Account ID</label>
                        <p>{account_id_stripe}</p>
                    </div>
                    <div className="account_det_label">
                        <label>Creato il</label>
                        {/* <p>{formatDate(bank_details.account_link_stripe.created)}</p> */}
                    </div>
                    {/* <p>{formatDate(bank_details.account_link_stripe.expires_at)}</p> */}

                </div>
                {/* <div className="open_account_info">
                    <i className="open_account_logo"><SiAdyen/></i>

                </div>
                <div className="open_account_info">
                    <i className="open_account_logo"><BsPaypal/></i>

                </div> */}

            </div>
        </div>
    );
}

export default LLPayments;
