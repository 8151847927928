import React, { useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { useNavigate } from "react-router-dom";
import ScreenTypesModal from "./ScreenTypesModal";
import { Dropdown } from 'react-bootstrap';

const NewScreen = ({ userData }) => {
    const { t } = useLanguage();
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [selectedChoices, setSelectedChoices] = useState([]);
    const [labels, setLabels] = useState({});

    const BackToPreviousPage = () => {
        navigate("/control_panel/screening");
    };

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleSelectionsChange = (choices) => {
        setSelectedChoices(choices);
        const newLabels = choices.reduce((acc, choice) => {
            if (!acc[choice]) acc[choice] = choice;
            return acc;
        }, {});
        setLabels((prevLabels) => ({ ...prevLabels, ...newLabels }));
    };

    const handleLabelChange = (e, choice) => {
        const newLabel = e.target.value;
        setLabels((prevLabels) => ({ ...prevLabels, [choice]: newLabel }));
    };

    const handleDuplicate = (index) => {
        const originalChoice = selectedChoices[index];
        const match = originalChoice.match(/(.*?)-(\d+)$/);
        let baseChoice, currentNumber;

        if (match) {
            baseChoice = match[1];
            currentNumber = parseInt(match[2], 10);
        } else {
            baseChoice = originalChoice;
            currentNumber = 1;
        }

        const duplicateCount = selectedChoices
            .filter(choice => choice.startsWith(baseChoice + "-"))
            .map(choice => {
                const num = choice.match(/-(\d+)$/);
                return num ? parseInt(num[1], 10) : 1;
            })
            .reduce((max, num) => Math.max(max, num), currentNumber);

        const newDuplicatedChoice = `${baseChoice}-${duplicateCount + 1}`;

        const updatedChoices = [...selectedChoices, newDuplicatedChoice];
        setSelectedChoices(updatedChoices);
    };

    const handleDelete = (index) => {
        const updatedChoices = selectedChoices.filter((_, i) => i !== index);
        setSelectedChoices(updatedChoices);
    };

    // Render form inputs based on the selected screen type
    const renderFormForScreenType = (choice) => {
        switch (choice) {
            case 'Personal-Info':
                return (
                    <>
                        <input type="text" placeholder="First Name" className="input_field_screen" />
                        <input type="text" placeholder="Last Name" className="input_field_screen" />
                        <input type="date" placeholder="Birth Date" className="input_field_screen" />
                        <select className="input_field_screen">
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </>
                );
            case 'Guarant-Request':
                return (
                    <>
                        <input type="text" placeholder="Guarantor Name" className="input_field_screen" />
                        <input type="number" placeholder="Guarantor Phone Number" className="input_field_screen" />
                        <input type="email" placeholder="Guarantor Email" className="input_field_screen" />
                    </>
                );
            case 'Income-Verification':
                return (
                    <>
                        <input type="number" placeholder="Annual Income" className="input_field_screen" />
                        <input type="file" className="input_field_screen" />
                    </>
                );
            case 'Economic-State':
                return (
                    <>
                        <input type="text" placeholder="Economic Status" className="input_field_screen" />
                        <textarea placeholder="Additional Financial Information" className="input_field_screen"></textarea>
                    </>
                );
            case 'FRHC':
                return (
                    <>
                        <input type="text" placeholder="Rental History Address" className="input_field_screen" />
                        <input type="text" placeholder="Landlord Name" className="input_field_screen" />
                        <input type="file" placeholder="Rental History Document" className="input_field_screen" />
                    </>
                );
            case 'References':
                return (
                    <>
                        <input type="text" placeholder="Reference Name" className="input_field_screen" />
                        <input type="number" placeholder="Reference Phone Number" className="input_field_screen" />
                        <input type="email" placeholder="Reference Email" className="input_field_screen" />
                    </>
                );
            case 'Behavioral-Info':
                return (
                    <>
                        <textarea placeholder="Behavioral History" className="input_field_screen"></textarea>
                    </>
                );
            case 'Employment-Info':
                return (
                    <>
                        <input type="text" placeholder="Employer Name" className="input_field_screen" />
                        <input type="text" placeholder="Job Title" className="input_field_screen" />
                        <input type="number" placeholder="Annual Salary" className="input_field_screen" />
                    </>
                );
            default:
                return <input type="text" placeholder={`Enter ${choice}`} className="input_field_screen" />;
        }
    };

    return (
        <div className="main_view_section">
            <p className="title_main_view">
                <i onClick={BackToPreviousPage} className="fa-solid fa-chevron-left back-btn_chevron"></i>
                Nuovo screen
            </p>

            <div className="new_screen_cont">
                <div className="new_screen_quest_block_cont">
                    {selectedChoices.map((choice, index) => (
                        <div key={index} className="selected_screen_type">
                            <div className="selected_screen_type_cont">
                                <input
                                    type="text"
                                    value={labels[choice]}
                                    onChange={(e) => handleLabelChange(e, choice)}
                                    className="editable_label"
                                />
                            </div>
                            <Dropdown>
                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleDuplicate(index)}>
                                        Duplicate
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleDelete(index)}>
                                        Delete
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    ))}
                </div>

                <div className="new_screen_preview_cont">
                    <div className="new_screen_preview_cont_func_bar">
                        <button className="screen_options_modale" onClick={handleShow}>
                            <i className="fa-solid fa-plus"></i> Esplora contenuti
                        </button>
                    </div>
                    <div className="new_screen_prev">
                        {selectedChoices.length > 0 &&
                            <div className="new_screen_prev_title_cont">
                                <input className="new_screen_prev_title" placeholder="Titolo (Opzionale)"></input>
                            </div>
                        }
                        {selectedChoices.map((choice, index) => (
                            <div key={index} className="preview_item">
                                <label>{labels[choice]}</label>
                                {renderFormForScreenType(choice)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <ScreenTypesModal
                show={showModal}
                handleClose={handleClose}
                onSelectionsChange={handleSelectionsChange}
            />
        </div>
    );
};

export default NewScreen;
