import React from "react";

function TenantAlert({ onClose, className, count }) {
    return (
        <div className={`alert_message ${className}`}>
            <p className="alert_text">
                <i className="fa-solid fa-warning"></i> 
                {count > 1 
                    ? `Sembra che ${count} inquilini non siano stati registrati correttamente` 
                    : "Sembra che un inquilino non sia stato registrato correttamente"}
            </p>                    
            <div className="alert_btn_cont">
                {/* <button onClick={onClose}>
                    <i className="fa-solid fa-x"></i>
                </button> */}
            </div>
        </div>
    );
}

export default TenantAlert;
