import React, {useState, useRef} from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useLanguage } from "../Translations/LenguageContext";

const Pricing = () => {
    const {t} = useLanguage()
     const pricingBlockRef = useRef(null);
     const [borderStyle, setBorderStyle] = useState(false); 
 
     const handleScrollAndBorder = () => {
         if (pricingBlockRef.current) {
             pricingBlockRef.current.scrollIntoView({ behavior: "smooth" });
         }
         setBorderStyle(true);
         
         setTimeout(() => {
             setBorderStyle(false);
         }, 3000);
     };
    return (
        <div ref={pricingBlockRef}>
            <Navbar></Navbar>
            <div className="pricing_container" >
                <div className="pricing_block">
                    <div className="pricing_block_title_cont">
                        <p className="pricing_block_title">{t.pricing.title}</p>
                        <p className="pricing_block_subtitle">{t.pricing.subtitle}</p>
                    </div>
                    <div className={`pricing_block_main ${borderStyle ? 'show_fee' : 'hide_fee'}`} >
                        <p className="method_pricing_title">{t.pricing.sepa_title}</p>
                        <div className="method_pricing_block">
                            <div className="method_pricing_block_inner flat_fee">
                                <p className="method_pricing_block_title">{t.pricing.flat_fee}</p>
                                <p className="fee_cost">1.70€</p>

                            </div>
                            {/* <div className="method_pricing_block_inner">
                                <p className="method_pricing_block_title">{t.pricing.more_sepa}</p>
                                <p className="fee_cost">2.50€</p>

                            </div> */}
                        </div>
                        <p className="method_pricing_title">{t.pricing.card_title}</p>

                        <div className="method_pricing_block">
                            <div className="method_pricing_block_inner">
                                <p className="method_pricing_block_title">Standard EU</p>
                                <p className="fee_cost">2.5% + 0.25€ </p>

                            </div>
                            <div className="method_pricing_block_inner">
                                <p className="method_pricing_block_title">Premium EU</p>
                                <p className="fee_cost">2.9% + 0.25€ </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fees_table_container">
                <p className="fee_table_title"><i className="fa-solid fa-layer-group"></i> {t.pricing.prices_services}</p>
                <div className="fee_table">
                    <div className="body_fee">
                        <div className="body_row">
                            <div className="body_cell_first"> {t.pricing.standard_functionallities}</div>
                            <div className="body_cell_second">
                                <div className="list_functionallities">
                                    <p className="list_functionallities_title">{t.pricing.platform_title}</p>
                                    <p className="list_functionallities_section"><i className="fa-regular fa-circle-check"></i> {t.pricing.dash}</p>
                                    <p className="list_functionallities_section"><i className="fa-regular fa-circle-check"></i>  {t.pricing.calendar}</p>
                                    <p className="list_functionallities_section"><i className="fa-regular fa-circle-check"></i>  {t.pricing.properties}</p>
                                    <p className="list_functionallities_section"><i className="fa-regular fa-circle-check"></i>  {t.pricing.tenants}</p>
                                    <p className="list_functionallities_section"><i className="fa-regular fa-circle-check"></i> {t.pricing.contacts}</p>
                                    <p className="list_functionallities_section"><i className="fa-regular fa-circle-check"></i> {t.pricing.messages} <span className="ia_span">{t.pricing.ai_assistant}</span> {t.pricing.quick}</p>
                                    <p className="list_functionallities_section"><i className="fa-regular fa-circle-check"></i> {t.pricing.wb_builder}</p>
                                </div>
                            </div>
                            <div className="body_cell_third"> {t.pricing.free}</div>
                        </div>
                        <div className="body_row">
                            <div className="body_cell_first"> {t.pricing.finance_title}</div>
                            <div className="body_cell_second">
                                <div className="list_functionallities">
                                    <p className="list_functionallities_title">{t.pricing.accounting}</p>
                                    <p className="list_functionallities_section"><i className="fa-solid fa-circle circle"></i> {t.pricing.payments}</p>
                                    <p className="list_functionallities_section"><i className="fa-regular fa-circle-check"></i> {t.pricing.transaction}</p>
                                    <p className="list_functionallities_section"><i className="fa-regular fa-circle-check"></i> {t.pricing.balance}</p>
                                    <p className="list_functionallities_section"><i className="fa-regular fa-circle-check"></i> {t.pricing.report}</p>
                                </div>
                            </div>
                            <div className="body_cell_third"> {t.pricing.free} + <span onClick={handleScrollAndBorder} className="fee_third">{t.pricing.standard_fees}</span></div>
                        </div>
                        <div className="body_row">
                            <div className="body_cell_first"> <span className="ia_span">AI</span>-Analytics </div>
                            <div className="body_cell_second">
                                <div className="list_functionallities">
                                    <p className="list_functionallities_title">{t.pricing.analytics_title}</p>
                                    <p className="list_functionallities_section"><i className="fa-regular fa-circle-check"></i> {t.pricing.tenant_analysis}</p>
                                    <p className="list_functionallities_section"><i className="fa-regular fa-circle-check"></i> {t.pricing.market_pricing}</p>
                                    <p className="list_functionallities_section"><i className="fa-regular fa-circle-check"></i> {t.pricing.expenses}</p>
                                </div>
                            </div>
                            <div className="body_cell_third"> {t.pricing.free}</div>
                        </div>
                    </div>

                </div>

            </div>
            <Footer></Footer>
        </div>
    )
}

export default Pricing