import React, { useState, useEffect } from 'react';
import { useLanguage } from "../../../Translations/LenguageContext";
import BookingsMain from './BookingMain';
import BookingSide from './BookingSide';
import PopupMex from '../SavePopup';
import BookingMainCal from './BookingMainCal';

const Bookings = () => {
    const { t } = useLanguage();
    const [bookings, setBookings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [popupActive, setPopupActive] = useState(false);
    const [popupType, setPopupType] = useState("success");

    const userId = sessionStorage.getItem("user_id");

    const fetchBookings = async () => {
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_bookings', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBookings(data.bookings);
        } catch (error) {
            setError(error);
            setPopupType("error");
            setPopupActive(true);
        } finally {
            setLoading(false);
        }
    };

    const handleBookingAdded = () => {
        fetchBookings();
        setPopupType("success");
        setPopupActive(true);
    };

    const handlePopupClose = () => {
        setPopupActive(false);
    };

    useEffect(() => {
        if (userId) {
            fetchBookings();
        }
    }, [userId]);

    if (loading) return <div className='loading_mex_cont'>
        <p className='loading_mex'>{t.loading}</p>
        <i className='fa-solid fa-box-open'></i>
    </div>;
    if (error) return <div className='loading_mex_cont_err'>
        <p>{t.error}</p>
        <i className='fa-solid fa-box-open'></i>
    </div>;

    return (
        <div className="main_view_section">
            <p className="title_main_view">{t.sidebar.bookings}</p>
            <div className="bookings_main">
                {/* <BookingsMain bookings={bookings} onBookingAdded={handleBookingAdded} /> */}
                <BookingMainCal bookings={bookings} onBookingAdded={handleBookingAdded}></BookingMainCal>
                <BookingSide bookings={bookings} onBookingAdded={handleBookingAdded}/>
                <PopupMex active={popupActive} type={popupType} message={""} onClose={handlePopupClose} />
            </div>
        </div>
    );
};

export default Bookings;
