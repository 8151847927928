import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { BiCalendar } from "react-icons/bi";
import { TbLayoutDashboard } from "react-icons/tb";
import { BsHouses, BsPeople } from "react-icons/bs";
import { RiTranslate2 } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { TbUserScan } from "react-icons/tb";
import { LiaToolsSolid } from "react-icons/lia";
import { LuBarChart3 } from "react-icons/lu";
import { BsFolder } from "react-icons/bs";
import { IoIosCloudOutline } from "react-icons/io";

function LLSidebar({ onNavigate, userData, statusRefresh }) {
    const { t, changeLanguage, language } = useLanguage();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState('');
    const [expandedSection, setExpandedSection] = useState('');
    const [properties, setProperties] = useState({});
    const userId = sessionStorage.getItem("user_id");

  

    useEffect(() => {
        const pathName = location.pathname.split('/').pop();
        setActiveLink(pathName);
        if(statusRefresh){
            fetchProperties()
        }
    }, [location.pathname]);

    const handleLanguageChange = (event) => {
        changeLanguage(event.target.value);
    };

    const handleLinkClick = (link) => {
        setActiveLink(link);
        onNavigate(link);
    };

    const getLinkStyle = (link) => {
        return activeLink === link ? { color: "var(--textColor)" } : {};
    };

    const fetchProperties = async () => {
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_properties', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProperties(data.properties || {});
        } catch (error) {
            console.log(error);
        }
    };

    
    useEffect(() => {
        fetchProperties();
    }, []);

    const toggleSection = (section) => {
        setExpandedSection((prev) => (prev === section ? '' : section));
    };

    return (
        <div className="sidebar_cont">
            <div className="sidebar_body">
                <div className="sidebar_body_main">
                    <div className="link_container">
                        <p className="side_link" onClick={() => handleLinkClick('home')} style={getLinkStyle('home')}>
                            <i><TbLayoutDashboard /></i>{t.sidebar.home}
                            <span className="building_span">Building</span>
                        </p>
                        <p className="side_link" onClick={() => handleLinkClick('bookings')} style={getLinkStyle('bookings')}>
                            <i><BiCalendar /></i>{t.sidebar.bookings}
                        </p>
                        <p className="side_link" onClick={() => handleLinkClick('contacts')} style={getLinkStyle('contacts')}>
                            <i><BsPeople /></i>{t.sidebar.contacts}
                        </p>
                    </div>

                    <div className="side_link" onClick={() => toggleSection('properties')} style={getLinkStyle('properties')}>
                        <div className="main_side_link_cont">
                            <i><BsHouses /></i>{t.sidebar.properties}
                        </div>
                        <i className={`fa-solid ${expandedSection === 'properties' ? "fa-chevron-up" : "fa-chevron-down"} chevron_side_link`}></i>
                    </div>
                    {expandedSection === 'properties' && (
                        <div className="link_container_anim link_container_expanded">
                            <div className="side_link_opened" onClick={() => handleLinkClick('properties')}>
                                <div className="side_link_bar">
                                    <div className="side_link_bar_top"></div>
                                    <div className="side_link_bar_bottom"></div>
                                </div>
                                <p>{t.sidebar.manage_properties}</p>
                            </div> 
                            {Object.entries(properties).map(([key, property]) => (
                                <div className="side_link_opened blocked" key={key} onClick={() => handleLinkClick(`properties/view/${key}`)}>
                                    <div className="side_link_bar">
                                        <div className="side_link_bar_top"></div>
                                        <div className="side_link_bar_bottom"></div>
                                    </div>
                                    <p>{property.property_name || "Le mie proprietà"}</p>
                                    <span className="building_span">Building</span>
                                </div>
                            ))}
                        </div>
                    )}

                    <div className="side_link" onClick={() => toggleSection('tenants')} style={getLinkStyle('tenants')}>
                        <div className="main_side_link_cont">
                            <i><TbUserScan /></i>{t.sidebar.activity}
                        </div>
                        <i className={`fa-solid ${expandedSection === 'tenants' ? "fa-chevron-up" : "fa-chevron-down"} chevron_side_link`}></i>
                    </div>
                    {expandedSection === 'tenants' && (
                        <div className="link_container_anim link_container_expanded">
                            <div className="side_link_opened blocked" onClick={() => handleLinkClick('screening')}>
                                <div className="side_link_bar">
                                    <div className="side_link_bar_top"></div>
                                    <div className="side_link_bar_bottom"></div>
                                </div>
                                <p>Screening</p>
                                <span className="building_span">Building</span>
                            </div>
                            <div className="side_link_opened blocked" onClick={() => handleLinkClick('candidates')}>
                                <div className="side_link_bar">
                                    <div className="side_link_bar_top"></div>
                                    <div className="side_link_bar_bottom"></div>
                                </div>
                                <p>{t.sidebar.candidates}</p>
                                <span className="building_span">Building</span>
                            </div>
                            <div className="side_link_opened" onClick={() => handleLinkClick('tenants')}>
                                <div className="side_link_bar">
                                    <div className="side_link_bar_top"></div>
                                    <div className="side_link_bar_bottom"></div>
                                </div>
                                <p>{t.sidebar.tenants}</p>
                            </div>
                            <div className="side_link_opened blocked" onClick={() => handleLinkClick('tenant-documents')}>
                                <div className="side_link_bar">
                                    <div className="side_link_bar_top"></div>
                                    <div className="side_link_bar_bottom"></div>
                                </div>
                                <p>{t.sidebar.documents}</p>
                                <span className="building_span">Building</span>
                            </div>
                        </div>
                    )}

                    <div className="side_link" onClick={() => toggleSection('maintenance')} style={getLinkStyle('maintenance')}>
                        <div className="main_side_link_cont">
                            <i><LiaToolsSolid /></i>{t.sidebar.maintenance}
                        </div>
                        <i className={`fa-solid ${expandedSection === 'maintenance' ? "fa-chevron-up" : "fa-chevron-down"} chevron_side_link`}></i>
                    </div>
                    {expandedSection === 'maintenance' && (
                        <div className="link_container_anim link_container_expanded">
                            <div className="side_link_opened blocked" onClick={() => handleLinkClick('operators')}>
                                <div className="side_link_bar">
                                    <div className="side_link_bar_top"></div>
                                    <div className="side_link_bar_bottom"></div>
                                </div>
                                <p>{t.sidebar.maintainers}</p>
                                <span className="building_span">Building</span>
                            </div>
                            <div className="side_link_opened blocked" onClick={() => handleLinkClick('requests')}>
                                <div className="side_link_bar">
                                    <div className="side_link_bar_top"></div>
                                    <div className="side_link_bar_bottom"></div>
                                </div>
                                <p>{t.sidebar.maint_request}</p>
                                <span className="building_span">Building</span>
                            </div>
                        </div>
                    )}

                    <div className="side_link" onClick={() => toggleSection('finance')} style={getLinkStyle('finance')}>
                        <div className="main_side_link_cont">
                            <i><LuBarChart3 /></i>Finance
                        </div>
                        <i className={`fa-solid ${expandedSection === 'finance' ? "fa-chevron-up" : "fa-chevron-down"} chevron_side_link`}></i>
                    </div>
                    {expandedSection === 'finance' && (
                        <div className="link_container_anim link_container_expanded">
                            <div className="side_link_opened blocked" onClick={() => handleLinkClick('invoices')}>
                                <div className="side_link_bar">
                                    <div className="side_link_bar_top"></div>
                                    <div className="side_link_bar_bottom"></div>
                                </div>
                                <p>Invoices</p>
                                <span className="building_span">Building</span>
                            </div>
                            <div className="side_link_opened blocked" onClick={() => handleLinkClick('cash-flow')}>
                                <div className="side_link_bar">
                                    <div className="side_link_bar_top"></div>
                                    <div className="side_link_bar_bottom"></div>
                                </div>
                                <p>Cash-Flow</p>
                                <span className="building_span">Building</span>
                            </div>
                            <div className="side_link_opened blocked" onClick={() => handleLinkClick('costs')}>
                                <div className="side_link_bar">
                                    <div className="side_link_bar_top"></div>
                                    <div className="side_link_bar_bottom"></div>
                                </div>
                                <p>Costs</p>
                                <span className="building_span">Building</span>
                            </div>
                        </div>
                    )}
                </div>

                <div className="sidebar_body_second">
                    <div className="side_link" style={getLinkStyle('files')}>
                        <div className="main_side_link_cont">
                            <i><IoIosCloudOutline /></i> Cloud storage
                        </div>
                    </div>

                    <div className="link_container_anim link_container_expanded">
                        <div className="side_link_opened blocked" onClick={() => handleLinkClick('main-directory')}>
                            <div className="side_link_bar">
                                <div className="side_link_bar_top"></div>
                                <div className="side_link_bar_bottom"></div>
                            </div>
                            <p>Main directory</p>
                            <span className="building_span">Building</span>
                        </div>
                        <div className="side_link_opened blocked" onClick={() => handleLinkClick('images')}>
                            <div className="side_link_bar">
                                <div className="side_link_bar_top"></div>
                                <div className="side_link_bar_bottom"></div>
                            </div>
                            <p><i><BsFolder /></i> Test folder</p>
                            <span className="building_span">Building</span>
                        </div>
                    </div>

                </div>
            </div>

            <div className="sidebar_footer">
                <div className="link_container link_cont_footer_sidebar">
                    <div className="profile_cont_link">
                        <i><RiTranslate2 /></i>
                        <select className="language_select_sidebar" onChange={handleLanguageChange} value={language}>
                            <option value="en">English</option>
                            <option value="it">Italiano</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LLSidebar;
