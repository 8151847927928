import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TbBrandBooking } from "react-icons/tb";
import { CgWebsite } from "react-icons/cg";

const SharePropModal = ({ show, handleClose, propertyName, prop_key }) => {
    console.log(propertyName, prop_key)

    return (
        <Modal show={show} onHide={handleClose} className="modal_share_prop">
            <div className='share_modal_body'>
                <div className='header_modal_share_prop'>
                    <p>Post your property anywhere!</p>

                </div>
                <div className='main_modal_share_prop'>
                    <div className='main_modal_share_prop_cont'>
                        <div className='share_prop_item'>
                            <i className='fa-solid fa-layer-group'></i>
                            <p>Paypermate</p>
                            <span>Post</span>
                        </div>
                        <div className='share_prop_item'>
                            <i><CgWebsite/></i>
                            <p>My Website</p>
                            <span>Connect</span>
                        </div>
                        <div className='share_prop_item'>
                            <i className='fa-brands fa-airbnb'></i>
                            <p>AirBnb</p>
                            <span>Connect</span>
                        </div>
                        <div className='share_prop_item'>
                            <i><TbBrandBooking /></i>
                            <p>Booking.com</p>
                            <span>Connect</span>
                        </div>
                        <div className='share_prop_item'>
                            <i className='fa-brands fa-google'></i>
                            <p>Google</p>
                            <span>Connect</span>
                        </div>
                        <div className='share_prop_item'>
                            <i className='fa-brands fa-facebook'></i>
                            <p>Facebook</p>
                            <span>Connect</span>
                        </div>
                        <div className='share_prop_item'>
                            <i className='fa-brands fa-whatsapp'></i>
                            <p>WhatsApp</p>
                            <span>Connect</span>
                        </div>
                    </div>
                </div>
                <div className='footer_modal'>

                </div>
            </div>
        </Modal>
    );
};

export default SharePropModal;
