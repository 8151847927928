import React, { useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';
import { FaSignature } from "react-icons/fa";
import { FaQrcode } from "react-icons/fa6";
import { IoMdShare } from "react-icons/io";

const SignatureModal = ({ show, onClose, signer, onSaveSignature }) => {
    const canvasRef = useRef(null);
    const [isPhone, setIsPhone] = useState(false);
    const [qrCodeUrl, setQrCodeUrl] = useState('');

    const handleClearCanvas = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
    };

    const handleDraw = (e) => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        if (e.buttons !== 1) return;
        context.lineWidth = 2;
        context.lineCap = 'round';
        context.strokeStyle = '#000';

        context.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
        context.stroke();
        context.beginPath();
        context.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    };

    const handleDeviceChoice = (device) => {
        setIsPhone(device === 'Phone');
        if (device === 'Phone') {
            const uniqueSigningUrl = `https://www.paypermate.com/payper-sign/${"12345"}`;
            setQrCodeUrl(uniqueSigningUrl);
        }
    };

    const handleShare = () => {
        const urlToShare = `https://www.paypermate.com/sign/${signer}`;

        if (navigator.share) {
            navigator.share({
                title: 'Sign the Document',
                text: 'Please sign the document by following the link below:',
                url: urlToShare,
            })
                .then(() => console.log('Sharing successful'))
                .catch((error) => console.log('Sharing failed', error));
        } else {
            navigator.clipboard.writeText(urlToShare)
                .then(() => alert('Link copied to clipboard!'))
                .catch((error) => console.error('Copy failed', error));
        }
    };

    const handleSave = () => {
        const canvas = canvasRef.current;
        const signatureData = canvas.toDataURL('image/png'); // Convert canvas to image data (base64)
        onSaveSignature(signatureData); // Pass the signature to the parent
    };

    const HandleClean = () => {
        setIsPhone(false);
        setQrCodeUrl("");

        if (canvasRef.current) {
            handleClearCanvas();
        }

        onClose();
    };

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Body>
                <div className='signing_modal_header_choise'>
                    <button
                        className={`signing_modal_header_btn ${!isPhone ? 'sign_active' : ''}`}
                        onClick={() => handleDeviceChoice('PC')}
                    >
                        <FaSignature />
                    </button>
                    <button
                        className={`signing_modal_header_btn ${isPhone ? 'sign_active' : ''}`}
                        onClick={() => handleDeviceChoice('Phone')}
                    >
                        <FaQrcode />
                    </button>
                    <button
                        className={`signing_modal_header_btn ${isPhone ? 'sign_active' : ''}`}
                        onClick={() => handleShare()}
                    >
                        <IoMdShare />
                    </button>
                </div>

                {isPhone ? (
                    <div className='qr_code_container'>
                        <QRCodeCanvas value={qrCodeUrl} size={180} />
                        <p className='qr_code_title'>Scanerizza il codice QR per firmare con il cellulare.</p>
                        {/* <p className='qr_code_title'>Scan this QR code to sign with your phone.</p> */}
                        <div className='modal_footer'>
                            <i></i>
                            <div className='action_buttons_container'>
                                <button className='close_modal_btn' onClick={HandleClean}>
                                    Chiudi
                                </button>
                                <i></i>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='canvas_btn_cont'>
                        <canvas
                            ref={canvasRef}
                            onMouseDown={handleDraw}
                            onMouseMove={handleDraw}
                            onMouseUp={() => canvasRef.current.getContext('2d').beginPath()}
                            onMouseLeave={() => canvasRef.current.getContext('2d').beginPath()}
                            className='canvas_signing_modale'
                        />

                        <div className='modal_footer'>
                            <button className='clean_btn' onClick={handleClearCanvas}>
                                Clear Signature
                            </button>
                            <div className='action_buttons_container'>
                                <button className='close_modal_btn' onClick={HandleClean}>
                                    Chiudi
                                </button>
                                <button className='save_modal_btn' onClick={handleSave}>
                                    Salva
                                </button>
                            </div>
                        </div>
                    </div>
                )}

            </Modal.Body>
        </Modal>
    );
};

export default SignatureModal;
