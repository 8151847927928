import React from "react";
import { GiPapers } from "react-icons/gi";
import { MdOutlineStackedLineChart } from "react-icons/md";
import { FaBoxOpen } from "react-icons/fa";
import { BsStack } from "react-icons/bs";

const Logo = () => {
    return (
        <div className="logo_page_cont">
            {/* <div className="logo_cont_page">
                <span className="ia_span"><GiPapers /></span>
                <p>Paypermate</p>
            </div>
            <div className="logo_cont_page">
                <span className="ia_span"><MdOutlineStackedLineChart /></span>
                <p>Paypermate</p>
            </div>
            <div className="logo_cont_page">
                <span><FaBoxOpen /></span>
                <p>FlatJoruney</p>
                <span className="ia_span last_span_title">AI</span>
            </div>
            <div className="logo_cont_page">
                <span><BsStack /></span>
                <p>FlatJoruney</p>
                <span className="ia_span last_span_title">AI</span>
            </div> */}

            <p id="title_logo">
                {/* <FaBoxOpen /> */}
                <i className="fa-solid fa-layer-group"></i>
                Paypermate
            </p>
            <p className="slogan">Empowering property owners with data-driven success.</p>
        </div>
    )
}

export default Logo