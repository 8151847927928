import React from "react";

const SideHome = ({ showSideMenu, toggleSideMenu }) => {
    return (
        <div className={`side_home_cont ${showSideMenu ? 'expanded' : 'collapsed'}`}>
            <div className="chevron_side_home_cont">
                <button onClick={toggleSideMenu}>
                    <i className={`fa-solid ${showSideMenu ? 'fa-chevron-left' : 'fa-chevron-right'}`}></i>
                </button>
            </div>
        </div>
    );
};

export default SideHome;
