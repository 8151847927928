import React, { useEffect, useState } from "react";
import { useLanguage } from "../Translations/LenguageContext";
import { FaRegUser, FaRobot, FaFileAlt, FaCreditCard } from "react-icons/fa";

function PaymentProcess() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [currentTime, setCurrentTime] = useState(new Date());
    const { t } = useLanguage();

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % 3);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const timeInterval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(timeInterval);
    }, []);

    const formatTime = (date) => {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const formatDate = (date) => {
        return date.toLocaleDateString([], { weekday: 'short', day: 'numeric', year: 'numeric' });
    };

    return (
        <div className="payment_process_container">
            <div className="payment_process">
                <p className="steps_title">{t.payment_process.title}</p>
                <p className="steps_desc">{t.payment_process.desc}</p>
            </div>
            <div className="pay_cont">
                <div className="pay_container">
                    <div className="phone_cont">
                        <div className="phone_screen">
                            <div className="phone_camera"></div>
                            <div className="phone_content">
                                <div className="phone_data">
                                    <p className="phone_hour">{formatTime(currentTime)}</p>
                                    <p className="phone_date">{formatDate(currentTime)}</p>
                                </div>
                                <div className="phone_notification_cont">
                                    <div className={`phone_notification ${activeIndex === 0 ? "active" : ""}`}>
                                        <div className="message_container">
                                            <div className="message_header">
                                                <div className="message_title"><span className="ia_span"><i className="fa-solid fa-layer-group"></i></span> Paypermate</div>
                                                <div className="hour_mex">{t.now || "now"}</div>
                                            </div>
                                            <div className="message_not">
                                                <p>{t.payment_notifications.notification1}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`phone_notification ${activeIndex === 1 ? "active" : ""}`}>
                                        <div className="message_container">
                                            <div className="message_header">
                                                <div className="message_title"><span className="ia_span"><i className="fa-solid fa-layer-group"></i></span> Paypermate</div>
                                                <div className="hour_mex">{t.now || "now"}</div>
                                            </div>
                                            <div className="message_not">
                                                <p>{t.payment_notifications.notification2}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`phone_notification ${activeIndex === 2 ? "active" : ""}`}>
                                        <div className="message_container">
                                            <div className="message_header">
                                                <div className="message_title"><span className="ia_span"><i className="fa-solid fa-layer-group"></i></span> Paypermate</div>
                                                <div className="hour_mex">{t.now || "now"}</div>
                                            </div>
                                            <div className="message_not">
                                                <p>{t.payment_notifications.notification3}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="phone_bar"></div>
                        </div>
                    </div>
                    <div className="phone_shadow"></div>
                </div>
                <div className="pay_container">
                    <div className="pay_description">
                        <p className="pay_desc_title"><span className="ia_span">{t.secure_payments.title}</span></p>
                        <p>{t.secure_payments.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentProcess;
