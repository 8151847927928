import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../../Translations/LenguageContext";
import CanoneLibero from "../Contracts/ITA/CanoneLibero";
import Spinner from "../../../../components/Spinner";
import { useNavigate } from "react-router-dom";
import { IoSparkles } from "react-icons/io5";

const TenantDocument = ({ tenant, toggleComponent, isActive, userData }) => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [check, setCheck] = useState(false);
    const [selectedDocumentType, setSelectedDocumentType] = useState("template");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [dragActive, setDragActive] = useState(false);
    const [contractType, setContractType] = useState("custom");
    const [loading, setLoading] = useState(false); 
    const [error, setError] = useState({});
    const [ciRequired, setCiRequired] = useState(true);
    const [jobContractRequired, setJobContractRequired] = useState(true);
    const [notes, setNotes] = useState('');
    const [mandatoryReading, setMandatoryReading] = useState(false);
    const [mandatorySigning, setMandatorySigning] = useState([]);
    const [incomeStatementRequired, setIncomeStatementRequired] = useState(true);
    const [guarantDocumentsRequired, setGuarantDocumentsRequired] = useState(true);
    const [userId] = useState(sessionStorage.getItem("user_id"));


    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const files = Array.from(e.dataTransfer.files);
        setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
        setMandatorySigning((prevSigning) => [...prevSigning, ...Array(files.length).fill(false)]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragActive(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };

    const handleDeleteFile = (index) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setMandatorySigning((prevSigning) => prevSigning.filter((_, i) => i !== index));
    };

    const handleContractChange = (event) => {
        setContractType(event.target.value);
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
        setMandatorySigning((prevSigning) => [...prevSigning, ...Array(files.length).fill(false)]);
    };

    const handleClickUpload = () => {
        document.getElementById("fileInput").click();
    };

    const handleMandatorySigningChange = (index) => {
        setMandatorySigning((prevSigning) =>
            prevSigning.map((signing, i) => (i === index ? !signing : signing))
        );
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError({ message: 'Failed to upload documents. Please try again.', display: false });

        const formData = new FormData();
        uploadedFiles.forEach((file, index) => {
            formData.append('files[]', file);
            formData.append(`mandatory_signing_${index}`, mandatorySigning[index]);
        });

        const userId = sessionStorage.getItem("user_id");
        formData.append('user_id', userId);
        formData.append('tenant_id', tenant);
        formData.append('contractType', contractType);

        formData.append('ci_required', ciRequired);
        formData.append('job_contract_required', jobContractRequired);
        formData.append('notes', notes);
        formData.append('income_statement_required', incomeStatementRequired);
        formData.append('guarant_documents_required', guarantDocumentsRequired);
        formData.append('mandatory_reading', mandatoryReading);

        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/tenant_documents', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setCheck(true)
            toggleComponent("tenantAccount")
        } catch (error) {
            console.error('Error:', error);
            setError({ message: 'Failed to upload documents. Please try again.', display: true });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (tenant) {
            setLoading(true);
            fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_specific_tenant_data", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ userId: userId, tenant_id: tenant })
            })
                .then(response => response.json())
                .then(data => {
                    setLoading(false);
                    setError({ message: "", display: false });
                    const tenantData = data.tenant?.tenant_document_information || {};
                    if (Object.keys(tenantData).length > 0) {
                        toggleComponent("tenantAccount");
                        setCheck(true);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setError({ message: t.register_form.problem_back, display: true });
                });
        }
    }, [tenant, userId, t]);


    return (
        <div className={`tenant_label_input_cont ${check ? "label_input_cont_done" : ""}`}>
            <div className={`new_tenant_title_chevron_cont ${isActive ? "new_tenant_title_chevron_cont_open" : ""}`} onClick={() => toggleComponent("documents")}>
                <div className="new_tenant_title_desc_cont">
                    <p className="new_tenant_title">{t.tenants.document_request_title}</p>
                    <p className="description_tenant_title">{t.tenants.document_request_desc}</p>
                </div>
                <div className="new_tenant_check_chevron_cont">
                    <i className={`fa-regular ${check ? "fa-circle-check check_tenant" : "fa-circle circle_tenant"}`}></i>
                    <i className={`fa-solid ${isActive ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                </div>
            </div>

            <div className={`toggle_tenant_info_cont ${isActive ? "active_tenant_info" : ""}`}>
                <div className="documents_tenant_cont">
                    <div className="doc_type_compile_bar_choise">
                        <button
                            className={`doc_type_compile_bar_btn ${selectedDocumentType === "upload" ? "selected_option" : ""}`}
                            onClick={() => setSelectedDocumentType("upload")}
                        >
                            Carica file
                        </button>
                        <button
                            className={`doc_type_compile_bar_btn template ${selectedDocumentType === "template" ? "selected_option" : ""}`}
                            onClick={() => setSelectedDocumentType("template")}
                        >
                            <IoSparkles/> Template contratto
                        </button>
                    </div>

                    <div className="choise_documents">
                        <div
                            className={`document_split first_split ${selectedDocumentType === "template" ? "active" : ""}`}
                            style={{ width: selectedDocumentType === "template" ? "0%" : "0%" }}
                        >
                            <div className="contract_prebuild_header">
                                <select
                                    className="contract_type"
                                    value={contractType}
                                    onChange={handleContractChange}
                                >
                                    <option value={""}>-</option>
                                    <option value={"custom"}>Personale</option>
                                    <option value={"canone_libero"}>Canone libero 4+4</option>
                                    <option value={"canone_concordato"}>Canone concordato 3+2</option>
                                    <option value={"contratto_transitorio"}>Contratto transitorio</option>
                                    <option value={"uso_commerciale"}>Uso commerciale</option>
                                </select>
                            </div>
                            {contractType === "canone_libero" ? (
                                <CanoneLibero userData={userData} />
                            ) : (
                                <div className="contract_container">
                                    <div className="contract_content">
                                        <div className='no_data_mex_cont'>
                                            <p className='no_data_mex'>Contratto non ancora disponibile</p>
                                            <div className='no_data_img'></div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div
                            className={`document_split second_split ${selectedDocumentType === "upload" ? "drag_active" : ""}`}
                            style={{
                                width: selectedDocumentType === "upload" ? "100%" : "100%",
                            }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            onDragLeave={handleDragLeave}
                        >
                            <div className={`document_split_second_inner ${dragActive ? "drag_active" : ""}`}>
                                <i className="fa-solid fa-upload upload_icon"></i>
                                <p className="document_split_second_title">Clicca <span className="upload_file_link" onClick={handleClickUpload}>qui</span> per caricare tutti i documenti necessari</p>
                                <p className="document_split_second_subtitle">(PDF, DOC, DOCX, TXT, XLS, XLSX, CSV, JPEG, PNG, ZIP, RAR, XML etc...)</p>
                                <input
                                    id="fileInput"
                                    type="file"
                                    multiple
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                />

                                <div className={`personal_docs_upload ${selectedDocumentType === "upload" ? "drag_active" : ""}`}
                                    style={{
                                        width: selectedDocumentType === "upload" ? "100%" : "100%",
                                        backgroundColor: dragActive ? "var(--lightGrey)" : "",
                                        border: dragActive ? "1px solid var(--mainColor)" : "",
                                        transition: "all 0.3s ease"
                                    }}
                                >
                                    {uploadedFiles.length > 0 && (
                                        <div className="personal_docs_list">
                                            <p className="personal_docs_upload_title">Seleziona i documenti che l'inquilino deve firmare</p>
                                            {uploadedFiles.map((file, index) => (
                                                <div className="selected_file" key={index}>
                                                    <div className="selected_filename_cont">
                                                        <input
                                                            type="checkbox"
                                                            checked={mandatorySigning[index] || false}
                                                            onChange={() => handleMandatorySigningChange(index)}
                                                        />
                                                        <p className="selected_filename">{file.name}</p>
                                                    </div>
                                                    <button
                                                        className="delete_selected_file"
                                                        onClick={() => handleDeleteFile(index)}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="needed_requests">
                        <p className="needed_requests_title">Altre informazioni</p>
                        <p className="needed_requests_subtitle">Inserisci informazioni aggiuntive o documenti da chiedere all'inquilino</p>
                        <div className="needed_requests_cont">
                            <div className="tenant_info_single_cont">
                                <div className="tenant_lab_in_cont">
                                    <label className="tenant_label">Richiedere carta d'identità?</label>
                                    <select
                                        id="ci_required"
                                        name="ci_required"
                                        className={`tenant_input`}
                                        value={ciRequired}
                                        onChange={(e) => setCiRequired(e.target.value)}
                                    >
                                        <option value={true}>SI</option>
                                        <option value={false}>NO</option>
                                    </select>
                                </div>
                                <div className="tenant_lab_in_cont">
                                    <label className="tenant_label">Richiedere contratto di lavoro?</label>
                                    <select
                                        id="job_contract_required"
                                        name="job_contract_required"
                                        className={`tenant_input`}
                                        value={jobContractRequired}
                                        onChange={(e) => setJobContractRequired(e.target.value)}
                                    >
                                        <option value={true}>SI</option>
                                        <option value={false}>NO</option>
                                    </select>
                                </div>
                                <div className="tenant_lab_in_cont bordered">
                                    <label className="tenant_label">Lascia una nota all'inquilino</label>
                                    <textarea
                                        id="notes"
                                        name="notes"
                                        className={`tenant_input`}
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                    />
                                    <div className="requests_fields">
                                        <label className="tenant_label">Lettura obbligatoria</label>
                                        <input
                                            type="checkbox"
                                            checked={mandatoryReading}
                                            onChange={(e) => setMandatoryReading(e.target.checked)}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="tenant_info_single_cont">
                                <div className="tenant_lab_in_cont">
                                    <label className="tenant_label">Richiedere dichiarazione dei redditi?</label>
                                    <select
                                        id="income_statement_required"
                                        name="income_statement_required"
                                        className={`tenant_input`}
                                        value={incomeStatementRequired}
                                        onChange={(e) => setIncomeStatementRequired(e.target.value)}
                                    >
                                       <option value={true}>SI</option>
                                       <option value={false}>NO</option>
                                    </select>
                                </div>

                                <div className="tenant_lab_in_cont">
                                    <label className="tenant_label">Richiedere gli stessi documenti al GARANTE?</label>
                                    <select
                                        id="guarant_documents_required"
                                        name="guarant_documents_required"
                                        className={`tenant_input`}
                                        value={guarantDocumentsRequired}
                                        onChange={(e) => setGuarantDocumentsRequired(e.target.value)}
                                    >
                                        <option value={true}>SI</option>
                                        <option value={false}>NO</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="save_tenant_info_btn_container">
                    <button className="save_tenant_info_btn" onClick={handleSubmit}>
                        <Spinner loading={loading} err={error} />
                        <span>{t.tenants.save_btn}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TenantDocument;
