import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../Translations/LenguageContext";
import LLHome from "./LLSections/Home/Home";
import TenantHome from "./TenantSection/Home/Home";

function Home({ userData, no_session }) {
    const navigate = useNavigate();
    const { t } = useLanguage();
    const [noHomeSession, setNoHomeSession] = useState(false);
    const [registrationStatus, setRegistrationStatus] = useState(3);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const checkSession = () => {
            const userId = sessionStorage.getItem("user_id");
            if (!userId) {
                setNoHomeSession(true);
                setTimeout(() => {
                    navigate(`/login`);
                }, 3000);
                return false;
            }
            return true;
        };

        const fetchRegistrationProgress = async () => {
            try {
                const userId = sessionStorage.getItem("user_id");
                const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/check_registration", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id: userId }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setRegistrationStatus(data.completed_steps ?? 3);
            } catch (error) {
                console.error("Error fetching registration progress:", error);
                setError("Failed to fetch registration progress. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        if (checkSession()) {
            fetchRegistrationProgress();
        } else {
            setLoading(false);
        }
    }, [navigate]);

    if (loading) {
        return <div className="main_view_section">
            <div className='loading_mex_cont'>
                <p className='loading_mex'>{t.loading}</p>
                <i className='fa-solid fa-box-open'></i>
            </div>
        </div>
    }

    if (no_session || noHomeSession) {
        return (
            <div className="main_view_section">
                <div className="no_session_cont">
                    <p className="no_session_mex">La tua sessione è scaduta... stai per essere reindirizzato nella login</p>
                    <div className="no_data_img"></div>
                </div>
            </div>
        )
    }

    if (error) {
        return (
            <div className="main_view_section">
                <p className="error_message">{error}</p>
            </div>
        )
    }

    if (userData) {
        if (userData.user_type === "owner") {
            return <LLHome userData={userData} no_session={no_session} />;
        } else {
            return <TenantHome userData={userData} no_session={no_session}/>;
        }
    }

    return null
}

export default Home;
