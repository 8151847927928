import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useLanguage } from "../../../Translations/LenguageContext";
import { useNavigate } from "react-router-dom";
import AddPropertieModal from "./AddPropertieModal";
import DeletePropertyModal from "./MicroComponents/DeletePropertieModal";
import SharePropModal from "./MicroComponents/SharePropModal";

const PropertiesList = ({ properties, onPropertyDeleted, onPropertieAddedOrUpdated }) => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [selectedPropertyKey, setSelectedPropertyKey] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showShareModal, setShowSharePropModal] = useState(false);
    const [selectedPropertyName, setSelectedPropertyName] = useState(null);
    const [popupType, setPopupType] = useState("success");
    const [popupActive, setPopupActive] = useState(false);
    const [userId, setUserId] = useState(sessionStorage.getItem("user_id"));
    const [imageIndices, setImageIndices] = useState({});

    const preloadImages = (files) => {
        files.forEach(file => {
            const img = new Image();
            img.src = file.url;
        });
    };

    useEffect(() => {
        properties.forEach(([key, property]) => {
            if (property.files && property.files.length > 0) {
                preloadImages(property.files);
            }
        });
    }, [properties]);

    const handleEditClick = (key, property) => {
        navigate(`${key}`)
    };

    const handleDeleteClick = (key, propertyName) => {
        setSelectedPropertyKey(key);
        setShowDeleteModal(true);
        setSelectedPropertyName(propertyName);
    };

    const handleSharePropClick = (key, propertyName) => {
        setShowSharePropModal(true)
        setSelectedPropertyName(propertyName);
        setSelectedPropertyKey(key);
    }

    const handleDeleteConfirmed = async () => {
        setShowDeleteModal(false);
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/delete_property', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId, property_key: selectedPropertyKey }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete property');
            }

            onPropertyDeleted(selectedPropertyKey);
            setPopupActive(true);
            setPopupType("success");
        } catch (error) {
            setPopupType("error");
            setPopupActive(true);
            console.error(error);
        }
    };

    const OpenProperty = (prop_id) => {
        navigate(`view/${prop_id}`);
    };

    const handleNextImage = (propertyKey, property) => {
        setImageIndices((prevIndices) => {
            const nextIndex = (prevIndices[propertyKey] || 0) === property.images.length - 1 ? 0 : (prevIndices[propertyKey] || 0) + 1;
            return { ...prevIndices, [propertyKey]: nextIndex };
        });
    };

    const handlePrevImage = (propertyKey, property) => {
        setImageIndices((prevIndices) => {
            const prevIndex = (prevIndices[propertyKey] || 0) === 0 ? property.images.length - 1 : (prevIndices[propertyKey] || 0) - 1;
            return { ...prevIndices, [propertyKey]: prevIndex };
        });
    };

    return (
        <div className="properties_card_container">
            {properties.map(([key, property], index) => {
                const currentImageIndex = imageIndices[key] || 0;
                return (
                    <div className="property_card" key={property.key || index}>
                        <div className="header_img_cont">
                            <div className="property_btn_action">
                                <p>{t.properties[property.available]}</p>
                            </div>
                            <div className="property_btn_action" onClick={() => handleSharePropClick(key, property.property_name)}>
                                <i className="fa-solid fa-arrow-up-from-bracket"></i>
                            </div>
                        </div>

                        {property.images && property.images.length > 0 ? (
                            <img
                                onClick={() => OpenProperty(key)}
                                className="property_img"
                                src={property.images[currentImageIndex].url}
                                alt="Property"
                            />
                        ) : (
                            <div className="property_img_blotter"></div>
                        )}
                        <div className="property_img_scroll_cont">
                            <div className="property_btn_action_scroll" onClick={() => handlePrevImage(key, property)}>
                                <i className="fa-solid fa-chevron-left"></i>
                            </div>
                            <div className="property_btn_action_scroll" onClick={() => handleNextImage(key, property)}>
                                <i className="fa-solid fa-chevron-right"></i>
                            </div>
                        </div>
                        <div className="footer_img_cont">
                            <Dropdown>
                                <div className="property_btn_action_toggle">
                                    <Dropdown.Toggle className="custom-dropdown-toggle" as="div">
                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="custom-dropdown-menu">
                                        <Dropdown.Item
                                            className="drop_down_item"
                                            onClick={() => handleEditClick(key, property)}
                                        >
                                            <i className="fa-solid fa-pencil"></i> {t.edit}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className="drop_down_item delete"
                                            onClick={() => handleDeleteClick(key, property.property_name)}
                                        >
                                            <i className="fa-solid fa-trash"></i> {t.delete}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </div>
                            </Dropdown>
                        </div>

                        <div className="property_description_cont">
                            <p className="property_title_card">{property.property_name}</p>
                            <p className="property_location_card">
                                {property.street}, {property.street_number} - {property.city} ({property.country})
                            </p>
                            <p className="property_amount_card">{property.rental_amount || property.sale_amount} {property.currency}</p>
                        </div>
                    </div>
                );
            })}
            <DeletePropertyModal
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                onDeleteConfirmed={handleDeleteConfirmed}
                propertyName={selectedPropertyName}
            />
            <SharePropModal
                show={showShareModal}
                handleClose={() => setShowSharePropModal(false)}
                propertyName={selectedPropertyName}
                prop_key={selectedPropertyKey}
            />
        </div>
    );
};

export default PropertiesList;
