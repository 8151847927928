import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../../Translations/LenguageContext";
import { useNavigate } from "react-router-dom";
import TenantPersonalInformation from "../Personal-Info/TenantPersonalInformation";
import TenantPropertieInformation from "../Property-Info/TenantPropertieInformation";
import TenantDocument from "../Docs-info/TenantDocumentsUpload";
import TenantAccount from "../Tenant-Account/TenantAccount";

const NewTenant = ({userData}) => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [tenant_id, setTenantId] = useState("");
    const [activeComponent, setActiveComponent] = useState("personalInfo");

    const BackToPreviousPage = () => {
        navigate("/control_panel/tenants");
    };

    const tenant = (id) => {
        setTenantId(id);
    };

    const toggleComponent = (componentName) => {
        setActiveComponent(activeComponent === componentName ? null : componentName);
    };

    return (
        <div className="main_view_section">
            <p className="title_main_view">
                <i
                    onClick={BackToPreviousPage}
                    className="fa-solid fa-chevron-left back-btn_chevron"
                ></i>
                {t.tenants.new_tenant_title}
            </p>
            <div className="new_tenant_form">
                <TenantPersonalInformation
                    tenant={tenant}
                    new_update={"new"}
                    tenantId={false} 
                    toggleComponent={toggleComponent}
                    isActive={activeComponent === "personalInfo"}
                />
                <TenantPropertieInformation
                    tenant={tenant_id}
                    toggleComponent={toggleComponent}
                    isActive={activeComponent === "propertyInfo"}
                />
                <TenantDocument
                    tenant={tenant_id}
                    toggleComponent={toggleComponent}
                    isActive={activeComponent === "documents"}
                    userData={userData}
                />
                <TenantAccount
                    tenant={tenant_id}
                    toggleComponent={toggleComponent}
                    isActive={activeComponent === "tenantAccount"}
                    userData={userData}
                />
            </div>
        </div>
    );
};

export default NewTenant;
