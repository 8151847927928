import React from "react";
import { useLanguage } from "../Translations/LenguageContext";
import { FaRegUser, FaRobot, FaFileAlt, FaCreditCard, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoSparkles } from "react-icons/io5";

// Map icon names to actual components
const iconMap = {
    FaRegUser: <FaRegUser />,
    IoSparkles: <IoSparkles />,
    FaFileAlt: <FaFileAlt />,
    FaCreditCard: <FaCreditCard />
};

// Array of colors to cycle through
const colors = ["var(--mainColor)", "var(--mainColor)", "var(--secondColor)", "#007aff"];
const rainbowColors = ["var(--danger)", "var(--secondColor)", "#007aff", "var(--mainColor)"];

function OnBoardingSection() {
    const { t } = useLanguage();

    const splitDescription = (description) => {
        const words = description.split(' ');
        const firstTwoWords = words.slice(0, 2).join(' ');
        const restOfText = words.slice(2).join(' ');
        return { firstTwoWords, restOfText };
    };

    return (
        <div className="onboarding_container">
            <p className="steps_title">{t.onboarding.title}</p>
            <p className="steps_desc">{t.onboarding.desc}</p>
            <div className="onboarding_sections">
                {/* Render all cards */}
                {t.onboarding.titles.map((title, index) => {
                    const { firstTwoWords, restOfText } = splitDescription(t.onboarding.descriptions[index]);
                    const iconColor = colors[index % colors.length];

                    const isSecondCard = index === 1;
                    const gradientStyle = isSecondCard ? {
                        background: `linear-gradient(to right, ${rainbowColors.join(', ')})`,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent'
                    } : { color: iconColor };

                    return (
                        <div key={index} className={`card_onboarding`}>
                            <div className="card_title">
                                <span className="card_icon" style={isSecondCard ? gradientStyle : { color: iconColor, border: `2px solid ${iconColor}` }}>
                                    {React.cloneElement(iconMap[t.onboarding.icons[index]], { style: isSecondCard ? gradientStyle : { color: iconColor } })}
                                </span>
                            </div>
                            <div className="card_text">
                                <p>
                                    {isSecondCard ? (
                                        <>
                                            <strong style={gradientStyle}>
                                                {firstTwoWords}
                                            </strong>{' '}
                                            {restOfText}
                                        </>
                                    ) : (
                                        <>
                                            <strong style={{ color: iconColor }}>
                                                {firstTwoWords}
                                            </strong>{' '}
                                            {restOfText}
                                        </>
                                    )}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="navigation_arrows">
                {/* Only show arrows on mobile */}
                <button className="arrow left">
                    <FaChevronLeft />
                </button>
                <button className="arrow right">
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default OnBoardingSection;
