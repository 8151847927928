import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../../Translations/LenguageContext";

const MonthlyPaymentsChoice = ({ selectedProperty, onMonthlyDetailsUpdate }) => {
    const { t } = useLanguage();
    const [isDepositIncluded, setIsDepositIncluded] = useState(false);
    const [isCommissionIncluded, setIsCommissionIncluded] = useState(false);
    const [monthlyPayments, setMonthlyPayments] = useState({});
    const [totalYearlyAmount, setTotalYearlyAmount] = useState(0);
    const [currency, setCurrency] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("sepa_payment");
    const [commissionCost, setCommissionCost] = useState(0);
    const [fromDate, setFromDate] = useState("");

    useEffect(() => {
        if (selectedProperty) {
            console.log(selectedProperty)
            const monthlyAmountPrice = selectedProperty.rental_amount || 0;
            const monthlyAmountExpenses = selectedProperty.condominium_fees || 0;
            const monthlyAmountCurrency = selectedProperty.currency || "";

            setCurrency(monthlyAmountCurrency);

            const initialPayments = Object.keys(t.months).reduce((acc, monthKey) => {
                acc[monthKey] = Number(monthlyAmountPrice) + Number(monthlyAmountExpenses);
                return acc;
            }, {});

            setMonthlyPayments(initialPayments);
        } else {
            setMonthlyPayments({});
            setTotalYearlyAmount(0);
            setCurrency("");
            setCommissionCost(0);
        }
    }, [selectedProperty, t.months]);

    useEffect(() => {
        const total = Object.values(monthlyPayments).reduce((sum, monthValue) => sum + Number(monthValue), 0);
        setTotalYearlyAmount(total);

        onMonthlyDetailsUpdate({
            monthly_ammount: monthlyPayments,
            currency: currency,
            deposit_included: isDepositIncluded,
            commission_included: isCommissionIncluded,
            payment_method: paymentMethod, 
            from_date: fromDate
        });
    }, [monthlyPayments, currency, isDepositIncluded, isCommissionIncluded, paymentMethod, fromDate, onMonthlyDetailsUpdate]);

    const handleInputChange = (monthKey, value) => {
        setMonthlyPayments(prev => ({
            ...prev,
            [monthKey]: Number(value)
        }));
    };

    const handleCheckboxChange = (event, groupName) => {
        const value = event.target.value;
        
        if (groupName === 'deposito') {
            setIsDepositIncluded(value === "yes");
        }

        if (groupName === 'commissioni') {
            setIsCommissionIncluded(value === "yes");
        }
    };

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    return (
        <div className="rent_ammount_choise">
            <p className="payment_title">{t.tenants.monthly_ammount}</p>
            <div className="from_to_container">
                <div className="from_to_payment">
                    <label>{t.tenants.from}</label>
                    <input type="date" onChange={handleFromDateChange} />
                </div>
            </div>
            {Object.keys(t.months).map((monthKey) => (
                <div key={monthKey} className="period_payment">
                    <label className="period_payment_label">{t.months[monthKey]}</label>
                    <input
                        className="period_payment_input"
                        type="number"
                        value={monthlyPayments[monthKey] || ""}
                        onChange={(e) => handleInputChange(monthKey, e.target.value)}
                    />
                    <p className="currency_period_payment">{currency}</p>
                </div>
            ))}
            <p className="total_value">{t.tenants.yearly_cashflow}: {totalYearlyAmount} {currency}
            {isDepositIncluded && (
                <span> + {selectedProperty.security_deposit} {currency} {t.new_property.security_deposit}</span>
            )}

            </p>

            {/* Payment Method Selection */}
            <div className="payment_meth_month">
                <p>{t.tenants.payment_meth_month_label}</p>
                <select className="fraction_payment" value={paymentMethod} onChange={handlePaymentMethodChange}>
                    <option value="sepa_payment">{t.tenants.sepa_payment}</option>
                    <option value="card_payment">{t.tenants.card_payment}</option>
                </select>
            </div>

            <div className="period_payment">
                <label className="period_payment_label">Includi Deposito</label>
                <div className="checkbox_label_cont">
                    <div className="checkbox_label">
                        <label>Si</label>
                        <input
                            type="checkbox"
                            name="deposito"
                            value="yes"
                            checked={isDepositIncluded}
                            onChange={(e) => handleCheckboxChange(e, 'deposito')}
                        />
                    </div>
                    <div className="checkbox_label">
                        <label>No</label>
                        <input
                            type="checkbox"
                            name="deposito"
                            value="no"
                            checked={!isDepositIncluded}
                            onChange={(e) => handleCheckboxChange(e, 'deposito')}
                        />
                    </div>
                </div>
            </div>

            {/* <div className="period_payment">
                <label className="period_payment_label">Includi commissioni</label>
                <div className="checkbox_label_cont">
                    <div className="checkbox_label">
                        <label>Si</label>
                        <input
                            type="checkbox"
                            name="commissioni"
                            value="yes"
                            checked={isCommissionIncluded}
                            onChange={(e) => handleCheckboxChange(e, 'commissioni')}
                        />
                    </div>
                    <div className="checkbox_label">
                        <label>No</label>
                        <input
                            type="checkbox"
                            name="commissioni"
                            value="no"
                            checked={!isCommissionIncluded}
                            onChange={(e) => handleCheckboxChange(e, 'commissioni')}
                        />
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default MonthlyPaymentsChoice;
