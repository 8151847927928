import React, { useRef, useState } from "react";
import { PiPencilSimpleSlashFill } from "react-icons/pi";
import { BsCloudUploadFill } from "react-icons/bs";

const SigningPageExternal = () => {
    const canvasRef = useRef(null);

    const handleClearCanvas = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
    };

    const handleDraw = (x, y) => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        context.lineWidth = 2;
        context.lineCap = 'round';
        context.strokeStyle = '#000';

        context.lineTo(x, y);
        context.stroke();
        context.beginPath();
        context.moveTo(x, y);
    };

    // Handle mouse events
    const handleMouseDown = (e) => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        context.beginPath();
        handleDraw(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    };

    const handleMouseMove = (e) => {
        if (e.buttons !== 1) return; // Only draw if the left mouse button is pressed
        handleDraw(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    };

    // Handle touch events
    const handleTouchStart = (e) => {
        const touch = e.touches[0];
        const rect = canvasRef.current.getBoundingClientRect();
        const x = touch.clientX - rect.left;
        const y = touch.clientY - rect.top;

        e.preventDefault(); // Prevent scrolling while drawing
        handleDraw(x, y);
    };

    const handleTouchMove = (e) => {
        const touch = e.touches[0];
        const rect = canvasRef.current.getBoundingClientRect();
        const x = touch.clientX - rect.left;
        const y = touch.clientY - rect.top;

        e.preventDefault(); // Prevent scrolling while drawing
        handleDraw(x, y);
    };

    const handleTouchEnd = () => {
        const context = canvasRef.current.getContext('2d');
        context.beginPath(); // Reset path after drawing
    };

    return (
        <div className="external_signature_container">
            <p className="external_signature_title">Firma con il dito</p>
            <div className='signing_external_header_choise'>
                <button
                    className="signing_external_header_btn"
                    onClick={handleClearCanvas}
                >
                    <p>Clean</p>
                    <PiPencilSimpleSlashFill/>
                </button>
                <button
                    className="signing_external_header_btn"
                >
                    <p>Carica in cloud</p>
                    <BsCloudUploadFill />
                </button>
            </div>
            <canvas
                ref={canvasRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={() => canvasRef.current.getContext('2d').beginPath()}
                onMouseLeave={() => canvasRef.current.getContext('2d').beginPath()}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                className='canvas_signing_external'
            />
        </div>
    )
}

export default SigningPageExternal;
