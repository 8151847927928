import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../Translations/LenguageContext";
import Spinner from "../components/Spinner";

const InvestorsLogin = () => {
    const navigate = useNavigate();
    const { t } = useLanguage();
    const [showPassword, setShowPassword] = useState(false);
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{10,20}$/;

    const validateEmail = (email) => {
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        return passwordRegex.test(password);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            startLogin();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    function startLogin() {
        setErr({ message: "", display: false })
        const password_reg = document.getElementById("password_reg");

        let error = false;
        let error_email = false;
        let error_password = false;


        if (password_reg.value === "") {
            error = true;
            password_reg.style.border = "1px solid var(--danger)";
        } else {
            password_reg.style.border = "";
        }

        if (error) {
            if (error_email) {
                setErr({ message: t.register_form.email_format, display: true });
            } else if (error_password) {
                setErr({ message: t.register_form.password_err, display: true });
            } else {
                setErr({ message: t.register_form.red_fields, display: true });
            }
        } else {
            loginUser(password_reg.value);
            setErr({ message: "", display: false });
        }
    }

    function loginUser(password) {
        setLoading(true);
        const dataToSend = {
            investors_code: password,
        };

        fetch('http://127.0.0.1:5000/login_investor', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setLoading(false);
                console.log(data)
                // if (data.message === "OK") {
                //     const db_id = data.user_info._id;
                //     const userType = data.user_info.user_type;
                //     sessionStorage.setItem("user_id", db_id);
                //     navigate("/control_panel/home");
                // } else if (data.message === "Invalid_email_or_password") {
                //     setErr({ message: t.login_form.invalid_email_pass, display: true });
                // } else if (data.message === "email_not_valid") {
                //     setErr({ message: t.register_form.email_format, display: true });
                // } else if (data.error) {
                //     setErr({ message: t.register_form.errors_back, display: true });
                // }
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
                setErr({ message: t.register_form.problem_back, display: true });
            });
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="main_frame">
            <div className="register_page">
                <div className="register_conts_reg">
                    <div className="reg_cont">
                        <p className="title_form" onClick={() => navigate("/")}><span className="ia_span"><i className="fa-solid fa-layer-group"></i></span> Paypermate investors club</p>
                        <div className="password_eye">
                            <input type={showPassword ? "text" : "password"} id="password_reg" placeholder={t.register_form.investors_code} className="register_form_first" />
                            <button className="show_passowrd" onClick={togglePasswordVisibility}>
                                <i className={`fa-regular fa-eye${showPassword ? "" : "-slash"}`}></i>
                            </button>
                        </div>
                        <button onClick={startLogin} className="start_btn">{t.login_form.button_login}</button>
                        <p className="already_present">Hai problemi di accesso? <span> Supporto</span></p>
                    </div>
                    <Spinner loading={loading} err={err} />
                </div>
            </div>
        </div>
    );
}

export default InvestorsLogin