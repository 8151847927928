import React from 'react';
import { useNavigate } from 'react-router-dom';

const Privacy_Policy = () => {
  const navigate = useNavigate()

  const goToHome = () => {
    navigate("/")
  }
  
  return (
    <div className='policies_container'>
      <div onClick={goToHome} className='policy_title_logo'>
        <i className="fa-solid fa-layer-group"></i>
        <p>Paypermate</p>
      </div>
      <div className='policies_cont'>
        <p className='title_policy'>Privacy Policy</p>
        <p><strong>Last updated: September 30, 2024</strong></p>

        <p className='declaration_policy'>
          This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information when you use the Service and informs you about your privacy rights and how the law protects you.
        </p>

        <p className='declaration_policy'>
          By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator.
        </p>

        <p className='section_titles'>1. Interpretation and Definitions</p>

        <p className='section_sub_title'>1.1 Interpretation</p>
        <p className='description_policy'>
          The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </p>

        <p className='section_sub_title'>1.2 Definitions</p>
        <p className='description_policy'>For the purposes of this Privacy Policy:</p>
        <ul className='description_policy'>
          <li><strong>Account:</strong> A unique account created for you to access our Service or parts of our Service.</li>
          <li><strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.</li>
          <li><strong>Company:</strong> Refers to Paypermate Srl, Milano, Via Emilio Gola 5 - 20143 (MI).</li>
          <li><strong>Cookies:</strong> Small files placed on your computer or mobile device by a website, containing details of your browsing history on that website among other uses.</li>
          <li><strong>Country:</strong> Refers to Italy.</li>
          <li><strong>Device:</strong> Any device that can access the Service, such as a computer, cellphone, or digital tablet.</li>
          <li><strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.</li>
          <li><strong>Service:</strong> Refers to the Website.</li>
          <li><strong>Service Provider:</strong> Any natural or legal person who processes the data on behalf of the Company.</li>
          <li><strong>Third-party Social Media Service:</strong> Any website or social network through which a user can log in or create an account to use the Service.</li>
          <li><strong>Usage Data:</strong> Data collected automatically when using the Service.</li>
        </ul>

        <p className='section_titles'>2. Collecting and Using Your Personal Data</p>

        <p className='description_policy_title'>Types of Data Collected</p>
        <p className='description_policy'>Personal Data</p>
        <p className='description_policy'>
          While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:
        </p>
        <ul className='description_policy'>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
        </ul>

        <p className='description_policy'>Usage Data</p>
        <p className='description_policy'>
          Usage Data is collected automatically when using the Service. Usage Data may include information such as your device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
        </p>

        <p className='description_policy'>
          When you access the Service by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers, and other diagnostic data.
        </p>

        <p className='description_policy'>Information from Third-Party SAAS</p>
        <p className='description_policy'>
          The Company allows you to create an account and log in to use the Service through the following Third-party SAAS (Software As A Service):
        </p>
        <ul className='description_policy'>
          {/* <li>Google</li>
          <li>Facebook</li>
          <li>Instagram</li>
          <li>Twitter</li>
          <li>LinkedIn</li> */}
          <li>Stripe</li>
        </ul>
        <p className='description_policy'>
          If you decide to register through or otherwise grant us access to a Third-Party Social Media Service, we may collect Personal data associated with your account.
        </p>

        <p className='description_policy'>Tracking Technologies and Cookies</p>
        <p className='description_policy'>
          We use Cookies and similar tracking technologies to track the activity on our Service and store certain information. Tracking technologies used include beacons, tags, and scripts.
        </p>

        <p className='section_titles'>3. Use of Your Personal Data</p>
        <p className='description_policy'>
          The Company may use Personal Data for the following purposes:
        </p>
        <ul className='description_policy'>
          <li>To provide and maintain our Service, including monitoring usage.</li>
          <li>To manage your Account.</li>
          <li>For the performance of a contract.</li>
          <li>To contact you regarding updates or information.</li>
          <li>To provide news and special offers.</li>
          <li>To manage your requests.</li>
          <li>For business transfers and evaluations.</li>
          <li>For other purposes such as data analysis and marketing.</li>
        </ul>

        <p className='section_titles'>4. Retention of Your Personal Data</p>
        <p className='description_policy'>
          The Company will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy.
        </p>

        <p className='section_titles'>5. Transfer of Your Personal Data</p>
        <p className='description_policy'>
          Your information may be transferred to — and maintained on — computers located outside of your jurisdiction.
        </p>

        <p className='section_titles'>6. Delete Your Personal Data</p>
        <p className='description_policy'>
          You have the right to delete or request that we assist in deleting the Personal Data we have collected about you.
        </p>

        <p className='section_titles'>7. Disclosure of Your Personal Data</p>
        <p className='description_policy'>
          The Company may disclose your Personal Data under certain circumstances, such as business transactions or law enforcement requests.
        </p>

        <p className='section_titles'>8. Security of Your Personal Data</p>
        <p className='description_policy'>
          The security of your Personal Data is important to us, but remember that no method of transmission over the Internet is 100% secure.
        </p>

        <p className='section_titles'>9. Children's Privacy</p>
        <p className='description_policy'>
          Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under 13.
        </p>

        <p className='section_titles'>10. Links to Other Websites</p>
        <p className='description_policy'>
          Our Service may contain links to other websites not operated by us. We are not responsible for the content or privacy policies of those sites.
        </p>

        <p className='section_titles'>11. Changes to this Privacy Policy</p>
        <p className='description_policy'>
          We may update our Privacy Policy from time to time and will notify you of any changes.
        </p>

        <p className='section_titles'>12. Contact Us</p>
        <p className='description_policy'>
          If you have any questions about this Privacy Policy, you can contact us at: <strong>hello@paypermate.com</strong>
        </p>
      </div>
    </div>
  );
};

export default Privacy_Policy;
