import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useLanguage } from "../../../Translations/LenguageContext";
import DeleteTenantModal from "./DeleteTenantModal";
import DisconnectTenantModal from "./DisconnectTenantModal";


const TenantsTable = ({ tenants, handleDropdownSelect, getPaymentStatus, section, fetchTenantData }) => {
    const { t } = useLanguage()

    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDisconnect, setShowModalDisconnect] = useState(false);
    const [selectedTenant, setSelectedTenant] = useState(null);
    const [selectedTenantKey, setSelectedTenantKey] = useState(null);
    const [selectedTenantName, setSelectedTenantName] = useState(null);
    const [CF_VAT, setCF_VAT] = useState(null);
    const [userId, setUserId] = useState(sessionStorage.getItem("user_id"))


    const handleClose = () => {
        setSelectedTenantKey(null);
        setShowModalDelete(false);
    };

    const handleCloseDisconnect = () => {
        setSelectedTenantKey(null);
        setShowModalDisconnect(false);
    };

    const handleShowShowDelete = (tenantId, name, surname, cf_vat) => {
        setSelectedTenantKey(tenantId);
        setSelectedTenantName(`${name || ''} ${surname || ''}`.trim());
        setCF_VAT(cf_vat);
        setShowModalDelete(true);
    };

    const handleShowShowDisconnect = (tenantId, name, surname, cf_vat) => {
        setSelectedTenantKey(tenantId);
        setSelectedTenantName(`${name || ''} ${surname || ''}`.trim());
        setCF_VAT(cf_vat);
        setShowModalDisconnect(true);
    };


    const handleCreateAccount = async (tenantId) => {

        fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/create_tenant_account_request", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ user_id: userId, tenant_id: tenantId })
        })
            .then(response => response.json())
            .then(data => {
                if (data.message === "OK") {
                    fetchTenantData()
                }

            })
            .catch(error => {

            });
    };





    if (section === "home_section") {
        return (
            <div className="tenants_table_cont">
                <table className="table_tenants responsive">
                    <thead className="thead">
                        <tr>
                            <th className="header_cell">ID</th>
                            <th className="header_cell">Name</th>
                            <th className="header_cell">CF/P.IVA</th>
                            <th className="header_cell">Stato</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(tenants).map(([tenantId, tenant]) => {
                            const missingInfo =
                                !tenant.personal_information ||
                                !tenant.tenant_property_information ||
                                !tenant.tenant_document_information ||
                                !tenant.tenant_account;

                            return (
                                <tr key={tenantId} className={`${missingInfo ? "missing_tenant_info" : ""}`}>
                                    <td className={`body_cell ${!tenantId ? "table-danger" : ""}`}>
                                        {tenantId || "N/A"}
                                    </td>

                                    <td className={`body_cell ${!tenant.personal_information?.name && !tenant.personal_information?.surname && !tenant.personal_information?.business_name ? "table-danger" : ""}`}>
                                        {tenant.personal_information?.name && tenant.personal_information?.surname
                                            ? `${tenant.personal_information.name} ${tenant.personal_information.surname}`
                                            : tenant.personal_information?.business_name || "N/A"}
                                    </td>

                                    <td className={`body_cell ${!(tenant.personal_information?.cf || tenant.personal_information?.vat) ? "table-danger" : ""}`}>
                                        {tenant.personal_information?.cf || tenant.personal_information?.vat || "N/A"}
                                    </td>

                                    <td className={`body_cell ${!tenant.tenant_property_information ? "missing_data_cel" : "no_missing_data_cell"}`}>
                                        {getPaymentStatus(tenant)}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    } else if (section === "tenant_section") {
        return (
            <div className="tenants_table_cont">
                <table className="table_tenants responsive">
                    <thead className="thead">
                        <tr>
                            <th className="header_cell_first_col"></th>
                            <th className="header_cell">ID Inquilino</th>
                            <th className="header_cell">Name</th>
                            <th className="header_cell">CF/P.IVA</th>
                            <th className="header_cell">Dati personali</th>
                            <th className="header_cell">Dati proprietà</th>
                            <th className="header_cell">Documenti</th>
                            <th className="header_cell">Connessione Account</th>
                            <th className="header_cell">Stato</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(tenants).map(([tenantId, tenant]) => {
                            const missingInfo =
                                !tenant.personal_information ||
                                !tenant.tenant_property_information ||
                                !tenant.tenant_document_information ||
                                !tenant.tenant_account;


                            return (
                                <tr key={tenantId} className={`${missingInfo ? "missing_tenant_info" : ""}`}>
                                    <td className="body_cell body_cell_first_col">
                                        <Dropdown>
                                            <Dropdown.Toggle className="custom-dropdown-toggle" as="div">
                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="custom-dropdown-menu">
                                                {missingInfo ? (
                                                    <Dropdown.Item
                                                        className="drop_down_item"
                                                        onClick={() => handleDropdownSelect("complete", tenantId)}
                                                    >
                                                        <i className="fa-solid fa-check"></i> {t.tenants.complete_reg}
                                                    </Dropdown.Item>
                                                ) : (
                                                    <Dropdown.Item
                                                        className="drop_down_item"
                                                        onClick={() => handleDropdownSelect("update", tenantId)}
                                                    >
                                                        <i className="fa-solid fa-pencil"></i> {t.edit}
                                                    </Dropdown.Item>
                                                )}

                                                {tenant.tenant_account === "pending" ? (

                                                    <Dropdown.Item
                                                        className="drop_down_item disconnect"
                                                        onClick={() => handleShowShowDisconnect(
                                                            tenantId,
                                                            tenant.personal_information?.business_name || tenant.personal_information?.name || 'N/A',
                                                            tenant.personal_information?.business_name ? '' : tenant.personal_information?.surname || '',
                                                            tenant.personal_information?.cf || tenant.personal_information?.vat || 'N/A',
                                                        )}
                                                    // onClick={() => handleDropdownSelect("delete", tenantId)}
                                                    >
                                                        <i className="fa-solid fa-pause"></i> Disconnect
                                                    </Dropdown.Item>
                                                ) :(
                                                    <Dropdown.Item
                                                        className="drop_down_item connect"
                                                        onClick={() => handleCreateAccount(tenantId)}
                                                    // onClick={() => handleDropdownSelect("delete", tenantId)}
                                                    >
                                                        <i className="fa-solid fa-play"></i> Send connection request
                                                    </Dropdown.Item>
                                                )
                                                }

                                                <Dropdown.Item
                                                    className="drop_down_item delete"
                                                    onClick={() => handleShowShowDelete(
                                                        tenantId,
                                                        tenant.personal_information?.business_name || tenant.personal_information?.name || 'N/A',
                                                        tenant.personal_information?.business_name ? '' : tenant.personal_information?.surname || '',
                                                        tenant.personal_information?.cf || tenant.personal_information?.vat || 'N/A',
                                                    )}
                                                // onClick={() => handleDropdownSelect("delete", tenantId)}
                                                >
                                                    <i className="fa-solid fa-trash"></i> {t.delete}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td className={`body_cell ${!tenantId ? "table-danger" : ""}`}>
                                        {tenantId || "N/A"}
                                    </td>

                                    <td className={`body_cell ${!tenant.personal_information?.name && !tenant.personal_information?.surname && !tenant.personal_information?.business_name ? "table-danger" : ""}`}>
                                        {tenant.personal_information?.name && tenant.personal_information?.surname
                                            ? `${tenant.personal_information.name} ${tenant.personal_information.surname}`
                                            : tenant.personal_information?.business_name || "N/A"}
                                    </td>

                                    <td className={`body_cell ${!(tenant.personal_information?.cf || tenant.personal_information?.vat) ? "table-danger" : ""}`}>
                                        {tenant.personal_information?.cf || tenant.personal_information?.vat || "N/A"}
                                    </td>

                                    <td className={`body_cell ${!tenant.personal_information ? "missing_data_cel" : "no_missing_data_cell"}`}>
                                        {tenant.personal_information ? (
                                            <div className="present_data_table_cont">
                                                <i className="fa-solid fa-circle-check"></i>
                                                <p>OK</p>
                                            </div>
                                        ) : (
                                            <div className="warning_table_cont">
                                                <i className="fa-solid fa-warning"></i>
                                                <p>Dati non presenti</p>
                                            </div>
                                        )}
                                    </td>

                                    <td className={`body_cell ${!tenant.tenant_property_information ? "missing_data_cel" : "no_missing_data_cell"}`}>
                                        {tenant.tenant_property_information ? (
                                            <div className="present_data_table_cont">
                                                <i className="fa-solid fa-circle-check"></i>
                                                <p>OK</p>
                                            </div>
                                        ) : (
                                            <div className="warning_table_cont">
                                                <i className="fa-solid fa-warning"></i>
                                                <p>Dati non presenti</p>
                                            </div>
                                        )}
                                    </td>

                                    <td className={`body_cell ${!tenant.tenant_document_information ? "missing_data_cel" : "no_missing_data_cell"}`}>
                                        {tenant.tenant_document_information ? (
                                            <div className="present_data_table_cont">
                                                <i className="fa-solid fa-circle-check"></i>
                                                <p>OK</p>
                                            </div>
                                        ) : (
                                            <div className="warning_table_cont">
                                                <i className="fa-solid fa-warning"></i>
                                                <p>Dati non presenti</p>
                                            </div>
                                        )}
                                    </td>

                                    <td className={`body_cell ${!tenant.tenant_account ? "missing_data_cell" : ""}`}>
                                        {tenant.tenant_account === "pending" ? (
                                            <div className="present_data_table_cont pending_cell">
                                                <i className="fa-solid fa-circle"></i>
                                                <p>Pending</p>
                                            </div>
                                        ) : tenant.tenant_account === "inactive" ? (
                                            <div className="present_data_table_cont inactive_cell">
                                                <i className="fa-solid fa-pause"></i>
                                                <p>Disconnected account</p>
                                            </div>
                                        ) : (
                                            <div className="warning_table_cont missing_data_cell">
                                                <i className="fa-solid fa-warning"></i>
                                                <p>Dati non presenti</p>
                                            </div>
                                        )}
                                    </td>


                                    <td className={`body_cell ${!tenant.tenant_property_information ? "missing_data_cel" : "no_missing_data_cell"}`}>
                                        {getPaymentStatus(tenant)}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <DeleteTenantModal
                    show={showModalDelete}
                    handleClose={handleClose}
                    userId={userId}
                    tenantId={selectedTenantKey}
                    tenantName={selectedTenantName}
                    cf_vat={CF_VAT}
                    fetchTenantData={fetchTenantData}
                />

                <DisconnectTenantModal
                    show={showModalDisconnect}
                    handleClose={handleCloseDisconnect}
                    userId={userId}
                    tenantId={selectedTenantKey}
                    tenantName={selectedTenantName}
                    cf_vat={CF_VAT}
                    fetchTenantData={fetchTenantData}
                />

            </div>
        );
    } else {

    }

}

export default TenantsTable