import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useLanguage } from '../../../Translations/LenguageContext';

const PersonalizeTableModal = ({ show, handleClose, onChangesAdded }) => {
    const {t} = useLanguage()
    // State to track which section is selected
    const [selectedSection, setSelectedSection] = useState(null);

    // Handle section click
    const handleSectionClick = (section) => {
        setSelectedSection(section); // Set the clicked section as selected
    };

    // Data for Personal Information
    const tenantInformation = {
        tenantType: t.complete_account.tenantType,
        name: t.register_form.name,
        surname: t.register_form.surname,
        birthday: t.complete_account.birthday,
        sex: t.complete_account.sex,
        country: t.complete_account.country,
        city: t.complete_account.city,
        province: t.complete_account.province,
        street: t.complete_account.street,
        n: t.complete_account.n,
        zip: t.complete_account.zip,
        business_name: t.complete_account.business_name,
        vat: t.complete_account.vat,
        phone: t.register_form.phone,
        email: "Email",
        cf: t.complete_account.cf,
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName='modal_table_personalize'>
            <div className='body_modal_table_personalize'>
                <div className='sidebar_modal'>
                    <p className='sidebar_modal_title'>Personalize table view</p>

                    <p className='sidebar_modal_link' onClick={() => handleSectionClick('tenant_information')}>
                        Tenant Information
                    </p>
                    <p className='sidebar_modal_link' onClick={() => handleSectionClick('property_information')}>
                        Property Information
                    </p>
                    <p className='sidebar_modal_link' onClick={() => handleSectionClick('docs_requirements')}>
                        Docs and Requirements
                    </p>
                </div>

                <div className='body_modal'>
                    {/* Content rendering based on section selection */}
                    {selectedSection === 'tenant_information' && (
                        <div className='personal_info_form'>
                            {Object.entries(tenantInformation).map(([key, label]) => (
                                <div key={key} className='form-group'>
                                    <label>{label}</label>
                                    <input type="radio" name="personal_info" value={key} />
                                </div>
                            ))}
                        </div>
                    )}

                    {selectedSection === 'property_information' && (
                        <div>
                            {/* Render property information form */}
                            <p>Property Information Form</p>
                        </div>
                    )}
                    {selectedSection === 'docs_requirements' && (
                        <div>
                            {/* Render docs and requirements form */}
                            <p>Docs and Requirements Form</p>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default PersonalizeTableModal;
