import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from "../../../components/Spinner";
import { useLanguage } from '../../../Translations/LenguageContext';
import { HiSparkles } from "react-icons/hi";
import { HiMiniPaintBrush } from "react-icons/hi2";

const ScreenTypesModal = ({ show, handleClose, onSelectionsChange  }) => {
    const [activeTab, setActiveTab] = useState('input');
    const [selectedChoices, setSelectedChoices] = useState([]);

    const handleSidebarClick = (tab) => {
        setActiveTab(tab);
    };

    const handleChoiceClick = (choice) => {
        setSelectedChoices((prevChoices) => {
            if (prevChoices.includes(choice)) {
                const updatedChoices = prevChoices.filter(item => item !== choice);
                onSelectionsChange(updatedChoices); 
                return updatedChoices;
            } else {
                // Add new choice
                const updatedChoices = [...prevChoices, choice];
                onSelectionsChange(updatedChoices); 
                return updatedChoices;
            }
        });
    };

    // Helper function to check if a choice is selected
    const isSelected = (choice) => selectedChoices.includes(choice);

    return (
        <Modal show={show} onHide={handleClose} className="modal_screen_types">
            <div className='screen_modal_cont'>
                <div className='screen_modal_header'>
                    <p><HiMiniPaintBrush /> Personalizza il tuo screen</p>
                </div>
                <div className='screen_modal_body'>
                    <div className='sidebar_screen_modal'>
                        <div className='sidebar_screen_link' onClick={() => handleSidebarClick('input')}>
                            <i className='fa-solid fa-keyboard'></i>
                            <p>Input type</p>
                        </div>
                        <div className='sidebar_screen_link' onClick={() => handleSidebarClick('form')}>
                            <i className='fa-solid fa-rectangle-list'></i>
                            <p>Form Type</p>
                        </div>
                    </div>

                    <div className='body_screen_modal'>
                        {activeTab === 'input' && (
                            <div className='screen_types_container'>
                                <div className='screen_types_cont'>
                                    <p className='screen_types_cont_title'>Text</p>
                                    <div className={`screen_type_choise ${isSelected('Testo') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Testo')}>
                                        <i className='fa-solid fa-font'></i>
                                        <p className='personal_info_p'>Testo</p>
                                    </div>
                                    <div className={`screen_type_choise ${isSelected('Password') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Password')}>
                                        <i className='fa-solid fa-lock'></i>
                                        <p className='personal_info_p'>Password</p>
                                    </div>
                                    <div className={`screen_type_choise ${isSelected('Email') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Email')}>
                                        <i className='fa-solid fa-envelope'></i>
                                        <p className='personal_info_p'>Email</p>
                                    </div>
                                    <div className={`screen_type_choise ${isSelected('url') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('url')}>
                                        <i className='fa-solid fa-link'></i>
                                        <p className='personal_info_p'>URL</p>
                                    </div>
                                    <div className={`screen_type_choise ${isSelected('Phone') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Phone')}>
                                        <i className='fa-solid fa-phone'></i>
                                        <p className='personal_info_p'>Telefono</p>
                                    </div>

                                    <p className='screen_types_cont_title'>Numeric</p>
                                    <div className={`screen_type_choise ${isSelected('Number') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Number')}>
                                        <i className='fa-solid fa-hashtag'></i>
                                        <p className='personal_info_p'>Numero</p>
                                    </div>
                                    <div className={`screen_type_choise ${isSelected('Range') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Range')}>
                                        <i className='fa-solid fa-list-ol'></i>
                                        <p className='personal_info_p'>Range</p>
                                    </div>
                                </div>
                                <div className='screen_types_cont'>
                                    <p className='screen_types_cont_title'>Date and Time</p>
                                    <div className={`screen_type_choise ${isSelected('Date') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Date')}>
                                        <i className='fa-solid fa-calendar-days'></i>
                                        <p className='personal_info_p'>Data</p>
                                    </div>
                                    <div className={`screen_type_choise ${isSelected('Month') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Month')}>
                                        <i className='fa-solid fa-calendar-check'></i>
                                        <p className='personal_info_p'>Month</p>
                                    </div>
                                    <div className={`screen_type_choise ${isSelected('Week') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Week')}>
                                        <i className='fa-solid fa-calendar-week'></i>
                                        <p className='personal_info_p'>Week</p>
                                    </div>
                                    <div className={`screen_type_choise ${isSelected('Time') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Time')}>
                                        <i className='fa-solid fa-clock'></i>
                                        <p className='personal_info_p'>Time</p>
                                    </div>


                                    <p className='screen_types_cont_title'>File</p>
                                    <div className={`screen_type_choise ${isSelected('File') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('File')}>
                                        <i className='fa-solid fa-folder'></i>
                                        <p className='personal_info_p'>File (Img, XLSX, PDF etc...)</p>
                                    </div>
                                    <p className='screen_types_cont_title'>Boolean</p>
                                    <div className={`screen_type_choise ${isSelected('Checkbox') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Checkbox')}>
                                        <i className='fa-solid fa-square-check'></i>
                                        <p className='personal_info_p'>Checkbox</p>
                                    </div>
                                </div>
                                <div className='screen_types_cont'>
                                    <p className='screen_types_cont_title'>Multiple Select</p>
                                    <div className={`screen_type_choise ${isSelected('Multiple') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Multiple')}>
                                        <i className='fa-solid fa-caret-down'></i>
                                        <p className='personal_info_p'>Multiple choise</p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === 'form' && (
                            <div className='screen_types_container'>
                                <div className='screen_types_cont'>
                                    <p className='screen_types_cont_title'>Personal information</p>
                                    <div className={`screen_type_choise ${isSelected('Personal-Info') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Personal-Info')}>                                       
                                        <i className='fa-solid fa-user'></i>
                                        <p className='personal_info_p'>Personal information</p>
                                    </div>
                                    <div className={`screen_type_choise ${isSelected('Guarant-Request') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Guarant-Request')}>                                       
                                        <i className='fa-solid fa-certificate'></i>
                                        <p className='personal_info_p'>Guarant request</p>
                                    </div>
                                    <p className='screen_types_cont_title'>Income verification</p>
                                    <div className={`screen_type_choise ${isSelected('Income-Verification') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Income-Verification')}>                                     
                                        <i className='fa-solid fa-euro'></i>
                                        <p className='personal_info_p'>Income Verification</p>
                                    </div>
                                    <div className={`screen_type_choise ${isSelected('Economic-State') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Economic-State')}>                                        
                                        <i className='fa-solid fa-coins'></i>
                                        <p className='personal_info_p'>Economic state</p>
                                    </div>
                                </div>
                                <div className='screen_types_cont'>
                                    <p className='screen_types_cont_title'>Rental</p>
                                    <div className={`screen_type_choise ${isSelected('FRHC') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('FRHC')}>                           
                                        <i className='fa-solid fa-house-user'></i>
                                        <p className='personal_info_p'>Full Rental History Check</p>
                                    </div>
                                    <div className={`screen_type_choise ${isSelected('References') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('References')}>                            
                                        <i className='fa-solid fa-asterisk'></i>
                                        <p className='personal_info_p'>References</p>
                                    </div>
                                    <div className={`screen_type_choise ${isSelected('Behavioral-Info') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Behavioral-Info')}>                                       
                                        <i className='fa-solid fa-clock-rotate-left'></i>
                                        <p className='personal_info_p'>Behavioral History</p>
                                    </div>
                                </div>
                                <div className='screen_types_cont'>
                                    <p className='screen_types_cont_title'>Work information</p>
                                    <div className={`screen_type_choise ${isSelected('Employment-Info') ? 'selected_screentype_choise' : ''}`} onClick={() => handleChoiceClick('Employment-Info')}>
                                        <i className='fa-solid fa-briefcase'></i>
                                        <p className='personal_info_p'>Employment information</p>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ScreenTypesModal;
