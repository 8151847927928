import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from "../../../components/Spinner";
import { useLanguage } from '../../../Translations/LenguageContext';

const DisconnectTenantModal = ({ show, handleClose, userId, tenantId, tenantName, fetchTenantData, cf_vat }) => {
    const { t } = useLanguage();
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState(""); 

    const DisconnectSelectedTenant = async () => {
        setLoading(true);
        setErr({ message: "", display: false });
        try {
            const response = await fetch(
                "https://boiling-mountain-85041-33258735eb3b.herokuapp.com/disconnect_tenant",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id: userId, tenant_id: tenantId }),
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            if (data.message === "OK") {
                setInputValue(""); 
                handleClose();
                fetchTenantData();
            } else {
                throw new Error("No tenants found");
            }
        } catch (error) {
            setErr({ message: error.message, display: true });
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleModalClose = () => {
        setInputValue("");
        setErr({ message: "", display: false });
        setLoading(false)
        handleClose(); 
    };

    return (
        <Modal show={show} onHide={handleModalClose} className="modal_contacts_delete">
            <div className='header_modal'>
                <div className='modal_title_cont_prop_disconnect'>
                    <p>Disconnect this tenant?</p>
                    {/* <p>{t.contacts.confirmation_delete}</p> */}
                </div>
                <button className='close_x_modale' onClick={handleModalClose}>
                    <i className='fa-solid fa-x'></i>
                </button>
            </div>
            <div className='body_modal_delete'>
                <p className='body_delete_modal'>{tenantName}</p>
                <p className='modal_delete_mex'>This action be undone at anytime. This will disconnect the tenant {cf_vat} and you wont recieve furtherer payments.</p>
                <p className='modal_delete_mex'>Type <strong>"{tenantId}"</strong> to disconnect the tenant.</p>
                
                <input
                    className='delete_confirmation_input'
                    value={inputValue}
                    onChange={handleInputChange}
                />

                {err.display && <p className="error_message">{err.message}</p>}
            </div>

            <div className='footer_modal'>
                <div className='footer_btn_cont'>
                    <button 
                        className='save_modal_btn disconnect_btn' 
                        onClick={DisconnectSelectedTenant} 
                        disabled={loading || inputValue !== tenantId}
                    >
                        Disconnect
                    </button>
                    <button 
                        className='close_modal_btn' 
                        onClick={handleModalClose} 
                        disabled={loading}
                    >
                        {t.properties.close_btn}
                    </button>
                </div>
                {loading && <Spinner loading={loading} err={err}></Spinner>}
            </div>
        </Modal>
    );
};

export default DisconnectTenantModal;
