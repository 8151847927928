import React, { useEffect, useState } from 'react';

const GoogleMaps = ({ country, city, street, number, postal_code }) => {
    const [address, setAddress] = useState('');
    const apiKey = 'AIzaSyDdsVkXvx589y4-AtdUj2HN9vKUl5qCWgU'; // Replace with your actual API key

    // Construct full address from props
    useEffect(() => {
        const fullAddress = `${street}, ${number}, ${postal_code}, ${city}`;
        setAddress(fullAddress);
        console.log(fullAddress);
    }, [street, number, postal_code, city]);

    return (
        <div className='map_cont'>
            {address ? (
                <iframe
                    title="Google Maps"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    style={{ border: 0 }}
                    // Use the address directly to set the pin
                    src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${encodeURIComponent(address)}&zoom=14&maptype=roadmap`}
                    allowFullScreen
                ></iframe>
            ) : (
                <p>Loading map...</p>
            )}
        </div>
    );
};

export default GoogleMaps;
