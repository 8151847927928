import React, { useEffect, useState } from 'react';
import { useLanguage } from "../../../Translations/LenguageContext";
import AddPropertieModal from "./AddPropertieModal";
import PopupMex from '../SavePopup';
import PropertiesList from './ProperiesList';
import { useNavigate } from 'react-router-dom';
import SpinningGlobe from '../Home/SpinningGlobe';

function Properties({ refreshSidebar }) {
    const { t } = useLanguage();
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false);
    const [properties, setProperties] = useState({});
    const [popupActive, setPopupActive] = useState(false);
    const [popupType, setPopupType] = useState("success");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const userId = sessionStorage.getItem("user_id");

    useEffect(() => {
        refreshSidebar()
    }, [refreshSidebar])

    const navigateNewProperty = () => {
        navigate("new-property")
    }

    const fetchProperties = async () => {
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_properties', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProperties(data.properties || {});
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProperties();
    }, []);

    const handlePropertyAdded = () => {
        fetchProperties();
        setPopupType("success");
        setPopupActive(true);
    };

    const handlePopupClose = () => {
        setPopupActive(false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const filteredProperties = Object.entries(properties).filter(([key, property]) => {
        const searchLowerCase = searchQuery.toLowerCase();
        const getValue = (value) => (value ? value.toLowerCase() : '');

        const rentAndExpensesTotal = (
            (Number(property.rent_price_property) || 0) +
            (Number(property.expenses_property) || 0)
        ).toFixed(2);

        // Search filter
        const matchesSearchQuery = (
            getValue(property.marketType).includes(searchLowerCase) ||
            getValue(property.adults).includes(searchLowerCase) ||
            getValue(property.children).includes(searchLowerCase) ||
            getValue(property.isImmediate).includes(searchLowerCase) ||
            getValue(property.startDate).includes(searchLowerCase) ||
            getValue(property.rentalAmount).includes(searchLowerCase) ||
            getValue(property.condominiumFees).includes(searchLowerCase) ||
            getValue(property.securityDeposit).includes(searchLowerCase) ||
            getValue(property.saleAmount).includes(searchLowerCase) ||
            getValue(property.livingSpace).includes(searchLowerCase) ||
            getValue(property.bedrooms).includes(searchLowerCase) ||
            getValue(property.kitchen).includes(searchLowerCase) ||
            getValue(property.bathrooms).includes(searchLowerCase) ||
            getValue(property.selectedOptions).includes(searchLowerCase) ||
            getValue(property.petPermission).includes(searchLowerCase) ||
            getValue(property.smokePermission).includes(searchLowerCase) ||
            getValue(property.propertyName).includes(searchLowerCase) ||
            getValue(property.description).includes(searchLowerCase) ||
            getValue(property.selectedUnit).includes(searchLowerCase) ||
            getValue(property.country).includes(searchLowerCase) ||
            getValue(property.city).includes(searchLowerCase) ||
            getValue(property.postalCode).includes(searchLowerCase) ||
            getValue(property.street).includes(searchLowerCase) ||
            getValue(property.streetNumber).includes(searchLowerCase) ||
            getValue(property.floor).includes(searchLowerCase) ||
            getValue(property.size).includes(searchLowerCase) ||
            getValue(property.exposure).includes(searchLowerCase) ||
            getValue(property.energyClass).includes(searchLowerCase) ||
            getValue(property.heating).includes(searchLowerCase) ||
            getValue(property.windows).includes(searchLowerCase) ||
            getValue(property.condition).includes(searchLowerCase) ||
            getValue(property.yearBuilt).includes(searchLowerCase)
        );

        return matchesSearchQuery;
    });

    const handlePropertyDeleted = (deletedPropertyKey) => {
        setProperties(prevProperties => {
            const updatedProperties = Object.entries(prevProperties).filter(([key]) => key !== deletedPropertyKey);
            return Object.fromEntries(updatedProperties);
        });
    };

    if (loading) return (
        <div className='loading_mex_cont'>
            <p className='loading_mex'>{t.loading}</p>
            <i className='fa-solid fa-box-open'></i>
        </div>
    );

    if (error) return (
        <div className='loading_mex_cont_err'>
            <p>{t.error}</p>
            <i className='fa-solid fa-box-open'></i>
        </div>
    );

    return (
        <div className="main_view_section">
            <p className="title_main_view">{t.sidebar.properties}</p>
            {Object.keys(properties).length > 0 ? (
                <div className='property_bar_table_cont'>
                    <div className='property_func_bar'>
                        <button className='add_property_btn' onClick={navigateNewProperty}>
                            <i className='fa-solid fa-plus'></i>{t.properties.add_property}
                        </button>
                        <div className='filter_input_cont'>
                            <i className='fa-solid fa-magnifying-glass'></i>
                            <input
                                className='search_bar_input'
                                placeholder={`${t.properties.search_property}...`}
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <div className='property_content'>
                        <PropertiesList
                            properties={filteredProperties}
                            onPropertyDeleted={handlePropertyDeleted}
                            onPropertieAddedOrUpdated={handlePropertyAdded}
                        />
                    </div>
                </div>
            ) : (
                <div className='property_new_add_cont' onClick={navigateNewProperty}>
                    <div className='mini_img_cont_add'>
                    </div>
                    <i className='fa-solid fa-plus'></i>
                  

                </div>
            )}

            <AddPropertieModal
                show={showModal}
                handleClose={handleClose}
                onPropertieAddedOrUpdated={handlePropertyAdded}
                propertyData={false}
            />
            <PopupMex active={popupActive} type={popupType} message={""} onClose={handlePopupClose} />
        </div>
    );
}

export default Properties;
