// App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { LanguageProvider } from './Translations/LenguageContext';
import './App.css';
import './AppMobile.css';


//Website
import ScrollToTop from './Website/ScrollToTop';
import Homepage from './Website/Homepage';
import AboutUs from './Website/AboutUs';
import Pricing from './Website/Pricing';
import OpenPositions from './Website/OpenPositions';
import Blog from './Website/Blog';
import TenantValidation from './Website/TenantValidation';
import AiAnalyt from './Website/AiAnalytics';
import PaymentsTransactions from './Website/PaymentsTransactions';
import Logo from './Website/Logo';
import Privacy_Policy from './Terms&Policies/PrivacyPolicy';
import Terms_And_Conditions from './Terms&Policies/TermsConditions';
import Cookie_Policy from './Terms&Policies/CookiesPolicy';
import Legal_Notes from './Terms&Policies/LegalNotes';
import WaitingListForm from './Website/WaitingListForm';


import Login from './App/Login';
import Register from './App/Register';
import TLL from './App/Tenant_landlord';
import ControlPanel from './App/ControlPanel';
import WelcomeMessage from './App/WelcomeMessage';
import MainStepsCont from './App/LLSections/CompleteRegistration/MainStepsContainer';
import RegistrationComplete from './App/LLSections/CompleteRegistration/RegistrationComplete';
import PaymentsCompletedStripe from './App/LLSections/Payments/PaymentsCompleteStripe';
import SigningPageExternal from './App/LLSections/Tenants/Contracts/SigningPageExternal/SigningPageExternal';
import WaitlistConfirm from './Website/WailistConfirm';
import CheckEmail from './Website/CheckEmail';
import InvestorsPanel from './Investors/InvestorsPanel';
import InvestorsLogin from './Investors/InvestorsLogin';

const App = () => {
  return (
    <LanguageProvider>
      <Router>
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/open_positions" element={<OpenPositions />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/tenant-validation" element={<TenantValidation />} />
          <Route path="/ai-analytics" element={<AiAnalyt />} />
          <Route path="/payments-transactions" element={<PaymentsTransactions />} />
          <Route path="/logo" element={<Logo />} />
          <Route path="/privacy-policy" element={<Privacy_Policy />} />
          <Route path="/terms-and-conditions" element={<Terms_And_Conditions />} />
          <Route path="/cookie-policy" element={<Cookie_Policy />} />
          <Route path="/legal-notes" element={<Legal_Notes />} />
          <Route path="/waiting-list" element={<WaitingListForm />} />
          <Route path="/pre-registration-confirmed/:waitlist_email" element={<WaitlistConfirm />} />
          <Route path="/check-email" element={<CheckEmail />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/tll" element={<TLL />} />
          <Route path="/welcome" element={<WelcomeMessage />} />
          <Route path="/control_panel/*" element={<ControlPanel />} />
          <Route path="/complete_reg" element={<MainStepsCont />} />
          <Route path="/competed_registration" element={<RegistrationComplete />} />
          <Route path="/competed_stipe_setup" element={<PaymentsCompletedStripe />} />
          <Route path="/payper-sign/*" element={<SigningPageExternal />} />

          {/* Investors */}
          <Route path="/investor-login" element={<InvestorsLogin />} />
          <Route path="/investors" element={<InvestorsPanel />} />


        </Routes>
      </Router>
    </LanguageProvider>
  );
};

export default App;
