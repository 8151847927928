import React, { useState } from "react";

const CalendarProp = ({ startDate, setStartDate, error }) => {
    const [currentDate, setCurrentDate] = useState(new Date());

    const month = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();

    const daysInMonth = new Date(year, currentDate.getMonth() + 1, 0).getDate();

    const prevMonth = () => {
        setCurrentDate(new Date(year, currentDate.getMonth() - 1, 1));
    };

    const nextMonth = () => {
        setCurrentDate(new Date(year, currentDate.getMonth() + 1, 1));
    };

    const handleDayClick = (day) => {
        const selectedDate = new Date(year, currentDate.getMonth(), day + 1);
        setStartDate(selectedDate);
    };



    const generateDays = () => {
        const days = [];
        for (let i = 1; i <= daysInMonth; i++) {
            days.push(i);
        }
        return days;
    };

    const isHighlighted = (day) => {
        const currentDay = new Date(year, currentDate.getMonth(), day + 1);
        return startDate && currentDay >= startDate;
    };

    return (
        <div className={`calendar ${error ? "err" : ""}`}>
            <div className="dates_cont_prop">
                <input
                    className={`available_dates_prop ${error ? "err" : ""}`}
                    type="date"
                    value={startDate === "Invalid Date" ? startDate.toISOString().substring(0, 10) : ""}
                    onChange={(e) => setStartDate(new Date(e.target.value))}
                    disabled
                />
            </div>
            <div className="calendar_header_prop">
                <i className="fa-solid fa-chevron-left" onClick={prevMonth} />
                <span>{month} {year}</span>
                <i className="fa-solid fa-chevron-right" onClick={nextMonth} />
            </div>
            <div className="calendar-days">
                {generateDays().map((day, index) => (
                    <div
                        key={index}
                        className={`calendar-day ${isHighlighted(day) ? 'highlighted' : ''}`}
                        onClick={() => handleDayClick(day)}
                    >
                        {day}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CalendarProp;
