import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const MyRentals = () => {
    const navigate = useNavigate();
    const [dataAvailable, setDataAvailable] = useState(false);
    const [rentals, setRentals] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userId = sessionStorage.getItem("user_id");

        const fetchRentals = async () => {
            if (userId) {
                try {
                    const response = await fetch("http://127.0.0.1:5000/get_rented_properties", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ user_id: userId }),
                    });

                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }

                    const result = await response.json();

                    const rentalsArray = Object.keys(result).map((key) => {
                        const rental = result[key];
                        const landlordProperties = rental.landlord_matching_properties
                            ? Object.values(rental.landlord_matching_properties)[0]
                            : {};

                        const tenantProperties = rental.tenant_rented_properties
                            ? Object.values(rental.tenant_rented_properties)[0]
                            : {};

                        const rentalId = Object.keys(rental.tenant_rented_properties || {})[0];

                        return {
                            ...rental,
                            landlordProperties,
                            tenantProperties,
                            rentalId,
                        };
                    });

                    if (rentalsArray.length > 0) {
                        setRentals(rentalsArray); 
                        setDataAvailable(true);
                    } else {
                        setDataAvailable(false);
                    }
                } catch (error) {
                    console.error("Error fetching rentals:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                console.error("User ID not found in session storage");
                setLoading(false);
            }
        };

        fetchRentals();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!dataAvailable) {
        return <p>No rentals available</p>;
    }

    return (
        <div className="main_view_section">
            <p className="title_main_view">My Rentals</p>
            <p className="subtitle_main_view">You can see all the rented properties and manage them</p>

            <div className="rentals_cont">
                {rentals.map((rental) => {
                    const rentalId = rental.rentalId;

                    return (
                        <div
                            key={rentalId}
                            className="rental_cards"
                            onClick={() => navigate(`${rentalId}`)}
                            style={{ cursor: "pointer" }}
                        >
                            <div className="static_data_cont_rentals"></div>
                            <div className="dynamic_data_cont_rentals">
                                <div className="street_rented">
                                    <p>{rental.landlordProperties?.street_property || "Address not available"}</p>
                                </div>
                                <div className="checking_data_cont">
                                    <div className="checking_data">
                                        <label>Check-in</label>
                                        {/* <p>{rental.tenantProperties?.tenant_property_information.personalizedDetail.selectedStartDate || "N/A"}</p> */}
                                    </div>
                                    <div className="checking_data">
                                        <label>Check-out</label>
                                        {/* <p>{rental.tenantProperties?.tenant_property_information.personalizedDetail.selectedEndDate || "N/A"}</p> */}
                                    </div>
                                </div>
                                <div className="checking_data_cont">
                                    <div
                                        className={`checking_data ${rental.tenantProperties?.status === "pending"
                                                ? "pending_status"
                                                : rental.tenantProperties?.status === "inactive"
                                                    ? "inactive_status"
                                                    : rental.tenantProperties?.status === "active"
                                                        ? "ok_status"
                                                        : ""
                                            }`}
                                    >
                                        <label>Status</label>
                                        <p>{rental.tenantProperties?.status || "NULL"}</p>
                                    </div>
                                </div>
                            </div>
                            <i className="fa-solid fa-chevron-right"></i>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default MyRentals;
