import React from "react";
import Navbar from "../Website/Navbar";
import Logo from "../Website/Logo";

const InvestorsPanel = () => {
    return (
        <div className="investors_cont">
            <Logo />
        </div>
    )
}

export default InvestorsPanel