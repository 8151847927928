import React, { useState, useEffect, useRef } from "react";
import { useLanguage } from "../../../../Translations/LenguageContext";
import stripeNation from "../../../../Geo/SupportedStripeCountries.json";
import PopupMex from "../../SavePopup";
import Spinner from "../../../../components/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import TenantPropertieInformation from "../Property-Info/TenantPropertieInformation";
import TenantPersonalInformation from "../Personal-Info/TenantPersonalInformation";
import TenantDocument from "../Docs-info/TenantDocumentsUpload";
import TenantAccount from "../Tenant-Account/TenantAccount";

function UpdateTenant({ userData }) {
    const { t } = useLanguage();
    const [user_id] = useState(sessionStorage.getItem("user_id"));
    const navigate = useNavigate();
    const [tenantId, setTenantId] = useState("");
    const [activeComponent, setActiveComponent] = useState("personalInfo");
    const { tenant_id } = useParams();

    const BackToPreviousPage = () => {
        navigate("/control_panel/tenants");
    };

    const tenant = (id) => {
        setTenantId(id);
    };

    const toggleComponent = (componentName) => {
        setActiveComponent(activeComponent === componentName ? null : componentName);
    };


    const fetchTenantData = async () => {
        try {
            const response = await fetch(
                "https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_tenant_data",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id: user_id, tenant_id: tenant_id }),
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            if (data.tenants) {

            } else {
                throw new Error("No tenants found");
            }
        } catch (error) {

        } finally {
        }
    };

    useEffect(() => {
        fetchTenantData();
    }, [tenant_id]);

    return (
        <div className="main_view_section">
            <p className="title_main_view">
                <i
                    onClick={BackToPreviousPage}
                    className="fa-solid fa-chevron-left back-btn_chevron"
                ></i>
                {t.tenants.update_tenant_title}
            </p>
            <div className="new_tenant_form">
                <TenantPersonalInformation
                    tenant={tenant}
                    new_update={"update"}
                    tenantId={tenant_id}
                    toggleComponent={toggleComponent}
                    isActive={activeComponent === "personalInfo"}
                />
                <TenantPropertieInformation
                    tenant={tenant_id}
                    toggleComponent={toggleComponent}
                    isActive={activeComponent === "propertyInfo"}
                />
                <TenantDocument
                    tenant={tenant_id}
                    toggleComponent={toggleComponent}
                    isActive={activeComponent === "documents"}
                    userData={userData}
                />
                <TenantAccount
                    tenant={tenant_id}
                    toggleComponent={toggleComponent}
                    isActive={activeComponent === "tenantAccount"}
                    userData={userData}
                />
            </div>
        </div>
    );
}

export default UpdateTenant;
