import { useLanguage } from "../Translations/LenguageContext";


const EnergyClassOptions = () => {
    const {t} = useLanguage()
    let energyClassOptions
    return energyClassOptions = [
        { value: "pending_certification", label: t.properties.pending_certification },
        { value: "exempt_property", label: t.properties.exempt_property },
        { value: "not_classifiable", label: t.properties.not_classifiable },
        { value: "A4", label: "A4" },
        { value: "A3", label: "A3" },
        { value: "A1", label: "A1" },
        { value: "A+", label: "A+" },
        { value: "A", label: "A" },
        { value: "B", label: "B" },
        { value: "C", label: "C" },
        { value: "D", label: "D" },
        { value: "E", label: "E" },
        { value: "F", label: "F" },
        { value: "G", label: "G" },
    ];
} 

export default EnergyClassOptions