import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../../Translations/LenguageContext";
import { FaHandsHelping } from "react-icons/fa";
import { FaPersonCirclePlus } from "react-icons/fa6";
import Spinner from "../../../../components/Spinner";

const TenantAccount = ({ tenant, new_update, tenantId, toggleComponent, isActive }) => {
    const { t } = useLanguage();

    const [tenantType, setTenantType] = useState("person");
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const [userId] = useState(sessionStorage.getItem("user_id"));
    const [check, setCheck] = useState(false);

    const handleCreateAccount = async () => {
        setLoading(true);

        fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/create_tenant_account_request", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ user_id: userId, tenant_id: tenant })
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if(data.message === "OK"){
                    toggleComponent("tenantAccount");
                    setCheck(true);
                }

            })
            .catch(error => {
                setLoading(false);
                setErr({ message: t.register_form.problem_back, display: true });
            });
    };

    useEffect(() => {
        if (tenant) {
            setLoading(true);
            fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_specific_tenant_data", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ userId: userId, tenant_id: tenant })
            })
                .then(response => response.json())
                .then(data => {
                    setLoading(false);
                    console.log(data)
                    setLoading(false);
                    setErr({ message: "", display: false });
                    console.log(data.tenant?.tenant_account)
                    const tenantData = data.tenant?.tenant_account;
                    if (tenantData === "pending" || tenantData === "active") {
                        toggleComponent("tenantAccount");
                        setCheck(true);
                    }else{
                        setCheck(false);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setErr({ message: t.register_form.problem_back, display: true });
                });
        }
    }, [tenant, userId, t]);

    return (
        <div className={`tenant_label_input_cont ${check ? "label_input_cont_done" : ""}`}>
            <div className={`new_tenant_title_chevron_cont ${isActive ? "new_tenant_title_chevron_cont_open" : ""}`} onClick={() => toggleComponent("tenantAccount")}>
                <div className="new_tenant_title_desc_cont">
                    <p className="new_tenant_title">{t.tenants.tenant_account_title}</p>
                    <p className="description_tenant_title">{t.tenants.tenant_account_desc}</p>
                </div>
                <div className="new_tenant_check_chevron_cont">
                    <i className={`fa-regular ${check ? "fa-circle-check check_tenant" : "fa-circle circle_tenant"}`}></i>
                    <i className={`fa-solid ${isActive ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                </div>
            </div>
            <div className={`toggle_tenant_info_cont ${isActive ? "active_tenant_info" : ""}`}>
                <div className="tenant_account_setup_container">
                    <div className="tenant_setup_cont">
                        <p className="tenant_setup_account_title">
                            <i><FaPersonCirclePlus /></i> Connessione account inquilino
                        </p>
                        <p className="tenant_setup_account_subtitle">
                            L'inquilino riceverà un'email con le istruzioni per l'accesso se non ha un account paypermate.
                            Una volta effettuato l'accesso, potrà accettare e gestire i pagamenti, inserire dati e fornire i documenti da te richiesti.
                        </p>
                        <button className="create_tenant_account_btn"
                            onClick={handleCreateAccount}
                            disabled={loading}>
                            <Spinner loading={loading} err={err} />
                            <span>INVIA LINK</span>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default TenantAccount;
