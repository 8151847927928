import { useLanguage } from "../Translations/LenguageContext";


const ExposureOptions = () => {
    const {t} = useLanguage()
    let exposureOptions
    return exposureOptions = [
        { value: "north", label: t.properties.north },
        { value: "south", label: t.properties.south },
        { value: "est", label: t.properties.est },
        { value: "west", label: t.properties.west },
    ]; 
}

export default ExposureOptions