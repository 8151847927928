import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../../Translations/LenguageContext";
import { IoSparkles } from "react-icons/io5";
import { PiHouseLine, PiBuilding, PiLighthouseFill, PiGarage } from "react-icons/pi";
import { TbBuildingCottage } from "react-icons/tb";
import { RiDoorOpenLine, RiLandscapeLine } from "react-icons/ri";
import { AiFillShop, AiOutlinePicture } from "react-icons/ai";
import CalendarProp from "./MicroComponents/CalendarProp";
import { Dropdown } from "react-bootstrap";
import stripeNations from "../../../Geo/SupportedStripeCountries.json"

import PropertyInput from "./MicroComponents/PropertyInput";
import UnitType from "./MicroComponents/UnitType";
import ExposureOptions from "../../../components/ExposureOptions";
import EnergyClasseOptions from "../../../components/EnergyClasseOptions";
import HeatingOptions from "../../../components/HeatingOptions";
import FixtureOptions from "../../../components/FixturesOptions";
import ConditionsOptions from "../../../components/ConditionsOptions";
import ComfortOptions from "../../../components/ComfortOptions";
import PopupMex from "../SavePopup";
import Spinner from "../../../components/Spinner";
import SavePropChangesModal from "./MicroComponents/SaveChangesModal";

const NewProperty = () => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [loadingPage, setLoadingPage] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingDeleImg, setLoadingDeleImg] = useState(false);
    const [loadingImageIndex, setLoadingImageIndex] = useState(null)
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const [err, setErr] = useState({ message: "", display: false })
    const [popupActive, setPopupActive] = useState(false);
    const [popupType, setPopupType] = useState("success");
    const [popupMex, setPopupMex] = useState("")
    const [showModal, setShowModal] = useState(false)

    const user_id = sessionStorage.getItem("user_id");
    const { property_id } = useParams();

    const [prop_id, setPropId] = useState(property_id)

    const [initialData, setInitialData] = useState({});

    const [marketType, setMarketType] = useState("rent");
    const [adults, setAdults] = useState(0);
    const [children, setChildren] = useState(0);
    const [isImmediate, setIsImmediate] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [rentalAmount, setRentalAmount] = useState('');
    const [condominiumFees, setCondominiumFees] = useState('');
    const [securityDeposit, setSecurityDeposit] = useState('');
    const [saleAmount, setSaleAmount] = useState('');
    const [livingSpace, setLivingSpace] = useState(0);
    const [bedrooms, setBedrooms] = useState(0);
    const [kitchen, setKitchen] = useState(0);
    const [bathrooms, setBathrooms] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [petPermission, setPetPermission] = useState(false);
    const [smokePermission, setSmokePermission] = useState(false);

    const [propertyName, setPropertyName] = useState("");
    const [description, setDescription] = useState("");
    const textEditorRef = useRef(null);
    const [selectedUnit, setSelectedUnit] = useState(null)
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [street, setStreet] = useState("");
    const [streetNumber, setStreetNumber] = useState("");
    const [floor, setFloor] = useState("");
    const [size, setSize] = useState("");
    const [exposure, setExposure] = useState("");
    const [energyClass, setEnergyClass] = useState("");
    const [heating, setHeating] = useState("");
    const [windows, setWindows] = useState("");
    const [condition, setCondition] = useState("");
    const [yearBuilt, setYearBuilt] = useState("");

    const [images, setImages] = useState([]);

    const [aiResponse, setAiResponse] = useState('');
    const [aiInput, setAiInput] = useState('');
    const [aiLoading, setAiLoading] = useState(false)


    const [errors, setErrors] = useState({});

    const handlePopupClose = () => {
        setPopupActive(false);
    };

    const fetchProperties = async () => {
        setLoadingPage(true)
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_specific_property', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id, prop_id }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            } else {
                setLoadingPage(false)
                const data = await response.json();
                const property = data.property || {};

                setInitialData({
                    marketType: property.market_type || "rent",
                    adults: property.adults || 0,
                    children: property.children || 0,
                    isImmediate: property.is_immediate ?? true,
                    startDate: property.start_date ? new Date(property.start_date) : null,
                    rentalAmount: property.rental_amount || '',
                    condominiumFees: property.condominium_fees || '',
                    securityDeposit: property.security_deposit || '',
                    saleAmount: property.sale_amount || '',
                    livingSpace: property.living_space || 0,
                    bedrooms: property.bedrooms || 0,
                    kitchen: property.kitchen || 0,
                    bathrooms: property.bathrooms || 0,
                    selectedOptions: property.selected_comforts || [],
                    petPermission: property.pet_permission ?? false,
                    smokePermission: property.smokePermission ?? false,
                    propertyName: property.property_name || "",
                    description: property.description || "",
                    selectedUnit: property.unit_type || null,
                    country: property.country || "",
                    city: property.city || "",
                    postalCode: property.postal_code || "",
                    street: property.street || "",
                    streetNumber: property.street_number || "",
                    floor: property.floor || "",
                    size: property.size || "",
                    exposure: property.exposure || "",
                    energyClass: property.energy_class || "",
                    heating: property.heating || "",
                    windows: property.windows || "",
                    condition: property.condition || "",
                    yearBuilt: property.year_built || "",
                    images: property.images || [],
                });

                setMarketType(property.market_type || "rent");
                setAdults(property.adults || 0);
                setChildren(property.children || 0);
                setIsImmediate(property.is_immediate ?? true);
                setStartDate(property.start_date ? new Date(property.start_date) : null);
                setRentalAmount(property.rental_amount || '');
                setCondominiumFees(property.condominium_fees || '');
                setSecurityDeposit(property.security_deposit || '');
                setSaleAmount(property.sale_amount || '');
                setLivingSpace(property.living_space || 0);
                setBedrooms(property.bedrooms || 0);
                setKitchen(property.kitchen || 0);
                setBathrooms(property.bathrooms || 0);
                setSelectedOptions(property.selected_comforts || []);
                setPetPermission(property.pet_permission ?? false);
                setSmokePermission(property.smokePermission ?? false);
                setPropertyName(property.property_name || "");
                setDescription(property.description || "");
                textEditorRef.current.innerHTML = property.description
                setSelectedUnit(property.unit_type || null);
                setCountry(property.country || "");
                setCity(property.city || "");
                setPostalCode(property.postal_code || "");
                setStreet(property.street || "");
                setStreetNumber(property.street_number || "");
                setFloor(property.floor || "");
                setSize(property.size || "");
                setExposure(property.exposure || "");
                setEnergyClass(property.energy_class || "");
                setHeating(property.heating || "");
                setWindows(property.windows || "");
                setCondition(property.condition || "");
                setYearBuilt(property.year_built || "");
                setImages(property.images || []);
                setErr({ message: "", display: false })
            }

        } catch (error) {
            setLoadingPage(false)
            setErr({ message: "Something went wrong", display: true })
            console.error('Error fetching property data:', error);
        }
    };

    useEffect(() => {
        if (prop_id) {
            setTimeout(() => {
                fetchProperties();
            }, 1000);
        } else {
            setTimeout(() => {
                setLoadingPage(false)
            }, 1000);
        }
    }, [prop_id]);


    const validateFields = () => {
        const newErrors = {};
        if (!propertyName) newErrors.propertyName = "Il nome della proprietà è obbligatorio";
        if (!country) newErrors.country = "La nazione è obbligatoria";
        if (!city) newErrors.city = "La città è obbligatoria";
        if (!postalCode) newErrors.postalCode = "Il CAP è obbligatorio";
        if (!street) newErrors.street = "La via è obbligatoria";
        if (!streetNumber) newErrors.streetNumber = "Il numero è obbligatorio";
        if (!energyClass) newErrors.energyClass = "La classe energetica";
        if (!heating) newErrors.heating = "Il riscaldamento è obbligatorio";
        if (!windows) newErrors.windows = "Gli infissi sono obbligatori";
        if (!condition) newErrors.condition = "La condizione è obbligatoria";
        if (!exposure) newErrors.exposure = "L'esposizione è obbligatoria";
        if (description.trim(" ").length <= 150) newErrors.description = "Descrizione obbligatoria";
        if (!floor || isNaN(floor)) newErrors.floor = "Il piano deve essere un numero valido";
        if (!size || isNaN(size)) newErrors.size = "I metri quadri devono essere un numero valido";
        if (!yearBuilt || isNaN(yearBuilt)) newErrors.yearBuilt = "L'anno di costruzione deve essere un numero valido";
        if (!selectedUnit) {
            newErrors.unitType = "È obbligatorio selezionare un tipo di unità";
        }
        if (livingSpace < 1 && bedrooms < 1 && kitchen < 1 && bathrooms < 1) {
            newErrors.roomCount = "Deve essere presente almeno un'unità abitativa";
        }
        if (marketType === "rent") {
            if (!rentalAmount) newErrors.rentalAmount = "Il canone è obbligatorio";
            if (!condominiumFees) newErrors.condominiumFees = "Le spese condominiali sono obbligatorie";
            if (!securityDeposit) newErrors.securityDeposit = "Il deposito cauzionale è obbligatorio";
        } else {
            if (!saleAmount) newErrors.saleAmount = "L'importo di vendita è obbligatorio";
        }

        if (!isImmediate) {
            if (!startDate) newErrors.startDate = "Devi selezionare una data di programmazione"
        }

        // if (images.length < 5) {
        //     newErrors.enough_images = "Deve essere presente almeno un'unità abitativa";
        // }

        if (children >= 1 && adults == 0) {
            newErrors.people = "Non possono esserci solo bambini";
        } else if (children == 0 && adults == 0) {
            newErrors.people = "Devi selezionare alemeno una persona";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const increment = (setter) => setter((prev) => prev + 1);
    const decrement = (setter) => setter((prev) => (prev > 0 ? prev - 1 : 0));

    const unitTypes = [
        { icon: <TbBuildingCottage />, value: "villa", title: t.new_property.unit_types.villa },
        { icon: <PiHouseLine />, value: "house", title: t.new_property.unit_types.house },
        { icon: <PiBuilding />, value: "appartment", title: t.new_property.unit_types.appartment },
        { icon: <RiDoorOpenLine />, value: "room", title: t.new_property.unit_types.room },
        { icon: <AiFillShop />, value: "business", title: t.new_property.unit_types.business },
        { icon: <RiLandscapeLine />, value: "land", title: t.new_property.unit_types.land },
        { icon: <PiLighthouseFill />, value: "other", title: t.new_property.unit_types.other }
    ];

    const toggleOption = (option) => {
        setSelectedOptions((prevSelected) =>
            prevSelected.includes(option)
                ? prevSelected.filter((item) => item !== option)
                : [...prevSelected, option]
        );
    };

    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);
        setImages((prevImages) => [...prevImages, ...files]);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        setImages((prevImages) => [...prevImages, ...files]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const removeImage = async (indexToRemove) => {
        const imageToRemove = images[indexToRemove];
        
        if (!imageToRemove) {
            return;
        }
        
        const imgId = imageToRemove.id;
        
        if (prop_id && imgId != undefined) {
            setLoadingImageIndex(indexToRemove)
            setLoadingDeleImg(true)
            try {
                const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/delete_selected_image', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_id: user_id,
                        prop_id: prop_id,
                        image_id: imgId,
                    }),
                });

                setLoadingDeleImg(false)
                setLoadingImageIndex(null);
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to delete image');
                }

                setImages((prevImages) =>
                    prevImages.filter((_, index) => index !== indexToRemove)
                );

            } catch (error) {
                setLoadingImageIndex(null);
                setLoadingDeleImg(false)
                console.error('Error removing image:', error);
            }
        } else {
            setLoadingDeleImg(false)
            setImages((prevImages) =>
                prevImages.filter((_, index) => index !== indexToRemove)
            );
        }

    };

    const handleDragStart = (index) => {
        setDraggedIndex(index);
    };

    const handleDropImage = (index) => {
        if (draggedIndex !== null && index !== draggedIndex) {
            const newImages = [...images];
            const [movedImage] = newImages.splice(draggedIndex, 1);
            newImages.splice(index, 0, movedImage);
            setImages(newImages);
            setDraggedIndex(index);
        }
    };

    const handleDragEnd = () => {
        setDraggedIndex(null);
    };

    const handleFetchDescription = async () => {
        setAiLoading(true)
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/create_perfect_description_prop', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ request: aiInput })
            });

            setAiLoading(false)
            if (response.ok) {
                const data = await response.json();
                setAiResponse(data.response);
                setAiInput("")
            } else {
                console.error('Failed to fetch description:', response.statusText);
            }
        } catch (error) {
            setAiLoading(false)
            console.error('Error fetching description:', error);
        }
    };

    const handleUseAiResponse = () => {
        if (textEditorRef.current) {
            textEditorRef.current.innerText = aiResponse;
            setDescription(aiResponse);
        }
    };

    const ValidateFields = () => {
        if (!validateFields()) {
            setPopupActive(true)
            setPopupType("error")
            setPopupMex(t.new_property.err_fields)
            return;
        } else {
            setPopupActive(false)
            setPopupMex("")
            SaveData()
        }
    }

    const SaveData = () => {
        setLoadingSave(true);
        const formData = new FormData();
        formData.append("prop_id", prop_id);
        formData.append("user_id", user_id);
        formData.append("market_type", marketType);
        formData.append("adults", adults);
        formData.append("children", children);
        formData.append("is_immediate", isImmediate);
        formData.append("start_date", startDate);
        formData.append("rental_amount", rentalAmount);
        formData.append("condominium_fees", condominiumFees);
        formData.append("security_deposit", securityDeposit);
        formData.append("sale_amount", saleAmount);
        formData.append("living_space", livingSpace);
        formData.append("bedrooms", bedrooms);
        formData.append("kitchen", kitchen);
        formData.append("bathrooms", bathrooms);
        formData.append("selected_comforts", JSON.stringify(selectedOptions));
        formData.append("pet_permission", petPermission);
        formData.append("smoke_permission", smokePermission);
        formData.append("property_name", propertyName);
        formData.append("description", description);
        formData.append("unit_type", selectedUnit);
        formData.append("country", country);
        formData.append("city", city);
        formData.append("postal_code", postalCode);
        formData.append("street", street);
        formData.append("street_number", streetNumber);
        formData.append("floor", floor);
        formData.append("size", size);
        formData.append("exposure", exposure);
        formData.append("energy_class", energyClass);
        formData.append("heating", heating);
        formData.append("windows", windows);
        formData.append("condition", condition);
        formData.append("year_built", yearBuilt);

        images.forEach((image) => {
            formData.append("images", image);
        });

        fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/new_propertie', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                setLoadingSave(false);
                setPopupActive(true);
                if (data.message === "OK") {
                    setPopupType("success");
                    setErr({ message: "", display: false });
                    setPropId(data.prop_id)
                    setInitialData({
                        marketType: marketType,
                        adults: adults,
                        children: children,
                        isImmediate: isImmediate,
                        startDate: startDate,
                        rentalAmount: rentalAmount,
                        condominiumFees: condominiumFees,
                        securityDeposit: securityDeposit,
                        saleAmount: saleAmount,
                        livingSpace: livingSpace,
                        bedrooms: bedrooms,
                        kitchen: kitchen,
                        bathrooms: bathrooms,
                        selectedOptions: selectedOptions,
                        petPermission: petPermission,
                        smokePermission: smokePermission,
                        propertyName: propertyName,
                        description: description,
                        selectedUnit: selectedUnit,
                        country: country,
                        city: city,
                        postalCode: postalCode,
                        street: street,
                        streetNumber: streetNumber,
                        floor: floor,
                        size: size,
                        exposure: exposure,
                        energyClass: energyClass,
                        heating: heating,
                        windows: windows,
                        condition: condition,
                        yearBuilt: yearBuilt,
                        images: images,
                    });
                } else if (data.error) {
                    setErr({ message: "Something went wrong", display: true });
                    setPopupType("error");
                }
            })
            .catch(error => {
                setLoadingSave(false);
                setPopupActive(true);
                setPopupType("error");
                console.error(error);
            });
    };

    const incrementAdults = () => setAdults(adults + 1);
    const decrementAdults = () => setAdults(Math.max(0, adults - 1));
    const incrementChildren = () => setChildren(children + 1);
    const decrementChildren = () => setChildren(Math.max(0, children - 1));

    const handleBackClick = () => {
        const isChanged = checkForChanges();

        if (propertyName === "" && description === "") {
            navigate("/control_panel/properties");
        } else {
            if (!validateFields()) {
                setPopupActive(true)
                setPopupType("error");
                setPopupMex("Controlla che tutti i campi siano compilati")
                return;
            } else {
                setPopupActive(false)
                setPopupMex("")
                if (isChanged) {
                    setShowModal(true);
                } else {
                    navigate("/control_panel/properties");
                }
            }
        }
    };

    const checkForChanges = () => {
        return JSON.stringify(initialData) !== JSON.stringify({
            marketType,
            adults,
            children,
            isImmediate,
            startDate,
            rentalAmount,
            condominiumFees,
            securityDeposit,
            saleAmount,
            livingSpace,
            bedrooms,
            kitchen,
            bathrooms,
            selectedOptions,
            petPermission,
            smokePermission,
            propertyName,
            description,
            selectedUnit,
            country,
            city,
            postalCode,
            street,
            streetNumber,
            floor,
            size,
            exposure,
            energyClass,
            heating,
            windows,
            condition,
            yearBuilt,
            images
        });
    };

    const handleNotSaving = () => {
        navigate("/control_panel/properties");
    };

    if (loadingPage) return (
        <div className='loading_mex_cont'>
            <p className='loading_mex'>{t.loading}</p>
            <i className='fa-solid fa-box-open'></i>
        </div>
    );

    if (err.display) return (
        <div className='loading_mex_cont_err'>
            <p>{t.error}</p>
            <i className='fa-solid fa-box-open'></i>
        </div>
    );

    return (
        <div className="main_view_section">
            <p className="title_main_view">
                <i onClick={handleBackClick} className="fa-solid fa-chevron-left back-btn_chevron"></i>
                {prop_id ? (
                    <span>{t.new_property.update_prop}</span>
                ) : (
                    <span>{t.new_property.new_prop}</span>

                )}
            </p>
            <div className='property_fun_bar_new'>
                <button className='save_property_btn' onClick={ValidateFields}>
                    {loadingSave ? (
                        <Spinner loading={loadingSave} err={{ message: "", display: false }} />
                    ) : (
                        <span>{t.new_property.verify_save_btn}</span>
                    )}
                </button>
            </div>

            <div className="new_property_cont">
                <div className="main_property_cont">
                    <div className="section_property_cont">
                        <PropertyInput
                            label={t.new_property.name}
                            placeholder={t.new_property.placeholders.name}
                            value={propertyName}
                            onChange={(e) => { setPropertyName(e.target.value) }}
                            error={errors.propertyName}
                        />

                    </div>

                    <div className="section_property_cont">
                        <div className={`text_editor_cont ${errors.description ? "err" : ""}`}>
                            <div className="text_editor_header">
                                <div className="header_btn_cont">
                                    <div className="ai_assistant_editor_cont">
                                        <Dropdown>
                                            <div>
                                                <Dropdown.Toggle className="custom-dropdown-toggle" as="div">
                                                    <IoSparkles />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="custom-dropdown-menu-ai-response">
                                                    <div className="ai_response">
                                                        <p className="ai_response_text">
                                                            {aiLoading ? (
                                                                <p className="ai_loading">{t.new_property.ai.analysis}</p>
                                                            ) : (
                                                                <>
                                                                    {aiResponse ? (
                                                                        <>
                                                                            {aiResponse}
                                                                            <p className="use_ai_resp" onClick={handleUseAiResponse}>{t.new_property.ai.use_this_desc}</p>
                                                                        </>
                                                                    ) : (
                                                                        <p className="ai_preview">{t.new_property.ai.desc_prev}</p>
                                                                    )}
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="ai_input_cont">
                                                        <input
                                                            className="ai_input"
                                                            placeholder={t.new_property.ai.placeholder_ai}
                                                            value={aiInput}
                                                            onChange={(e) => setAiInput(e.target.value)}
                                                        />
                                                        <button className="send_ai" onClick={handleFetchDescription}>
                                                            <IoSparkles />
                                                        </button>
                                                    </div>
                                                </Dropdown.Menu>
                                            </div>
                                        </Dropdown>
                                    </div>
                                    <div className="font_cont">
                                        <button className="font_type_btn" onClick={() => document.execCommand('bold')}>
                                            <i className="fa-solid fa-bold"></i>
                                        </button>
                                        <button className="font_type_btn" onClick={() => document.execCommand('italic')}>
                                            <i className="fa-solid fa-italic"></i>
                                        </button>
                                        <button className="font_type_btn" onClick={() => document.execCommand('underline')}>
                                            <i className="fa-solid fa-underline"></i>
                                        </button>
                                        <div className="font_type_btn">
                                            <i className="fa-solid fa-font"></i>
                                            <select onChange={(e) => document.execCommand('fontName', false, e.target.value)} defaultValue="var(--font)">
                                                <option value="" disabled>Font Family</option>
                                                <option value="var(--font)">{t.new_property.font.system}</option>
                                                <option value="Courier New">Courier New</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Times New Roman">Times New Roman</option>
                                                <option value="Verdana">Verdana</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <p className="words_length">{description.trim(" ").length}/150</p>
                                <i className={`fa-regular ${description.trim(" ").length >= 150 ? 'fa-circle-check' : 'fa-circle'}`}></i>
                            </div>
                            <div
                                className="text_editor"
                                contentEditable
                                ref={textEditorRef}
                                onInput={(e) => {
                                    const currentHtml = e.currentTarget.innerHTML;
                                    setDescription(currentHtml);
                                }}
                                error={errors.description}
                            />

                        </div>
                    </div>

                    <div className="unit_types_cont">
                        {unitTypes.map((unit, index) => (
                            <UnitType
                                key={index}
                                icon={unit.icon}
                                title={unit.title}
                                selected={selectedUnit === unit.value}
                                onClick={() => setSelectedUnit(unit.value)}
                                error={errors.unitType}
                            />
                        ))}
                    </div>

                    <div className="section_property_cont">
                        <PropertyInput
                            inputType="select"
                            label={t.new_property.country}
                            placeholder={t.new_property.placeholders.country}
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            options={stripeNations}
                            error={errors.country} />
                    </div>

                    <div className="section_property_cont">
                        <PropertyInput
                            inputType="input"
                            label={t.new_property.city}
                            placeholder={t.new_property.placeholders.city}
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            error={errors.city} />
                    </div>
                    <div className="section_property_cont">
                        <PropertyInput
                            inputType="input"
                            label={t.new_property.ZIP}
                            placeholder={"1234"}
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                            error={errors.postalCode} />
                    </div>
                    <div className="section_property_cont">
                        <PropertyInput
                            inputType="input"
                            label={t.new_property.street}
                            placeholder={t.new_property.placeholders.street}
                            value={street} onChange={(e) => setStreet(e.target.value)}
                            error={errors.street} />
                    </div>
                    <div className="section_property_cont">
                        <PropertyInput
                            inputType="input"
                            label={t.new_property.street_num}
                            placeholder={t.new_property.placeholders.street_num}
                            value={streetNumber}
                            onChange={(e) => setStreetNumber(e.target.value)}
                            error={errors.streetNumber}
                        />
                    </div>
                    <div className="section_property_cont">
                        <PropertyInput
                            inputType="number"
                            label={t.new_property.floor}
                            placeholder="5"
                            value={floor}
                            onChange={(e) => setFloor(e.target.value)}
                            error={errors.floor}
                        />
                    </div>
                    <div className="section_property_cont">
                        <PropertyInput
                            inputType="number"
                            label={t.new_property.mq}
                            placeholder="130"
                            value={size} onChange={(e) => setSize(e.target.value)}
                            error={errors.size}
                        />
                    </div>
                    <div className="section_property_cont">
                        <PropertyInput
                            inputType="select"
                            label={t.new_property.exposure}
                            placeholder={t.new_property.placeholders.north}
                            value={exposure}
                            onChange={(e) => setExposure(e.target.value)}
                            options={ExposureOptions()}
                            error={errors.exposure}
                        />
                    </div>
                    <div className="section_property_cont">
                        <PropertyInput
                            inputType="select"
                            label={t.new_property.energy_class}
                            placeholder="A+"
                            value={energyClass}
                            onChange={(e) => setEnergyClass(e.target.value)}
                            options={EnergyClasseOptions()}
                            error={errors.energyClass}
                        />
                    </div>
                    <div className="section_property_cont">
                        <PropertyInput
                            inputType="select"
                            label={t.new_property.heating}
                            placeholder={t.new_property.placeholders.centralized}
                            value={heating}
                            onChange={(e) => setHeating(e.target.value)}
                            options={HeatingOptions()}
                            error={errors.heating}
                        />
                    </div>
                    <div className="section_property_cont">
                        <PropertyInput
                            inputType="select"
                            label={t.new_property.windows}
                            placeholder={t.new_property.placeholders.glass_pvc}
                            value={windows}
                            onChange={(e) => setWindows(e.target.value)}
                            options={FixtureOptions()}
                            error={errors.windows}
                        />
                    </div>
                    <div className="section_property_cont">
                        <PropertyInput
                            inputType="select"
                            label={t.new_property.conditions}
                            placeholder={t.new_property.placeholders.new_prop}
                            value={condition}
                            onChange={(e) => setCondition(e.target.value)}
                            options={ConditionsOptions()}
                            error={errors.condition}
                        />
                    </div>
                    <div className="section_property_cont">
                        <PropertyInput
                            inputType="number"
                            label={t.new_property.year_built}
                            placeholder="2010"
                            value={yearBuilt}
                            onChange={(e) => setYearBuilt(e.target.value)}
                            error={errors.yearBuilt}
                        />
                    </div>

                    <div className={`imag_prop_cont ${errors.enough_images ? "error" : ""}`}>
                        {/* {errors.enough_images && (
                            <p className="err_image">Devi selezionare almeno 5 immagini</p>
                        )} */}
                        <input
                            id="file_img_prop_input"
                            type="file"
                            multiple
                            onChange={handleFileUpload}
                            style={{ display: "none" }}
                        />
                        {images.length === 0 ? (
                            <div
                                className="imag_prop_cont_inner"
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <p className="imag_prop_cont_inner_mex">
                                {t.new_property.drag_img_text}{" "}
                                    <span className="ia_span" onClick={() => document.getElementById("file_img_prop_input").click()}>
                                        {t.new_property.here}
                                    </span>
                                </p>
                                <i><AiOutlinePicture /></i>
                            </div>
                        ) : (
                            <div className="image_preview_container">
                                {images.map((image, index) => (
                                    <div
                                        key={image.id} // Use unique id for the key
                                        className={`image_preview ${draggedIndex === index ? 'dragging' : ''}`}
                                        draggable
                                        onDragStart={() => handleDragStart(index)}
                                        onDrop={() => handleDropImage(index)}
                                        onDragOver={handleDragOver}
                                        onDragEnd={handleDragEnd}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >
                                        <img src={image.url || URL.createObjectURL(image)} alt={image.filename} />

                                        {loadingImageIndex === index ? (
                                            <div className="delete_icon_spinner">
                                                <Spinner err={{ message: "", display: false }} loading={true} />
                                            </div>
                                        ) : (
                                            <div className="delete_icon" onClick={() => removeImage(index)}>
                                                <i className="fa-solid fa-trash"></i>
                                            </div>
                                        )}

                                        {hoveredIndex === index && draggedIndex !== null && (
                                            <div className="dragging_overlay"></div>
                                        )}
                                    </div>
                                ))}


                                <p className="imag_prop_cont_inner_mex_add" onClick={() => document.getElementById("file_img_prop_input").click()}>
                                    <span className="ia_span" >
                                        <i className="fa-solid fa-plus"></i>
                                    </span>
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="secondary_property_cont">
                    <label className="specific_request_label"><i className="fa-solid fa-tag"></i> {t.new_property.sell_rent}</label>
                    <div className="specific_request_cont btn_specific_req">
                        <div className={`market_type_prop ${marketType === "rent" ? 'market_type_prop_selected' : ''}`} onClick={() => setMarketType("rent")}>
                            <p>{t.new_property.rent}</p>
                        </div>
                        <div className={`market_type_prop ${marketType === "for_sale" ? 'market_type_prop_selected' : ''}`} onClick={() => setMarketType("for_sale")}>
                            <p>{t.new_property.sell}</p>
                        </div>
                    </div>

                    <label className="specific_request_label"><i className="fa-solid fa-people-group"></i> {t.new_property.people}</label>
                    <div className="specific_request_cont room_specific_req">
                        <p className="single_cont_title">{t.new_property.adults}</p>
                        <div className={`single_cont ${errors.people ? "error" : ""}`}>
                            <i className="fa-solid fa-plus" onClick={incrementAdults}></i>
                            <p className="number_unit_room">{adults}</p>
                            <i className="fa-solid fa-minus" onClick={decrementAdults}></i>
                        </div>

                        <p className="single_cont_title">{t.new_property.children}</p>
                        <div className={`single_cont ${errors.people ? "error" : ""}`}>
                            <i className="fa-solid fa-plus" onClick={incrementChildren}></i>
                            <p className="number_unit_room">{children}</p>
                            <i className="fa-solid fa-minus" onClick={decrementChildren}></i>
                        </div>
                    </div>

                    {marketType === "rent" && (
                        <>
                            <label className="specific_request_label"><i className="fa-solid fa-calendar"></i> {t.new_property.available_date}</label>
                            <div className="specific_request_cont cal_specific_req">
                                <div className="dates_cont_prop">
                                    <div className="available_now">
                                        <input
                                            className="radio_prop"
                                            type="radio"
                                            checked={isImmediate}
                                            onChange={() => setIsImmediate(true)}
                                        />
                                        <label>{t.new_property.immediate}</label>
                                    </div>
                                    <div className="available_now">
                                        <input
                                            className="radio_prop"
                                            type="radio"
                                            checked={!isImmediate}
                                            onChange={() => setIsImmediate(false)}
                                        />
                                        <label>{t.new_property.programmed}</label>
                                    </div>
                                </div>

                                {!isImmediate && <CalendarProp startDate={startDate} setStartDate={setStartDate} error={errors.startDate} />}
                            </div>
                        </>
                    )}
                    <label className="specific_request_label"><i className="fa-solid fa-chart-simple"></i> {t.new_property.request_amount}</label>
                    {marketType === "rent" ? (
                        <div className="specific_request_cont room_specific_req">
                            <p className="single_cont_title">{t.new_property.rent_amount}</p>
                            <div className={`single_cont ${errors.rentalAmount ? "error" : ""}`}>
                                <input
                                    className="single_cont_input"
                                    value={rentalAmount}
                                    onChange={(e) => setRentalAmount(e.target.value)}
                                    error={errors.rentalAmount}
                                />
                            </div>
                            <p className="single_cont_title">{t.new_property.condominium_fees}</p>
                            <div className={`single_cont ${errors.condominiumFees ? "error" : ""}`}>
                                <input
                                    className="single_cont_input"
                                    value={condominiumFees}
                                    onChange={(e) => setCondominiumFees(e.target.value)}
                                    error={errors.condominiumFees}
                                />
                            </div>
                            <p className="single_cont_title">{t.new_property.security_deposit}</p>
                            <div className={`single_cont ${errors.securityDeposit ? "error" : ""}`}>
                                <input
                                    className="single_cont_input"
                                    value={securityDeposit}
                                    onChange={(e) => setSecurityDeposit(e.target.value)}
                                    error={errors.securityDeposit}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="specific_request_cont room_specific_req">
                            <p className="single_cont_title">{t.new_property.sell_amount}</p>
                            <div className={`single_cont ${errors.saleAmount ? "error" : ""}`}>
                                <input
                                    className="single_cont_input"
                                    value={saleAmount}
                                    onChange={(e) => setSaleAmount(e.target.value)}
                                    error={errors.saleAmount}
                                />
                            </div>
                        </div>
                    )}
                    <label className="specific_request_label"><i className="fa-solid fa-door-open"></i> {t.new_property.spaces}</label>
                    <div className="specific_request_cont room_specific_req">
                        <p className="single_cont_title">{t.new_property.living_space}</p>
                        <div className={`single_cont ${errors.roomCount ? "error" : ""}`}>
                            <i className="fa-solid fa-plus" onClick={() => increment(setLivingSpace)}></i>
                            <p className="number_unit_room">{livingSpace}</p>
                            <i className="fa-solid fa-minus" onClick={() => decrement(setLivingSpace)}></i>
                        </div>

                        <p className="single_cont_title">{t.new_property.bedrooms}</p>
                        <div className={`single_cont ${errors.roomCount ? "error" : ""}`}>
                            <i className="fa-solid fa-plus" onClick={() => increment(setBedrooms)}></i>
                            <p className="number_unit_room">{bedrooms}</p>
                            <i className="fa-solid fa-minus" onClick={() => decrement(setBedrooms)}></i>
                        </div>

                        <p className="single_cont_title">{t.new_property.kitchens}</p>
                        <div className={`single_cont ${errors.roomCount ? "error" : ""}`}>
                            <i className="fa-solid fa-plus" onClick={() => increment(setKitchen)}></i>
                            <p className="number_unit_room">{kitchen}</p>
                            <i className="fa-solid fa-minus" onClick={() => decrement(setKitchen)}></i>
                        </div>

                        <p className="single_cont_title">{t.new_property.bathrooms}</p>
                        <div className={`single_cont ${errors.roomCount ? "error" : ""}`}>
                            <i className="fa-solid fa-plus" onClick={() => increment(setBathrooms)}></i>
                            <p className="number_unit_room">{bathrooms}</p>
                            <i className="fa-solid fa-minus" onClick={() => decrement(setBathrooms)}></i>
                        </div>
                    </div>
                    <label className="specific_request_label"><i className="fa-solid fa-heart"></i> {t.new_property.included_comforts}</label>
                    <div className="specific_request_cont">
                        <div className="comfort_container">
                            {Object.entries(ComfortOptions()).map(([key, { label, icon }]) => (
                                <p
                                    key={key}
                                    className="comfort_option"
                                    onClick={() => toggleOption(key)}
                                    style={{
                                        border: selectedOptions.includes(key) ? "1px solid var(--azure)" : "",
                                        color: selectedOptions.includes(key) ? "var(--azure)" : "",
                                    }}
                                >
                                    <i className={icon} aria-hidden="true" style={{ marginRight: '8px' }}></i>
                                    {label}
                                </p>
                            ))}
                        </div>
                    </div>

                    {marketType === "rent" && (
                        <>
                            <label className="specific_request_label"><i className="fa-solid fa-dog"></i> {t.new_property.pet_allowed}</label>
                            <div className="specific_request_cont btn_specific_req">
                                <div className={`market_type_prop ${petPermission ? 'market_type_prop_selected' : ''}`} onClick={() => setPetPermission(true)}>
                                    <p>{t.yes}</p>
                                </div>
                                <div className={`market_type_prop ${!petPermission ? 'market_type_prop_selected' : ''}`} onClick={() => setPetPermission(false)}>
                                    <p>{t.no}</p>
                                </div>
                            </div>
                            <label className="specific_request_label"><i className="fa-solid fa-smoking"></i> {t.new_property.smoker_allowed}</label>
                            <div className="specific_request_cont btn_specific_req">
                                <div className={`market_type_prop ${smokePermission ? 'market_type_prop_selected' : ''}`} onClick={() => setSmokePermission(true)}>
                                    <p>{t.yes}</p>
                                </div>
                                <div className={`market_type_prop ${!smokePermission ? 'market_type_prop_selected' : ''}`} onClick={() => setSmokePermission(false)}>
                                    <p>{t.no}</p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <SavePropChangesModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                handleSave={SaveData}
                handleNoThanks={handleNotSaving}
                loadingSave={loadingSave}
            />
            <PopupMex active={popupActive} type={popupType} message={popupMex} onClose={handlePopupClose} />
        </div>
    );
};

export default NewProperty;
