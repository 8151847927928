import React from 'react';
import { useNavigate } from 'react-router-dom';

const Cookie_Policy = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  return (
    <div className='policies_container'>
      <div onClick={goToHome} className='policy_title_logo'>
        <i className="fa-solid fa-layer-group"></i>
        <p>Paypermate</p>
      </div>
      <div className='policies_cont'>
        <p className='title_policy'>Cookie Policy</p>
        <p><strong>Last updated: September 30, 2024</strong></p>

        <p className='declaration_policy'>
          This Cookie Policy explains how Paypermate Srl ("we," "us," or "our") uses cookies and similar tracking technologies when you visit our website or use our Service. By using our website or Service, you consent to the use of cookies as described in this policy.
        </p>

        <p className='section_titles'>1. What Are Cookies?</p>
        <p className='description_policy'>
          Cookies are small text files placed on your device (computer, mobile device, or tablet) when you visit a website. They are used to collect information about your browsing activities and to help improve your experience. Cookies may be "persistent" or "session" cookies.
        </p>
        <ul className='description_policy'>
          <li><strong>Session Cookies:</strong> Temporary cookies that expire once you close your web browser.</li>
          <li><strong>Persistent Cookies:</strong> Remain on your device until they expire or are deleted.</li>
        </ul>

        <p className='section_titles'>2. Types of Cookies We Use</p>
        <p className='description_policy'>
          We use the following types of cookies on our website:
        </p>
        <ul className='description_policy'>
          <li><strong>Essential Cookies:</strong> Necessary for the operation of our website and Service. They enable core features, such as security, authentication, and accessibility.</li>
          <li><strong>Performance and Analytics Cookies:</strong> These cookies collect information about how users interact with our website, such as pages visited, time spent on the site, and any errors encountered.</li>
          <li><strong>Functionality Cookies:</strong> Allow us to remember your preferences (such as language settings or login details) and personalize your experience on the website.</li>
          <li><strong>Targeting or Advertising Cookies:</strong> We may use these cookies to display relevant advertisements based on your browsing behavior and interests.</li>
        </ul>

        <p className='section_titles'>3. How We Use Cookies</p>
        <p className='description_policy'>
          We use cookies to:
        </p>
        <ul className='description_policy'>
          <li>Operate and improve our Service.</li>
          <li>Analyze usage trends and monitor traffic.</li>
          <li>Remember your preferences and login details.</li>
          <li>Deliver personalized content and advertising.</li>
        </ul>

        <p className='section_titles'>4. Third-Party Cookies</p>
        <p className='description_policy'>
          Some cookies on our website are set by third parties, such as Google Analytics and Stripe, to track performance metrics or enable payment services. These third-party cookies are subject to the privacy policies of the third-party providers.
        </p>

        <p className='section_titles'>5. Managing Cookies</p>
        <p className='description_policy'>
          You can control and manage cookies in your web browser settings. You may choose to block cookies or delete existing cookies from your device, but disabling cookies may affect the functionality of the website.
        </p>
        <ul className='description_policy'>
          <li><a href='https://support.google.com/chrome/answer/95647?hl=en' target='_blank' rel='noopener noreferrer'>Manage cookies in Chrome</a></li>
          <li><a href='https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences' target='_blank' rel='noopener noreferrer'>Manage cookies in Firefox</a></li>
          <li><a href='https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac' target='_blank' rel='noopener noreferrer'>Manage cookies in Safari</a></li>
          <li><a href='https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09' target='_blank' rel='noopener noreferrer'>Manage cookies in Edge</a></li>
        </ul>

        <p className='section_titles'>6. Changes to the Cookie Policy</p>
        <p className='description_policy'>
          We may update this Cookie Policy from time to time. You are advised to review this policy periodically for any changes. Changes to this Cookie Policy will be effective when posted on this page.
        </p>

        <p className='section_titles'>7. Contact Us</p>
        <p className='description_policy'>
          If you have any questions about this Cookie Policy, please contact us at: <strong>hello@paypermate.com</strong>
        </p>
      </div>
    </div>
  );
};

export default Cookie_Policy;
