import React, { useState, useEffect } from "react";
import MovingArrows from "../../MovingArrows";
import nationData from "../../../Geo/CountriesCities.json";
import stripeNation from "../../../Geo/SupportedStripeCountries.json";
import { useLanguage } from "../../../Translations/LenguageContext";
import { IoMdBusiness } from "react-icons/io";
import Spinner from "../../../components/Spinner";
import { useNavigate } from "react-router-dom";

function BusinessInfoForm({ setCurrentStep }) {
    const { t } = useLanguage();
    const navigate = useNavigate()
    const [selectedNation, setSelectedNation] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [vat, setVat] = useState("");
    const [city, setCity] = useState("");
    const [prov, setProv] = useState("");
    const [street, setStreet] = useState("");
    const [num, setNum] = useState("");
    const [zip, setZip] = useState("");
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchBusinessData();
    }, []);

    const setFieldBorderStyle = (id, value) => {
        const element = document.getElementById(id);
        if (value) {
            element.style.borderLeft = "3px solid var(--mainColor)";
            element.style.borderBottomLeftRadius = "3px solid var(--mainColor)";
            element.style.borderTopLeftRadius = "3px solid var(--mainColor)";
        } else {
            element.style.border = "";
        }
    };

    const fetchBusinessData = () => {
        const userId = sessionStorage.getItem("user_id");
        if (!userId) {
            console.error("User ID not found in session storage");
            return;
        }

        setLoading(true);
        fetch(`https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_owner_business_information_route?user_id=${userId}`)
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.business_info) {
                    const businessNameVal = data.business_info.business_name || "";
                    const vatVal = data.business_info.vat || "";
                    const nationVal = data.business_info.nation || "";
                    const cityVal = data.business_info.city || "";
                    const provVal = data.business_info.prov || "";
                    const streetVal = data.business_info.street || "";
                    const numVal = data.business_info.num || "";
                    const zipVal = data.business_info.zip || "";

                    setBusinessName(businessNameVal);
                    setVat(vatVal);
                    setSelectedNation(nationVal);
                    setCity(cityVal);
                    setProv(provVal);
                    setStreet(streetVal);
                    setNum(numVal);
                    setZip(zipVal);

                    // Set border styles
                    setFieldBorderStyle("business_name_val", businessNameVal);
                    setFieldBorderStyle("vat_val", vatVal);
                    setFieldBorderStyle("nation_val", nationVal);
                    setFieldBorderStyle("city_val", cityVal);
                    setFieldBorderStyle("prov_val", provVal);
                    setFieldBorderStyle("street_val", streetVal);
                    setFieldBorderStyle("num_val", numVal);
                    setFieldBorderStyle("zip_val", zipVal);
                } else if (data.error) {
                    setErr({ message: data.error, display: true });
                }
            })
            .catch(error => {
                setErr({ message: t.register_form.problem_back, display: true });
                console.error("Request failed:", error);
                setLoading(false);
            });
    };

    const handleNextStep = () => {
        CheckFields();
    };

    const CheckFields = () => {
        let errVal = false;

        const checkField = (value, id) => {
            const element = document.getElementById(id);
            if (value === "") {
                element.style.border = "1px solid var(--danger)";
                errVal = true;
            } else {
                element.style.border = "";
            }
        };

        checkField(businessName, "business_name_val");
        checkField(vat, "vat_val");
        checkField(selectedNation, "nation_val");
        checkField(city, "city_val");
        checkField(prov, "prov_val");
        checkField(street, "street_val");
        checkField(num, "num_val");
        checkField(zip, "zip_val");

        if (!errVal) {
            updateUserData();
        }
    };

    const updateUserData = () => {
        setErr({ message: "", display: false });
        setLoading(true);
        const userId = sessionStorage.getItem("user_id");
        if (!userId) {
            console.error("User ID not found in session storage");
            return;
        }

        const dataToUpdate = {
            user_id: userId,
            business_name: businessName,
            vat: vat,
            nation: selectedNation,
            city: city,
            prov: prov,
            street: street,
            num: num,
            zip: zip,
        };

        fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/owner_business_information', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToUpdate),
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.message === "OK") {
                    setCurrentStep(3);
                } else {
                    setErr({ message: t.register_form.problem_back, display: true });
                    console.error("Error updating user type:", data.error);
                }
            })
            .catch(error => {
                setErr({ message: t.register_form.problem_back, display: true });
                console.error("Request failed:", error);
            });
    };

    return (
        <div className="form_complete_reg">
            <p className="form_complete_reg_title">
                <IoMdBusiness /> {t.complete_account.business_info}
            </p>
            <div className="form_complete_reg_body">
                <div className="input_label">
                    <label>{t.complete_account.business_name}</label>
                    <input
                        id="business_name_val"
                        type="text"
                        value={businessName}
                        onChange={(e) => {
                            setBusinessName(e.target.value);
                            setFieldBorderStyle("business_name_val", e.target.value);
                        }}
                    />
                </div>
                <div className="input_label">
                    <label>{t.complete_account.vat}</label>
                    <input
                        id="vat_val"
                        type="text"
                        value={vat}
                        onChange={(e) => {
                            setVat(e.target.value);
                            setFieldBorderStyle("vat_val", e.target.value);
                        }}
                    />
                </div>
                <div className="input_label">
                    <label>{t.complete_account.country}</label>
                    <select
                        id="nation_val"
                        value={selectedNation}
                        onChange={(e) => {
                            setSelectedNation(e.target.value);
                            setFieldBorderStyle("nation_val", e.target.value);
                        }}
                    >
                        <option value="" disabled>-</option>
                        {stripeNation.map((nation) => (
                            <option key={nation.value} value={nation.value}>
                                {nation.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="input_label">
                    <label>{t.complete_account.city}</label>
                    <input
                        id="city_val"
                        type="text"
                        value={city}
                        onChange={(e) => {
                            setCity(e.target.value);
                            setFieldBorderStyle("city_val", e.target.value);
                        }}
                    />
                </div>
                <div className="input_label">
                    <label>{t.complete_account.province}</label>
                    <input
                        id="prov_val"
                        type="text"
                        value={prov}
                        onChange={(e) => {
                            setProv(e.target.value);
                            setFieldBorderStyle("prov_val", e.target.value);
                        }}
                    />
                </div>
                <div className="input_label">
                    <label>{t.complete_account.street}</label>
                    <div className="street_num_cont">
                        <input
                            id="street_val"
                            type="text"
                            value={street}
                            onChange={(e) => {
                                setStreet(e.target.value);
                                setFieldBorderStyle("street_val", e.target.value);
                            }}
                        />
                        <div className="num_label_cont">
                            <label>{t.complete_account.n}</label>
                            <input
                                id="num_val"
                                type="text"
                                value={num}
                                onChange={(e) => {
                                    setNum(e.target.value);
                                    setFieldBorderStyle("num_val", e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="input_label">
                    <label>{t.complete_account.zip}</label>
                    <input
                        id="zip_val"
                        type="text"
                        value={zip}
                        onChange={(e) => {
                            setZip(e.target.value);
                            setFieldBorderStyle("zip_val", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="steps_navigation">
                <button className="moving_arrows_btn" onClick={() => setCurrentStep(1)}>
                    <i className="fa-solid fa-chevron-left"></i>
                </button>
                <div></div>
                <Spinner loading={loading} err={err}></Spinner>
                <button className="moving_arrows_btn" onClick={handleNextStep}>
                    <i className="fa-solid fa-chevron-right"></i>
                </button>
            </div>
        </div>
    );
}

export default BusinessInfoForm;
