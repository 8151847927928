import React, { useState, useEffect } from 'react';
import { Modal, Button, FormFloating } from 'react-bootstrap';
import Spinner from "../../../components/Spinner";
import { useLanguage } from '../../../Translations/LenguageContext';
import Nations from "../../../Geo/SupportedStripeCountries.json"

import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable, deleteObject } from 'firebase/storage';
import { getDownloadURL } from 'firebase/storage';

const AddPropertieModal = ({ show, handleClose, onPropertieAddedOrUpdated, propertyData }) => {
    const { t } = useLanguage();
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const [selectedNation, setSelectedNation] = useState('');
    const [cities, setCities] = useState([]);
    const [selectedUnitType, setSelectedUnitType] = useState(null);
    const [selectedBuyRentChoice, setSelectedBuyRentChoice] = useState("rent");
    const [checkedItems, setCheckedItems] = useState({});
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [formData, setFormData] = useState({
        unit_type: propertyData?.unit_type || '',
        title_property: propertyData?.title_property || '',
        notes: propertyData?.notes || '',
        nation_property: propertyData?.nation_property || '',
        city_property: propertyData?.city_property || '',
        street_property: propertyData?.street_property || '',
        number_property: propertyData?.number_property || '',
        zip_property: propertyData?.zip_property || '',
        type_property: propertyData?.type_property || '',
        mt2_property: propertyData?.mt2_property || '',
        floor_property: propertyData?.floor_property || '',
        building_floors_property: propertyData?.building_floors_property || '',
        energy_class_property: propertyData?.energy_class_property || '',
        exposure_property: propertyData?.exposure_property || '',
        heating_property: propertyData?.heating_property || '',
        external_fixtures_property: propertyData?.external_fixtures_property || '',
        state_property: propertyData?.state_property || '',
        construction_year_property: propertyData?.construction_year_property || '',
        living_property: propertyData?.living_property || '',
        bedroom_property: propertyData?.bedroom_property || '',
        kitchen_property: propertyData?.kitchen_property || '',
        bathroom_property: propertyData?.bathroom_property || '',
        price_property: propertyData?.price_property || '',
        // available: propertyData?.available || '',
        currency_property: propertyData?.currency_property || '',
        expenses_property: propertyData?.expenses_property || '',
        deposit_property: propertyData?.deposit_property || '',
        commissions_property: propertyData?.commissions_property || '',
        commission_type_property: propertyData?.commission_type_property || '',
        other_details: propertyData?.other_details || {},
    });

    const firebaseConfig = {
        apiKey: "AIzaSyBZramUU6MjXX4q9BUzsfPiKfGYiwOXByM",
        authDomain: "flatjourney.firebaseapp.com",
        projectId: "flatjourney",
        storageBucket: "flatjourney.appspot.com",
        messagingSenderId: "340390454645",
        appId: "1:340390454645:web:05cfa12b0ffe3e07a09f3c",
        measurementId: "G-SQH8TQFGJ7"
    };

    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);

    useEffect(() => {
        if (propertyData) {
            setFormData({
                title_property: propertyData.title_property || '',
                unit_type: propertyData.unit_type || '',
                notes: propertyData.notes || '',
                nation_property: propertyData.nation_property || '',
                city_property: propertyData.city_property || '',
                street_property: propertyData.street_property || '',
                number_property: propertyData.number_property || '',
                zip_property: propertyData.zip_property || '',
                type_property: propertyData.type_property || '',
                mt2_property: propertyData.mt2_property || '',
                floor_property: propertyData.floor_property || '',
                building_floors_property: propertyData.building_floors_property || '',
                energy_class_property: propertyData.energy_class_property || '',
                exposure_property: propertyData.exposure_property || '',
                heating_property: propertyData.heating_property || '',
                external_fixtures_property: propertyData.external_fixtures_property || '',
                state_property: propertyData.state_property || '',
                construction_year_property: propertyData.construction_year_property || '',
                living_property: propertyData.living_property || '',
                bedroom_property: propertyData.bedroom_property || '',
                kitchen_property: propertyData.kitchen_property || '',
                bathroom_property: propertyData.bathroom_property || '',
                price_property: propertyData.price_property || '',
                // available: propertyData.available || '',
                currency_property: propertyData.currency_property || '',
                expenses_property: propertyData.expenses_property || '',
                deposit_property: propertyData.deposit_property || '',
                commissions_property: propertyData.commissions_property || '',
                commission_type_property: propertyData.commission_type_property || '',
            });

            if (propertyData.other_details) {
                const detailsArray = propertyData.other_details ? propertyData.other_details.split(",") : [];

                const initialCheckedItems = Object.keys(checkboxDetails).reduce((acc, key) => {
                    acc[key] = detailsArray.includes(key);
                    return acc;
                }, {});

                setCheckedItems(initialCheckedItems);
            } else {
                setCheckedItems(Object.keys(checkboxDetails).reduce((acc, key) => {
                    acc[key] = false;
                    return acc;
                }, {}));
            }

            setSelectedNation(propertyData.nation_property || '');
            // setCities(Nations[propertyData.nation_property] || []);
        } else {
            setFormData({
                unit_type: '',
                title_property: '',
                notes: '',
                nation_property: '',
                city_property: '',
                street_property: '',
                number_property: '',
                zip_property: '',
                type_property: '',
                mt2_property: '',
                floor_property: '',
                building_floors_property: '',
                energy_class_property: '',
                exposure_property: '',
                heating_property: '',
                external_fixtures_property: '',
                state_property: '',
                construction_year_property: '',
                living_property: '',
                bedroom_property: '',
                kitchen_property: '',
                bathroom_property: '',
                price_property: '',
                // available: '',
                currency_property: '',
                expenses_property: '',
                deposit_property: '',
                commissions_property: '',
                commission_type_property: '',
                other_details: '',
            });
            setSelectedNation('');
            setCities([]);
        }
    }, [propertyData]);

    const handleNationChange = (event) => {
        const nation = event.target.value;
        setSelectedNation(nation);
        // setCities(Nations[nation] || []);
    };

    const CheckFields = () => {
        const fields = [
            "title_property", "unit_type", "notes", "nation_property", "city_property", "street_property",
            "number_property", "zip_property", "type_property", "mt2_property",
            "floor_property", "building_floors_property", "energy_class_property",
            "exposure_property", "heating_property", "external_fixtures_property",
            "state_property", "construction_year_property", "living_property",
            "bedroom_property", "kitchen_property", "bathroom_property",
            // "available",
            "price_property", "currency_property", "expenses_property",
            "deposit_property", "commissions_property", "commission_type_property"
        ];

        let errFields = false;
        let fieldValues = {};
        let errorMessage = "";

        fields.forEach(field => {
            const element = document.getElementById(field);
            if (element) {
                const value = element.value;
                if (value === "") {
                    if (field === "title_property") {
                        element.style.borderBottom = "2px solid var(--danger)";
                    } else {
                        element.style.border = "1px solid var(--danger)";
                    }
                    errFields = true;
                    fieldValues[field] = null;
                } else if (value.includes("-") && ["mt2_property", "building_floors_property", "construction_year_property", "living_property", "bedroom_property", "kitchen_property", "bathroom_property", "price_property", "expenses_property", "deposit_property", "commissions_property"].includes(field)) {
                    if (field === "title_property") {
                        element.style.borderBottom = "2px solid var(--danger)";
                    } else {
                        element.style.border = "1px solid var(--danger)";
                    }
                    errorMessage = "Number can't be negative";
                    errFields = true;
                    fieldValues[field] = null;
                } else {
                    element.style.border = "";
                    element.style.borderBottom = "";
                    fieldValues[field] = value;
                }
            } else {
                fieldValues[field] = null;
            }
        });

        if (errFields) {
            setErr({ message: errorMessage || t.register_form.red_fields, display: true });
        } else {
            setErr({ message: "", display: false });
            setLoading(true);
            SaveData(fieldValues);
        }
    };

    function CleanField() {
        setFormData({
            unit_type: '',
            title_property: '',
            notes: '',
            nation_property: '',
            city_property: '',
            street_property: '',
            number_property: '',
            zip_property: '',
            type_property: '',
            mt2_property: '',
            floor_property: '',
            building_floors_property: '',
            energy_class_property: '',
            exposure_property: '',
            heating_property: '',
            external_fixtures_property: '',
            state_property: '',
            construction_year_property: '',
            living_property: '',
            bedroom_property: '',
            kitchen_property: '',
            bathroom_property: '',
            price_property: '',
            // available: '',
            currency_property: '',
            expenses_property: '',
            deposit_property: '',
            commissions_property: '',
            commission_type_property: '',
            other_details: '',
        })

        setSelectedBuyRentChoice("rent");
        setSelectedNation("")
        setCheckedItems({})
        setErr({ message: "", display: false });
        setLoading(false);
        handleClose();

        setUploading(false)
        setUploadedFiles([])
    }

    const checkboxDetails = {
        deposit: t.properties.deposit,
        terrace: t.properties.terrace,
        balcony: t.properties.balcony,
        box_garage: t.properties.box_garage,
        storage: t.properties.storage,
        // garden: t.properties.garden,
        elevator: t.properties.elevator,
        disabled_bathroom: t.properties.disabled_bathroom,
        full_furnishing: t.properties.full_furnishing,
        partial_furnishing: t.properties.partial_furnishing,
        kitchen_furnishing: t.properties.kitchen_furnishing,
        communal_garden: t.properties.communal_garden,
        private_garden: t.properties.private_garden,
        swimming_pool: t.properties.swimming_pool,
        sauna: t.properties.sauna,
        disabled_elevator: t.properties.disabled_elevator,
        alarm_system: t.properties.alarm_system,
        reception: t.properties.reception,
        external_parking: t.properties.external_parking,
        air_conditioning: t.properties.air_conditioning,
    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: checked,
        }));
    };


    const handleBuyRentChange = (e) => {
        const value = e.target.value;
        setSelectedBuyRentChoice(value);
    };


    const checkedItemsArray = Object.keys(checkedItems).filter(key => checkedItems[key]);
    const excludedLiving = ["office_building", "retail_property", "industrial_property", "mixed_use", "vacant_land", "agricultural_land", "commercial_land", "residential_land", "warehouse", "parking_lot", "storage_facility", "healthcare_facility", "educational_facility", "religious_facility", "deposit_storage_area", "garage",];
    const excludedforLand = ["mixed_use", "vacant_land", "agricultural_land", "commercial_land", "residential_land", "warehouse", "parking_lot", "storage_facility", "healthcare_facility", "educational_facility", "religious_facility", "deposit_storage_area", "garage",];
    const excludedforNonHabitable = ["mixed_use", "vacant_land", "agricultural_land", "commercial_land", "residential_land", "warehouse", "parking_lot", "storage_facility", "healthcare_facility", "educational_facility", "religious_facility", "deposit_storage_area", "garage",];

    const SaveData = (data) => {
        const userId = sessionStorage.getItem("user_id");
        const propertyId = propertyData?.key;

        const dataToSave = {
            user_id: userId,
            propertyId: propertyId,
            ...data,
            other_details: Object.keys(checkedItems)
                .filter(key => checkedItems[key])
                .join(","),
            files: uploadedFiles // Include the array of uploaded images here
        };

        fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/new_propertie', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSave),
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.message === "OK") {
                    setErr({ message: "", display: false });
                    CleanField();
                    onPropertieAddedOrUpdated();
                } else if (data.error) {
                    setErr({ message: t.register_form.errors_back, display: true });
                }
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
                setErr({ message: t.register_form.problem_back, display: true });
            });
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value,
            nation: id === 'city' && prevState.nation ? prevState.nation : prevState.nation
        }));
    };

    const handleFileInputClick = () => {
        document.getElementById('file_input_property_img').click();
    };

    useEffect(() => {
        if (propertyData?.files) {
            setUploadedFiles(propertyData.files);
        }
    }, [propertyData]);


    const handleFileUpload = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            setUploading(true);
            const filesArray = [];

            Array.from(files).forEach((file) => {
                const storageRef = ref(storage, `uploads/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setProgress(progress);
                    },
                    (error) => {
                        console.error('Upload failed', error);
                        setUploading(false);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            filesArray.push({ name: file.name, url: downloadURL, ref: storageRef });
                            if (filesArray.length === files.length) {
                                // Combine the new uploaded files with the existing ones
                                const updatedFiles = [...uploadedFiles, ...filesArray];
                                setUploadedFiles(updatedFiles);
                                setUploading(false);

                                // Optionally update propertyData.files here if needed
                                if (propertyData) {
                                    onPropertieAddedOrUpdated({
                                        ...propertyData,
                                        files: updatedFiles,
                                    });
                                }
                            }
                        });
                    }
                );
            });
        }
    };

    const handleFileRemove = (fileToRemove) => {
        if (fileToRemove && fileToRemove.ref) {
            const updatedFiles = uploadedFiles.filter(file => file.url !== fileToRemove.url);
            setUploadedFiles(updatedFiles);

            deleteObject(fileToRemove.name)
                .then(() => {
                    console.log('File deleted successfully');
                })
                .catch((error) => {
                    console.error('Error deleting file:', error);
                });

            if (propertyData) {
                onPropertieAddedOrUpdated({
                    ...propertyData,
                    files: updatedFiles,
                });
            }
        } else {
            console.error('Invalid file reference for deletion:', fileToRemove);
        }
    };


    return (
        <Modal show={show} onHide={CleanField} className="modal_property">
            <div className='header_modal'>
                <div className='modal_title_cont_prop'>
                    <i className='fa-solid fa-plus'></i>
                    <input id='title_property' className='modal_title' placeholder={t.properties.title_prop} value={formData.title_property} onChange={handleInputChange}></input>
                </div>
                <button className='close_x_modale' onClick={handleClose}><i className='fa-solid fa-x'></i></button>
            </div>
            <div className='body_modal'>

                <div className='property_input_cont'>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.unit_type}</label>
                            <select value={formData.unit_type || selectedUnitType} id='unit_type' className='property_input' onChange={(e) => setSelectedUnitType(e.target.value)}>
                                <option value={""}>-</option>
                                <option value={"single_family"}>{t.properties.single_family}</option>
                                <option value={"multi_family"}>{t.properties.multi_family}</option>
                                <option value={"apartment"}>{t.properties.apartment}</option>
                                <option value={"room"}>{t.properties.room}</option>
                                <option value={"condominium"}>{t.properties.condominium}</option>
                                <option value={"townhouse"}>{t.properties.townhouse}</option>
                                <option value={"cooperative"}>{t.properties.cooperative}</option>
                                <option value={"vacation_home"}>{t.properties.vacation_home}</option>
                                <option value={"mobile_home"}>{t.properties.mobile_home}</option>
                                <option value={"tiny_home"}>{t.properties.tiny_home}</option>
                                <option value={"office_building"}>{t.properties.office_building}</option>
                                <option value={"retail_property"}>{t.properties.retail_property}</option>
                                <option value={"industrial_property"}>{t.properties.industrial_property}</option>
                                <option value={"mixed_use"}>{t.properties.mixed_use}</option>
                                <option value={"hotel"}>{t.properties.hotel}</option>
                                <option value={"vacant_land"}>{t.properties.vacant_land}</option>
                                <option value={"agricultural_land"}>{t.properties.agricultural_land}</option>
                                <option value={"commercial_land"}>{t.properties.commercial_land}</option>
                                <option value={"residential_land"}>{t.properties.residential_land}</option>
                                <option value={"warehouse"}>{t.properties.warehouse}</option>
                                <option value={"parking_lot"}>{t.properties.parking_lot}</option>
                                <option value={"storage_facility"}>{t.properties.storage_facility}</option>
                                <option value={"healthcare_facility"}>{t.properties.healthcare_facility}</option>
                                <option value={"educational_facility"}>{t.properties.educational_facility}</option>
                                <option value={"religious_facility"}>{t.properties.religious_facility}</option>
                                <option value={"deposit_storage_area"}>{t.properties.deposit_storage_area}</option>
                                <option value={"garage"}>{t.properties.garage}</option>
                                <option value={"marina"}>{t.properties.marina}</option>
                                <option value={"resort"}>{t.properties.resort}</option>
                                <option value={"ranch"}>{t.properties.ranch}</option>
                            </select>
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.contacts.notes}</label>
                            <input className='property_input' id='notes' value={formData.notes} onChange={handleInputChange}></input>
                        </div>
                    </div>
                    {/* <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.available_notAvailable}</label>
                            <select id='available' className='property_input' value={formData.available} onChange={handleInputChange}>
                                <option value={"available_rent"}>{t.properties.available_rent}</option>
                                <option value={"rented"}>{t.properties.rented}</option>

                            </select>
                        </div>
                    </div> */}
                </div>

                <div className='property_input_cont'>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.nation}</label>
                            <select id='nation_property' value={selectedNation} className='property_input' onChange={handleNationChange}>
                                <option value="">-</option>
                                {Nations.map((nation) => (
                                    <option key={nation.code} value={nation.code}>
                                        {nation.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.city}</label>
                            <input id='city_property' value={formData.city_property} onChange={handleInputChange} className='property_input'></input>
                            {/* <select id='city_property' value={formData.city_property} onChange={handleInputChange} className='property_input'>
                                <option value="">-</option>
                                {cities.map((city, index) => (
                                    <option key={index} value={city}>
                                        {city}
                                    </option>
                                ))}
                            </select> */}
                        </div>
                    </div>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.street_num}</label>
                            <div className='street_number_cont'>
                                <input id='street_property' value={formData.street_property} onChange={handleInputChange} className='property_input prop_street' placeholder='Roma'></input>
                                <input id='number_property' value={formData.number_property} onChange={handleInputChange} className='property_input prop_number' placeholder='N'></input>
                            </div>
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.zip}</label>
                            <input id='zip_property' value={formData.zip_property} onChange={handleInputChange} className='property_input' placeholder='NNNNN'></input>
                        </div>
                    </div>
                </div>

                <div className='property_input_cont'>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.prop_type}</label>
                            <select id='type_property' value={formData.type_property} onChange={handleInputChange} className='property_input'>
                                <option value={""}>-</option>
                                <option value={"full_ownership"}>{t.properties.full_ownership}</option>
                                <option value={"bare_ownership"}>{t.properties.bare_ownership}</option>
                                <option value={"partial_ownership"}>{t.properties.partial_ownership}</option>
                                <option value={"usufruct"}>{t.properties.usufruct}</option>
                                <option value={"timeshare"}>{t.properties.timeshare}</option>
                                <option value={"right_superficies"}>{t.properties.right_superficies}</option>
                            </select>
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.mt2}</label>
                            <input type='number' min='0' id='mt2_property' value={formData.mt2_property} onChange={handleInputChange} className='property_input' placeholder='NN'></input>
                        </div>
                    </div>
                    {!excludedforLand.includes(selectedUnitType) && (
                        <>
                            <div className='details_split_cont'>

                                <div className='label_input_cont_property'>
                                    <label className='property_input_section_title'>{t.properties.floor}</label>
                                    <input type='number' id='floor_property' value={formData.floor_property} onChange={handleInputChange} className='property_input' placeholder='N'></input>
                                </div>
                                <div className='label_input_cont_property'>
                                    <label className='property_input_section_title'>{t.properties.building_floors}</label>
                                    <input type='number' min='0' id='building_floors_property' value={formData.building_floors_property} onChange={handleInputChange} className='property_input' placeholder='NN'></input>
                                </div>
                            </div>
                            <div className='details_split_cont'>

                                <div className='label_input_cont_property'>
                                    <label className='property_input_section_title'>{t.properties.energy_class}</label>
                                    <select id='energy_class_property' value={formData.energy_class_property} onChange={handleInputChange} className='property_input'>
                                        <option value={""}>-</option>
                                        <option value={"pending_certification"}>{t.properties.pending_certification}</option>
                                        <option value={"exempt_property"}>{t.properties.exempt_property}</option>
                                        <option value={"not_classifiable"}>{t.properties.not_classifiable}</option>
                                        <option value={"A4"}>A4</option>
                                        <option value={"A3"}>A3</option>
                                        <option value={"A4"}>A4</option>
                                        <option value={"A1"}>A1</option>
                                        <option value={"A+"}>A+</option>
                                        <option value={"A"}>A</option>
                                        <option value={"B"}>B</option>
                                        <option value={"C"}>C</option>
                                        <option value={"D"}>D</option>
                                        <option value={"E"}>E</option>
                                        <option value={"F"}>F</option>
                                        <option value={"G"}>G</option>
                                    </select>
                                </div>
                                <div className='label_input_cont_property'>
                                    <label className='property_input_section_title'>{t.properties.exposure}</label>
                                    <select id='exposure_property' value={formData.exposure_property} onChange={handleInputChange} className='property_input'>
                                        <option value={""}>-</option>
                                        <option value={"north"}>{t.properties.north}</option>
                                        <option value={"south"}>{t.properties.south}</option>
                                        <option value={"est"}>{t.properties.est}</option>
                                        <option value={"west"}>{t.properties.west}</option>
                                    </select>
                                </div>
                            </div>
                            <div className='details_split_cont'>
                                <div className='label_input_cont_property'>
                                    <label className='property_input_section_title'>{t.properties.heating}</label>
                                    <select id='heating_property' value={formData.heating_property} onChange={handleInputChange} className='property_input'>
                                        <option value={""}>-</option>
                                        <option value={"autonomous"}>{t.properties.autonomous}</option>
                                        <option value={"centralized"}>{t.properties.centralized}</option>
                                        <option value={"absent"}>{t.properties.absent}</option>
                                    </select>
                                </div>
                                <div className='label_input_cont_property'>
                                    <label className='property_input_section_title'>{t.properties.external_fixtures}</label>
                                    <select id='external_fixtures_property' value={formData.external_fixtures_property} onChange={handleInputChange} className='property_input'>
                                        <option value={""}>-</option>
                                        <option value={"glass_wood"}>{t.properties.glass_wood}</option>
                                        <option value={"double_glass_wood"}>{t.properties.double_glass_wood}</option>
                                        <option value={"triple_glass_wood"}>{t.properties.triple_glass_wood}</option>
                                        <option value={"glass_metal"}>{t.properties.glass_metal}</option>
                                        <option value={"double_glass_metal"}>{t.properties.double_glass_metal}</option>
                                        <option value={"triple_glass_metal"}>{t.properties.triple_glass_metal}</option>
                                        <option value={"glass_pvc"}>{t.properties.glass_pvc}</option>
                                        <option value={"double_glass_pvc"}>{t.properties.double_glass_pvc}</option>
                                        <option value={"triple_glass_pvc"}>{t.properties.triple_glass_pvc}</option>
                                    </select>
                                </div>
                            </div>
                            <div className='details_split_cont'>
                                <div className='label_input_cont_property'>
                                    <label className='property_input_section_title'>{t.properties.prop_state}</label>
                                    <select id='state_property' value={formData.state_property} onChange={handleInputChange} className='property_input'>
                                        <option value={""}>-</option>
                                        <option value={"new"}>{t.properties.new}</option>
                                        <option value={"like_new"}>{t.properties.like_new}</option>
                                        <option value={"excellent_condition"}>{t.properties.excellent_condition}</option>
                                        <option value={"good_condition"}>{t.properties.good_condition}</option>
                                        <option value={"fair_condition"}>{t.properties.fair_condition}</option>
                                        <option value={"needs_renovation"}>{t.properties.needs_renovation}</option>
                                        <option value={"to_renovate"}>{t.properties.to_renovate}</option>
                                        <option value={"under_construction"}>{t.properties.under_construction}</option>
                                        <option value={"partially_renovated"}>{t.properties.partially_renovated}</option>
                                        <option value={"unfinished"}>{t.properties.unfinished}</option>
                                        <option value={"move_in_ready"}>{t.properties.move_in_ready}</option>
                                    </select>
                                </div>
                                <div className='label_input_cont_property'>
                                    <label className='property_input_section_title'>{t.properties.construction_year}</label>
                                    <input type='number' min='0' id='construction_year_property' value={formData.construction_year_property} onChange={handleInputChange} className='property_input' placeholder='YYYY'></input>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                {!excludedLiving.includes(selectedUnitType) && (
                    <>
                        <div className='property_input_cont'>

                            <div className='details_split_cont'>
                                <div className='label_input_cont_property'>
                                    <label className='property_input_section_title'>{t.properties.living}</label>
                                    <input type='number' min='0' id='living_property' value={formData.living_property} onChange={handleInputChange} className='property_input' placeholder='N' />
                                </div>
                                <div className='label_input_cont_property'>
                                    <label className='property_input_section_title'>{t.properties.bedroom}</label>
                                    <input type='number' min='0' id='bedroom_property' value={formData.bedroom_property} onChange={handleInputChange} className='property_input' placeholder='N' />
                                </div>
                            </div>
                            <div className='details_split_cont'>
                                <div className='label_input_cont_property'>
                                    <label className='property_input_section_title'>{t.properties.kitchen}</label>
                                    <input type='number' min='0' id='kitchen_property' value={formData.kitchen_property} onChange={handleInputChange} className='property_input' placeholder='N' />
                                </div>
                                <div className='label_input_cont_property'>
                                    <label className='property_input_section_title'>{t.properties.bathroom}</label>
                                    <input type='number' min='0' id='bathroom_property' value={formData.bathroom_property} onChange={handleInputChange} className='property_input' placeholder='N' />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {!excludedforLand.includes(selectedUnitType) && !excludedforNonHabitable.includes(selectedUnitType) && (
                    <div className='property_input_cont'>
                        <label className='property_input_section_title'>Altri dettagli inclusi</label>
                        <div className='details_split_cont'>
                            <div className='label_input_cont_property_btn'>
                                {Object.entries(checkboxDetails).map(([key, label]) => (
                                    <div key={key} className='label_checkbox_cont date_hour_modal_input'>
                                        <label className='label_checkbox'>{label}</label>
                                        <input
                                            type='checkbox'
                                            className='input_checkbox'
                                            value={key}
                                            checked={!!checkedItems[key]}
                                            onChange={handleCheckboxChange}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}


                <div className='property_input_cont'>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <div className='street_number_cont'>
                                {selectedBuyRentChoice === "selling" ? (
                                    <label className='property_input_section_title'>{t.properties.price}</label>
                                ) : (
                                    <label className='property_input_section_title'>{t.properties.property_rent}</label>
                                )}

                                <input type='number' min='0' value={formData.price_property} onChange={handleInputChange} id='price_property' className='property_input prop_street' placeholder='NNNN'></input>
                                <select id='currency_property' value={formData.currency_property} onChange={handleInputChange} className='property_input prop_number'>
                                    <option>€</option>
                                    <option>£</option>
                                    <option>$</option>
                                </select>
                            </div>
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.expenses}</label>
                            <input type='number' min='0' id='expenses_property' value={formData.expenses_property} onChange={handleInputChange} className='property_input' placeholder='NNNN'></input>
                        </div>
                    </div>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.deposit}</label>
                            <input type='number' min='0' id='deposit_property' value={formData.deposit_property} onChange={handleInputChange} className='property_input' placeholder='NNNN'></input>
                        </div>
                        <div className='label_input_cont_property'>
                            <div className='street_number_cont'>
                                <label className='property_input_section_title'>{t.properties.commissions}</label>
                                <input type='number' min='0' id='commissions_property' value={formData.commissions_property} onChange={handleInputChange} className='property_input prop_street' placeholder='NN or %'></input>
                                <select id='commission_type_property' className='property_input prop_number'>
                                    <option>%</option>
                                    <option>€</option>
                                    <option>£</option>
                                    <option>$</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='property_input_cont'>
                    <label className='property_input_section_title'>IMG & planimetria (Immagini o PDF)</label>
                    <button className='upload_img_btn' onClick={handleFileInputClick}>Upload imag</button>
                    <input
                        type="file"
                        id="file_input_property_img"
                        accept="image/*, .pdf"
                        multiple
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                    />

                    {uploading && (
                        <div>
                            <progress value={progress} max="100" />
                            <span>{Math.round(progress)}%</span>
                        </div>
                    )}

                    <div className='invited_boocking_users_cont'>
                        {uploadedFiles.map((file, index) => (
                            <div key={index} className='invited_boocking_users'>
                                <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
                                <i className='fa-solid fa-x' onClick={() => handleFileRemove(file)}></i>
                            </div>
                        ))}
                    </div>
                </div>


            </div>
            <div className='footer_modal'>
                <div className='footer_btn_cont'>
                    <button className='save_modal_btn' onClick={CheckFields}>
                        {t.properties.save_btn}
                    </button>
                    <button className='close_modal_btn' onClick={CleanField}>
                        {t.properties.close_btn}

                    </button>
                </div>
                <Spinner loading={loading} err={err}></Spinner>
            </div>
        </Modal>
    );
};

export default AddPropertieModal;
