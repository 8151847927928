import { useLanguage } from "../Translations/LenguageContext";


const FixtureOptions = () => {
    const {t} = useLanguage()
    
    let fixturesOptions
    return fixturesOptions = [
        { value: "glass_wood", label: t.properties.glass_wood },
        { value: "double_glass_wood", label: t.properties.double_glass_wood },
        { value: "triple_glass_wood", label: t.properties.triple_glass_wood },
        { value: "glass_metal", label: t.properties.glass_metal },
        { value: "double_glass_metal", label: t.properties.double_glass_metal },
        { value: "triple_glass_metal", label: t.properties.triple_glass_metal },
        { value: "glass_pvc", label: t.properties.glass_pvc },
        { value: "double_glass_pvc", label: t.properties.double_glass_pvc },
        { value: "triple_glass_pvc", label: t.properties.triple_glass_pvc },
    ];    
}

export default FixtureOptions