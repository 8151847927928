import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

function TenantValidation() {

    return (
        <div>
            <Navbar></Navbar>

            <Footer></Footer>

        </div>
    )
}

export default TenantValidation