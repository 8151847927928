const PropertyInput = ({ label, placeholder, value, onChange, options, inputType, error }) => (
    <div className={`section_property_input_cont ${error ? 'error' : ''}`}>
        {inputType === 'select' ? (
            <>
                <label className="label_property">
                    <i className="fa-solid fa-list"></i> {label}
                </label>
                <select className="value_property" value={value} onChange={onChange}>
                    <option value="">-</option>
                    {options && options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </>
        ) : (
            <>
                <label className="label_property">
                    <i className={`fa-solid ${inputType === "number" ? "fa-hashtag" : "fa-grip-lines"}`}></i> {label}
                </label>
                <input
                    className="value_property"
                    type={inputType}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            </>
        )}
        <i className={`fa-regular ${value ? 'fa-circle-check checked_mark' : 'fa-circle unchecked_mark'}`}></i>
    </div>
);

export default PropertyInput