import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../../Translations/LenguageContext";
import { useNavigate } from "react-router-dom";
import { BsHouses } from "react-icons/bs";
import AddPropertieModal from "../../Property/AddPropertieModal";
import PopupMex from "../../SavePopup";
import { Dropdown } from 'react-bootstrap';


function PropertiesList({ fetchProperties, properties, onPropertySelect }) {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [popupActive, setPopupActive] = useState(false);
    const [popupType, setPopupType] = useState("success");

    const [selectedPropertyKey, setSelectedPropertyKey] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedPropertyName, setSelectedPropertyName] = useState(null);
    const [selectedPropertyData, setSelectedPropertyData] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [userId, setUserId] = useState(sessionStorage.getItem("user_id"));



    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handlePropertyAdded = async () => {
        setPopupType("success");
        setPopupActive(true);
        await fetchProperties();
    };

    const handlePopupClose = () => {
        setPopupActive(false);
    };

    const handleEditClick = (key, property) => {
        setSelectedPropertyKey(key);
        setSelectedPropertyData(property);
        setShowModal(true);
    };

    const handleDeleteClick = (key, propertyName) => {
        setSelectedPropertyKey(key);
        setShowDeleteModal(true);
        setSelectedPropertyName(propertyName);
    };


    const handleCheckboxChange = (property) => {
        if (selectedProperty === property) {
            setSelectedProperty(null);
            onPropertySelect(null);
        } else {
            setSelectedProperty(property);
            onPropertySelect(property);
        }
    };

    const NavigateToProperties = () => {
        navigate("/control_panel/properties");
    };

    return (
        <div className="property_container">
            <div className="tenant_property_cont">
                <div className="tenant_prop_title_cont">
                <button className='tenant_add_property_btn' onClick={handleShow}>
                    <i className='fa-solid fa-plus'></i>{t.properties.add_property}
                </button>
                    {/* <p className="payment_title">{t.tenants.available_properties_title}</p> */}
                </div>
            </div>

            <div className="property_choice">
                {Object.keys(properties).length === 0 ? (
                    <div className='no_data_mex_cont'>
                        <p className='no_data_mex'>{t.properties.no_property}</p>
                        <div className='no_data_img'></div>
                        {/* <button className='tenant_go_property_btn' onClick={NavigateToProperties}>
                            <i><BsHouses /></i>View properties
                        </button> */}

                    </div>
                ) : (
                    Object.entries(properties).map(([id, property]) => (
                        <div key={id} className="property_item">
                            <div className="checkBox_cont">
                                <input
                                    type="checkbox"
                                    checked={selectedProperty === property}
                                    onChange={() => handleCheckboxChange(property)}
                                />
                            </div>
                            <p className="title_prop">{property.property_name}</p>
                            <div className="tenant_prop_det">
                                <p>{property.unit_type}</p>
                                <p>{property.size} m2</p>
                            </div>
                            <div className="tenant_prop_det">
                                <p><i className="fa-solid fa-map-pin"></i> {property.country} - {property.city}, {property.street} {property.street_number}, {property.postal_code}</p>
                            </div>
                            <div className="tenant_prop_det">
                                <div className="prop_expenses_cont">
                                    <p className="title_prop_expenses">{t.properties.price}</p>
                                    <p className="prop_ammount_expenses">{property.rental_amount}{property.currency}</p>
                                </div>

                                <div className="prop_expenses_cont">
                                    <p className="title_prop_expenses">{t.properties.expenses}</p>
                                    <p className="prop_ammount_expenses">{property.condominium_fees}{property.currency}</p>
                                </div>

                                <div className="prop_expenses_cont">
                                    <p className="title_prop_expenses">{t.properties.deposit}</p>
                                    <p className="prop_ammount_expenses">{property.security_deposit}{property.currency}</p>
                                </div>

                                {/* <div className="prop_expenses_cont">
                                    <p className="title_prop_expenses">{t.properties.commissions}</p>
                                    <p className="prop_ammount_expenses">{property.commissions_property}{property.commission_type_property}</p>
                                </div> */}
                            </div>
                            <div className='btn_tenant_prop_list'>
                                <div className='btn_prop_cont'>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            className='custom-dropdown-toggle'
                                            as="div"
                                        >
                                            <i className='fa-solid fa-ellipsis-vertical'></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='custom-dropdown-menu'>
                                            {/* <Dropdown.Item
                                                className='drop_down_item'
                                                onClick={() => handleEditClick(property.key, property)}
                                            >
                                                <i className='fa-solid fa-pencil'></i> {t.edit}
                                            </Dropdown.Item> */}
                                            <Dropdown.Item
                                                className='drop_down_item'
                                                onClick={NavigateToProperties}
                                            >
                                                <i><BsHouses /></i> {t.tenants.manage_properties}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <AddPropertieModal
                show={showModal}
                handleClose={handleClose}
                onPropertieAddedOrUpdated={handlePropertyAdded}
                propertyData={selectedPropertyData}
            />
            <PopupMex active={popupActive} type={popupType} message={""} onClose={handlePopupClose} />
        </div>
    );
}

export default PropertiesList;
