import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Spinner from "../../../../components/Spinner";
import { useLanguage } from '../../../../Translations/LenguageContext';
import { useNavigate } from 'react-router-dom';

const SavePropChangesModal = ({ show, handleClose, handleSave, handleNoThanks, loadingSave }) => {
    const { t } = useLanguage();
    const [err, setErr] = useState({ message: "", display: false });
    const navigate = useNavigate()

    const saveDataAndGoBack = async () => {
        await handleSave();
        setTimeout(() => {
            navigate("/control_panel/properties");
        }, 1000);
    };

    return (
        <Modal show={show} onHide={handleClose} className="modal_contacts_delete">
            <div className='header_modal'>
                <p className='save_data_prop_mex'>{t.new_property.modal.title}</p>
            </div>
            <div className='footer_modal'>
                {loadingSave ? (
                    <Spinner loading={loadingSave} err={err}></Spinner>
                ) : (
                    <div className='footer_btn_cont_save_prop_data'>
                        <button className='save_modal_btn save_btn' onClick={saveDataAndGoBack} disabled={loadingSave}>
                            {t.new_property.modal.save_btn}
                        </button>
                        <button className='close_modal_btn' onClick={handleNoThanks} disabled={loadingSave}>
                            {t.new_property.modal.dont_save_btn}
                        </button>
                    </div>

                )}
            </div>
        </Modal>
    );
};

export default SavePropChangesModal;
