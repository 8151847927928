import React, { useState, useEffect, useCallback } from "react";
import PropertiesList from "./PropertiesList";
import MonthlyPaymentsChoice from "./MonthlyPaymentsChoice";
import PersonalizedPayments from "./PersonalizedPayments";
import { GiPaintBrush } from "react-icons/gi";
import { BiCalendar } from "react-icons/bi";
import { useLanguage } from "../../../../Translations/LenguageContext";
import Spinner from "../../../../components/Spinner";

const TenantPropertieInformation = ({ tenant, toggleComponent, isActive }) => {

    const { t } = useLanguage();
    const [properties, setProperties] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setErrorMex] = useState({ message: "", display: false });
    const [err, setEr] = useState(false)
    const [selectedProperty, setSelectedProperty] = useState("");
    const [paymentType, setPaymentType] = useState("monthly");
    const [monthlyDetails, setMonthlyDetails] = useState({});
    const [personalizedDetails, setPersonalizedDetails] = useState({});
    const [check, setCheck] = useState(false)
    const userId = sessionStorage.getItem("user_id");


    const fetchProperties = async () => {
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_properties', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProperties(data.properties || {});
        } catch (error) {
            setEr(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProperties();
    }, [userId]);

    const handlePropertySelection = (property) => {
        setSelectedProperty(property);
    };

    const handlePaymentTypeSelection = (type) => {
        setPaymentType(type);
    };

    const handleMonthlyDetailsUpdate = useCallback((details) => {
        setMonthlyDetails(details);
    }, []);

    const handlePersonalizedPaymentsUpdate = useCallback((details) => {
        setPersonalizedDetails(details);
    }, []);

    const HandleCheckandSave = () => {
        if (!selectedProperty) {
            setErrorMex({ message: "Please select a property.", display: true });
            return;
        }
        if (paymentType === 'monthly') {

            if (!monthlyDetails.monthly_ammount || Object.keys(monthlyDetails.monthly_ammount).length === 0) {
                setErrorMex({ message: "Please enter monthly amounts", display: true });
            } else if (monthlyDetails.from_date === "") {
                setErrorMex({ message: "Please enter monthly insert a from date", display: true });
            } else {
                setErrorMex({ message: "", display: false });
            }
        }
        if (paymentType === 'personalized') {

            if (personalizedDetails.monthlyPayments === "") {
                setErrorMex({ message: "Please enter monthly amounts", display: true })
            } else if (personalizedDetails.selectedStartDate === "") {
                setErrorMex({ message: "Please enter monthly insert a from date", display: true });
            } else if (personalizedDetails.selectedEndDate === "") {
                setErrorMex({ message: "Please enter monthly insert a to date", display: true });
            } else {
                setErrorMex({ message: "", display: false });
            }
        }
        
        SaveData();
    };

    const SaveData = () => {
        setLoading(true);

        const dataToSend = {
            user_id: userId ,
            tenant_id: tenant,
            property_id: selectedProperty.key,
            paymentType: paymentType,
            monthlyDetails: monthlyDetails,
            personalizedDetail: personalizedDetails,
        };
        fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/tenant_property_information", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSend),
        })
            .then((response) => response.json())
            .then((data) => {
                setLoading(false);
                if (data.message === "OK") {
                    setErrorMex({ message: "", display: false });
                    toggleComponent("documents")
                    setCheck(true)
                } else if (data.error) {
                    setErrorMex({ message: t.register_form.errors_back, display: true });
                }
            })
            .catch((e) => {
                setLoading(false);
                setErrorMex({ message: t.register_form.problem_back, display: true });
            });
    };

    useEffect(() => {
        if (tenant) {
            setLoading(true);
            fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_specific_tenant_data", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ userId: userId, tenant_id: tenant })
            })
                .then(response => response.json())
                .then(data => {
                    setLoading(false);
                    setErrorMex({ message: "", display: false });
                    const tenantData = data.tenant?.tenant_property_information || {};
                    
                    if (Object.keys(tenantData).length > 0) {
                        toggleComponent("documents");
                        setCheck(true);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setErrorMex({ message: t.register_form.problem_back, display: true });
                });
        }
    }, [tenant, userId, t]);

    return (
        <div className={`tenant_label_input_cont ${check ? "label_input_cont_done" : ""}`} >
            <div className={`new_tenant_title_chevron_cont ${isActive ? "new_tenant_title_chevron_cont_open" : ""}`} onClick={() => toggleComponent("propertyInfo")}>
                <div className="new_tenant_title_desc_cont">
                    <p className="new_tenant_title">{t.tenants.available_prop_rent_title}</p>
                    <p className="description_tenant_title">{t.tenants.available_prop_rent_desc}</p>
                </div>
                <div className="new_tenant_check_chevron_cont">
                    <i className={`fa-regular ${check ? "fa-circle-check check_tenant": "fa-circle circle_tenant"}`}></i>
                    <i className={`fa-solid ${isActive ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                </div>
            </div>

            <div className={`toggle_tenant_info_cont ${isActive ? "active_tenant_info" : ""}`}>
                <div className="property_choice_cont">
                    <PropertiesList
                        fetchProperties={fetchProperties}
                        properties={properties}
                        onPropertySelect={handlePropertySelection}
                    />
                    <div className="rent_ammount_choise_cont">
                        <div className="rent_amm_btns_cont">
                            <button
                                className={`rent_amm_btns ${paymentType === 'personalized' ? 'active_payment' : ''}`}
                                onClick={() => handlePaymentTypeSelection('personalized')}
                            >
                                <i><GiPaintBrush /></i> {t.tenants.personalized}
                            </button>
                            <button
                                className={`rent_amm_btns ${paymentType === 'monthly' ? 'active_payment' : ''}`}
                                onClick={() => handlePaymentTypeSelection('monthly')}
                            >
                                <i><BiCalendar /></i> {t.tenants.monthly}
                            </button>
                        </div>

                        {paymentType === 'monthly' && (
                            <MonthlyPaymentsChoice
                                selectedProperty={selectedProperty}
                                onMonthlyDetailsUpdate={handleMonthlyDetailsUpdate}
                            />
                        )}
                        {paymentType === 'personalized' && (
                            <PersonalizedPayments
                                selectedProperty={selectedProperty}
                                onPersonalizedPaymentsUpdate={handlePersonalizedPaymentsUpdate}
                            />
                        )}
                    </div>
                </div>
                <div className="save_tenant_info_btn_container">
                    <button className="save_tenant_info_btn" onClick={HandleCheckandSave}>
                        <Spinner loading={loading} err={error} />
                        <span>{t.tenants.save_btn}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TenantPropertieInformation;
