import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

function AiAnalyt(){
    
    return(
        <div>
            <Navbar></Navbar>

            <Footer></Footer>
        </div>
    )
}

export default AiAnalyt