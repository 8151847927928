import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Spinner from "../../../components/Spinner";
import { useLanguage } from '../../../Translations/LenguageContext';
import Nations from "../../../Geo/SupportedStripeCountries.json";

const AddContactModal = ({ show, handleClose, onContactAdded, contact }) => {
    const { t } = useLanguage();
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const [selectedNation, setSelectedNation] = useState(contact?.nation || '');
    const [cities, setCities] = useState([]);
    const [formData, setFormData] = useState({
        name: contact?.name || '',
        surname: contact?.surname || '',
        birthday: contact?.birthday || '',
        sex: contact?.sex || '',
        email: contact?.email || '',
        phone: contact?.phone || '',
        city: contact?.city || '',
        street: contact?.street || '',
        number: contact?.number || '',
        zip: contact?.zip || '',
        unit_type: contact?.unit_type || '',
        unit_state: contact?.unit_state || '',
        mt2_property: contact?.mt2_property || '',
        heating_property: contact?.heating_property || '',
        floor_property: contact?.floor_property || '',
        building_floors_property: contact?.building_floors_property || '',
        living_property: contact?.living_property || '',
        bedroom_property: contact?.bedroom_property || '',
        kitchen_property: contact?.kitchen_property || '',
        bathroom_property: contact?.bathroom_property || '',
        why_look_prop: contact?.why_look_prop || '',
        transfer_date: contact?.transfer_date || '',
        bgt_min: contact?.bgt_min || '',
        bgt_max: contact?.bgt_max || '',
        // mortage: contact?.mortage || '',
        // perc_mortage: contact?.perc_mortage || '',
        // sell_house: contact?.sell_house || '',
        // first_house: contact?.first_house || '',
        notes: contact?.notes || '',
    });

    useEffect(() => {
        if (contact) {
            setFormData({
                name: contact.name || '',
                surname: contact.surname || '',
                birthday: contact.birthday || '',
                sex: contact.sex || '',
                email: contact.email || '',
                phone: contact.phone || '',
                nation: contact.nation || '',
                city: contact.city || '',
                street: contact.street || '',
                number: contact.number || '',
                zip: contact.zip || '',
                unit_type: contact.unit_type || '',
                unit_state: contact.unit_state || '',
                mt2_property: contact.mt2_property || '',
                heating_property: contact.heating_property || '',
                floor_property: contact.floor_property || '',
                building_floors_property: contact.building_floors_property || '',
                living_property: contact.living_property || '',
                bedroom_property: contact.bedroom_property || '',
                kitchen_property: contact.kitchen_property || '',
                bathroom_property: contact.bathroom_property || '',
                why_look_prop: contact.why_look_prop || '',
                transfer_date: contact.transfer_date || '',
                bgt_min: contact.bgt_min || '',
                bgt_max: contact.bgt_max || '',
                // mortage: contact.mortage || '',
                // perc_mortage: contact.perc_mortage || '',
                // sell_house: contact.sell_house || '',
                // first_house: contact.first_house || '',
                notes: contact.notes || '',
            });
            setSelectedNation(contact.nation || '');
            // setCities(Nations[contact.nation] || []);
        } else {
            setFormData({
                name: '',
                surname: '',
                birthday: '',
                sex: '',
                email: '',
                phone: '',
                nation: '',
                city: '',
                street: '',
                number: '',
                zip: '',
                unit_type: '',
                unit_state: '',
                mt2_property: '',
                heating_property: '',
                floor_property: '',
                building_floors_property: '',
                living_property: '',
                bedroom_property: '',
                kitchen_property: '',
                bathroom_property: '',
                why_look_prop: '',
                transfer_date: '',
                bgt_min: '',
                bgt_max: '',
                // mortage: '',
                // perc_mortage: '',
                // sell_house: '',
                // first_house: '',
                notes: '',
            });
            setSelectedNation('');
            setCities([]);
        }
    }, [contact]);

    const handleNationChange = (event) => {
        const nation = event.target.value;
        setSelectedNation(nation);
        // setCities(Nations[nation] || []);
        setFormData(prevState => ({ ...prevState, nation }));
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value,
            nation: id === 'city' && prevState.nation ? prevState.nation : prevState.nation // Preserve nation when city changes
        }));
    };

    const CheckFields = () => {
        const fields = [
            "name",
            "surname",
            "birthday",
            "sex",
            "email",
            "phone",
            "nation",
            "city",
            "street",
            "number",
            "zip",
            "unit_type",
            "unit_state",
            "mt2_property",
            "heating_property",
            "floor_property",
            "building_floors_property",
            "living_property",
            "bedroom_property",
            "kitchen_property",
            "bathroom_property",
            "why_look_prop",
            "transfer_date",
            "bgt_min",
            "bgt_max",
            // "mortage",
            // "perc_mortage",
            // "sell_house",
            // "first_house",
            "notes",
        ];

        let errFields = false;
        let fieldValues = {};
        let errorMessage = "";

        fields.forEach(field => {
            const value = formData[field];
            if (value === "") {
                errFields = true;
                document.getElementById(field).style.border = "1px solid var(--danger)";
            } else {
                document.getElementById(field).style.border = "";
                fieldValues[field] = value;
            }
        });

        if (errFields) {
            setErr({ message: errorMessage || t.register_form.red_fields, display: true });
        } else {
            setErr({ message: "", display: false });
            setLoading(true);
            SaveData(fieldValues);
        }
    };

    function CleanField() {
        setFormData({
            name: '',
            surname: '',
            birthday: '',
            sex: '',
            email: '',
            phone: '',
            nation: '',
            city: '',
            street: '',
            number: '',
            zip: '',
            unit_type: '',
            unit_state: '',
            mt2_property: '',
            heating_property: '',
            floor_property: '',
            building_floors_property: '',
            living_property: '',
            bedroom_property: '',
            kitchen_property: '',
            bathroom_property: '',
            why_look_prop: '',
            transfer_date: '',
            bgt_min: '',
            bgt_max: '',
            // mortage: '',
            // perc_mortage: '',
            // sell_house: '',
            // first_house: '',
            notes: '',
        });

        setSelectedNation('');
        setCities([]);
        setErr({ message: "", display: false });
        setLoading(false);
        handleClose();
    }

    const SaveData = (data) => {
        const userId = sessionStorage.getItem("user_id");
        const contactId = contact?.key;

        const dataToSave = {
            user_id: userId,
            contact_id: contactId,
            ...data
        };

        fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/new_or_update_contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSave),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setLoading(false);
                if (data.message === "OK") {
                    setErr({ message: "", display: false });
                    CleanField();
                    onContactAdded();
                } else if (data.error) {
                    setErr({ message: t.register_form.errors_back, display: true });
                }
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
                setErr({ message: t.register_form.problem_back, display: true });
            });
    };
    return (
        <Modal show={show} onHide={CleanField} className="modal_contacts">
            <div className='header_modal'>
                <div className='modal_title_cont_prop'>
                    <i className={`fa-solid ${contact ? 'fa-pencil' : 'fa-plus'}`}></i>
                    <p>{contact ? t.contacts.update_contact : t.contacts.add_contact}</p>
                </div>
                <button className='close_x_modale' onClick={handleClose}><i className='fa-solid fa-x'></i></button>
            </div>
            <div className='body_modal'>
                <div className='property_input_cont'>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.register_form.name}</label>
                            <input id='name' className='property_input' value={formData.name} onChange={handleInputChange}></input>
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.register_form.surname}</label>
                            <input id='surname' className='property_input' value={formData.surname} onChange={handleInputChange}></input>
                        </div>
                    </div>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.complete_account.birthday}</label>
                            <input type="date" id='birthday' className='property_input' value={formData.birthday} onChange={handleInputChange}></input>
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.complete_account.sex}</label>
                            <select id='sex' className='property_input' value={formData.sex} onChange={handleInputChange}>
                                <option value="">-</option>
                                <option value="M">M</option>
                                <option value="F">F</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='property_input_cont'>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>Email</label>
                            <input id='email' className='property_input' value={formData.email} onChange={handleInputChange}></input>
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.register_form.phone}</label>
                            <input type="phone" id='phone' className='property_input' value={formData.phone} onChange={handleInputChange}></input>
                        </div>
                    </div>
                </div>
                <p></p>
                <div className='property_input_cont'>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.nation}</label>
                            <select id='nation' className='property_input' value={selectedNation} onChange={handleNationChange}>
                                <option value="">-</option>
                                {Nations.map((nation) => (
                                    <option key={nation.value} value={nation.value}>
                                        {nation.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.city}</label>
                            <input id='city' className='property_input' value={formData.city} onChange={handleInputChange}></input>
                            {/* <select id='city' className='property_input' value={formData.city} onChange={handleInputChange}>
                                <option value="">-</option>
                                {cities.map((city, index) => (
                                    <option key={index} value={city}>
                                        {city}
                                    </option>
                                ))}
                            </select> */}
                        </div>
                    </div>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.street_num}</label>
                            <div className='street_number_cont'>
                                <input id='street' className='property_input prop_street' placeholder='Roma' value={formData.street} onChange={handleInputChange}></input>
                                <input id='number' className='property_input prop_number' placeholder='N' value={formData.number} onChange={handleInputChange}></input>
                            </div>
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.zip}</label>
                            <input id='zip' className='property_input' placeholder='NNNNN' value={formData.zip} onChange={handleInputChange}></input>
                        </div>
                    </div>
                </div>

                <div className='property_input_cont'>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.unit_type}</label>
                            <select id='unit_type' className='property_input' value={formData.unit_type} onChange={handleInputChange}>
                                <option value="">-</option>
                                <option value={'single_family'}>{t.properties.single_family}</option>
                                <option value={'multi_family'}>{t.properties.multi_family}</option>
                                <option value={'apartment'}>{t.properties.apartment}</option>
                                <option value={'room'}>{t.properties.room}</option>
                                <option value={'condominium'}>{t.properties.condominium}</option>
                                <option value={'townhouse'}>{t.properties.townhouse}</option>
                                <option value={'cooperative'}>{t.properties.cooperative}</option>
                                <option value={'vacation_home'}>{t.properties.vacation_home}</option>
                                <option value={'mobile_home'}>{t.properties.mobile_home}</option>
                                <option value={'tiny_home'}>{t.properties.tiny_home}</option>
                                <option value={'office_building'}>{t.properties.office_building}</option>
                                <option value={'retail_property'}>{t.properties.retail_property}</option>
                                <option value={'industrial_property'}>{t.properties.industrial_property}</option>
                                <option value={'mixed_use'}>{t.properties.mixed_use}</option>
                                <option value={'hotel'}>{t.properties.hotel}</option>
                                <option value={'vacant_land'}>{t.properties.vacant_land}</option>
                                <option value={'agricultural_land'}>{t.properties.agricultural_land}</option>
                                <option value={'commercial_land'}>{t.properties.commercial_land}</option>
                                <option value={'residential_land'}>{t.properties.residential_land}</option>
                                <option value={'warehouse'}>{t.properties.warehouse}</option>
                                <option value={'parking_lot'}>{t.properties.parking_lot}</option>
                                <option value={'storage_facility'}>{t.properties.storage_facility}</option>
                                <option value={'healthcare_facility'}>{t.properties.healthcare_facility}</option>
                                <option value={'educational_facility'}>{t.properties.educational_facility}</option>
                                <option value={'religious_facility'}>{t.properties.religious_facility}</option>
                                <option value={'deposit_storage_area'}>{t.properties.deposit_storage_area}</option>
                                <option value={'garage'}>{t.properties.garage}</option>
                                <option value={'marina'}>{t.properties.marina}</option>
                                <option value={'resort'}>{t.properties.resort}</option>
                                <option value={'ranch'}>{t.properties.ranch}</option>
                            </select>
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.prop_state}</label>
                            <select id='unit_state' className='property_input' value={formData.unit_state} onChange={handleInputChange}>
                                <option value="">-</option>
                                <option value={"new"}>{t.properties.new}</option>
                                <option value={"like_new"}>{t.properties.like_new}</option>
                                <option value={"excellent_condition"}>{t.properties.excellent_condition}</option>
                                <option value={"good_condition"}>{t.properties.good_condition}</option>
                                <option value={"fair_condition"}>{t.properties.fair_condition}</option>
                                <option value={"needs_renovation"}>{t.properties.needs_renovation}</option>
                                <option value={"to_renovate"}>{t.properties.to_renovate}</option>
                                <option value={"under_construction"}>{t.properties.under_construction}</option>
                                <option value={"partially_renovated"}>{t.properties.partially_renovated}</option>
                                <option value={"unfinished"}>{t.properties.unfinished}</option>
                                <option value={"move_in_ready"}>{t.properties.move_in_ready}</option>
                            </select>
                        </div>
                    </div>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.mt2}</label>
                            <input type='number' min='0' id='mt2_property' className='property_input' placeholder='NN' value={formData.mt2_property} onChange={handleInputChange}></input>
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.heating}</label>
                            <select id='heating_property' className='property_input' value={formData.heating_property} onChange={handleInputChange}>
                                <option value={""}>-</option>
                                <option value={"autonomous"}>{t.properties.autonomous}</option>
                                <option value={"centralized"}>{t.properties.centralized}</option>
                                <option value={"absent"}>{t.properties.absent}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className='property_input_cont'>

                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.floor}</label>
                            <input type='number' id='floor_property' className='property_input' placeholder='N' value={formData.floor_property} onChange={handleInputChange}></input>
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.building_floors}</label>
                            <input type='number' min='0' id='building_floors_property' className='property_input' placeholder='NN' value={formData.building_floors_property} onChange={handleInputChange}></input>
                        </div>
                    </div>
                </div>

                <div className='property_input_cont'>

                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.living}</label>
                            <input type='number' min='0' id='living_property' className='property_input' placeholder='N' value={formData.living_property} onChange={handleInputChange} />
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.bedroom}</label>
                            <input type='number' min='0' id='bedroom_property' className='property_input' placeholder='N' value={formData.bedroom_property} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.kitchen}</label>
                            <input type='number' min='0' id='kitchen_property' className='property_input' placeholder='N' value={formData.kitchen_property} onChange={handleInputChange} />
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.properties.bathroom}</label>
                            <input type='number' min='0' id='bathroom_property' className='property_input' placeholder='N' value={formData.bathroom_property} onChange={handleInputChange} />
                        </div>
                    </div>
                </div>

                <div className='property_input_cont'>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.contacts.why_look_prop}</label>
                            <input id='why_look_prop' className='property_input' placeholder='...' value={formData.why_look_prop} onChange={handleInputChange} />
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.contacts.transfer_date}</label>
                            <input type='date' min='0' id='transfer_date' className='property_input' placeholder='N' value={formData.transfer_date} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className='details_split_cont'>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.contacts.min_bgt}</label>
                            <input min='0' id='bgt_min' className='property_input' placeholder='NNNN' value={formData.bgt_min} onChange={handleInputChange} />
                        </div>
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.contacts.max_bgt}</label>
                            <input min='0' id='bgt_max' className='property_input' placeholder='NNNN' value={formData.bgt_max} onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className='details_split_cont'>
                        {/* <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.contacts.mortage}</label>
                            <div className='street_number_cont'>
                                <select id='mortage' className='property_input prop_street' value={formData.mortage} onChange={handleInputChange}>
                                    <option value={""}>-</option>
                                    <option value={"yes"}>{t.yes}</option>
                                    <option value={"no"}>{t.no}</option>
                                </select>
                                <input id='perc_mortage' className='property_input prop_number' placeholder='%' value={formData.perc_mortage} onChange={handleInputChange}></input>
                            </div>
                        </div> */}

                        {/* <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.contacts.sell_home}</label>
                            <select id='sell_house' className='property_input' value={formData.sell_house} onChange={handleInputChange}>
                                <option value={""}>-</option>
                                <option value={"yes"}>{t.yes}</option>
                                <option value={"no"}>{t.no}</option>
                            </select>
                        </div> */}
                    </div>
                </div>

                <div className='property_input_cont'>
                    <div className='details_split_cont'>
                        {/* <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.contacts.first_house}</label>
                            <select id='first_house' className='property_input' value={formData.first_house} onChange={handleInputChange}>
                                <option value={""}>-</option>
                                <option value={"yes"}>{t.yes}</option>
                                <option value={"no"}>{t.no}</option>
                            </select>
                        </div> */}
                        <div className='label_input_cont_property'>
                            <label className='property_input_section_title'>{t.contacts.notes}</label>
                            <input type='text' id='notes' className='property_input' placeholder='' value={formData.notes} onChange={handleInputChange} />
                        </div>
                    </div>
                </div>

            </div>

            <div className='footer_modal'>
                <div className='footer_btn_cont'>
                    <button className='save_modal_btn' onClick={CheckFields}>
                        {contact ? t.contacts.save_contact_changes : t.contacts.save_contact}
                    </button>
                    <button className='close_modal_btn' onClick={CleanField}>
                        {t.properties.close_btn}
                    </button>
                </div>
                <Spinner loading={loading} err={err}></Spinner>
            </div>
        </Modal>
    );
};

export default AddContactModal;
