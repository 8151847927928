import { useLanguage } from "../Translations/LenguageContext";

const ComfortOptions = () => {
    const {t} = useLanguage()

    let comfortOptions
    return  comfortOptions = {
        elevator: {
            label: t.properties.elevator,  // "Ascensore"
            icon: 'fas fa-elevator',
            category: 'interior_features'
        },
        disabled_bathroom: {
            label: t.properties.disabled_bathroom,  // "Bagno per disabili"
            icon: 'fas fa-wheelchair',
            category: 'interior_features'
        },
        full_furnishing: {
            label: t.properties.full_furnishing,  // "Arredamento completo"
            icon: 'fas fa-couch',
            category: 'additional_features'
        },
        partial_furnishing: {
            label: t.properties.partial_furnishing,  // "Arredamento parziale"
            icon: 'fas fa-couch',
            category: 'additional_features'
        },
        kitchen_furnishing: {
            label: t.properties.kitchen_furnishing,  // "Arredamento cucina"
            icon: 'fas fa-utensils',
            category: 'interior_features'
        },
        communal_garden: {
            label: t.properties.communal_garden,  // "Giardino comune"
            icon: 'fas fa-tree',
            category: 'exterior_features'
        },
        private_garden: {
            label: t.properties.private_garden,  // "Giardino privato"
            icon: 'fas fa-leaf',
            category: 'exterior_features'
        },
        sauna: {
            label: t.properties.sauna,  // "Sauna"
            icon: 'fas fa-hot-tub',
            category: 'interior_features'
        },
        disabled_elevator: {
            label: t.properties.disabled_elevator,  // "Ascensore per disabili"
            icon: 'fas fa-wheelchair',
            category: 'interior_features'
        },
        reception: {
            label: t.properties.reception,  // "Reception"
            icon: 'fas fa-concierge-bell',
            category: 'community_amenities'
        },
        external_parking: {
            label: t.properties.external_parking,  // "Parcheggio esterno"
            icon: 'fas fa-parking',
            category: 'exterior_features'
        },
        kitchen_island: {
            label: t.properties.kitchen_island,  // "Isola della cucina"
            icon: 'fas fa-concierge-bell',
            category: 'interior_features'
        },
        pantry: {
            label: t.properties.pantry,  // "Dispensa"
            icon: 'fas fa-archive',
            category: 'interior_features'
        },
        washing_machine: {
            label: t.properties.washing_machine,  // "Lavatrice"
            icon: 'fas fa-soap',
            category: 'interior_features'
        },
        dryer: {
            label: t.properties.dryer,  // "Asciugatrice"
            icon: 'fas fa-wind',
            category: 'interior_features'
        },
        closets: {
            label: t.properties.closets,  // "Armadi"
            icon: 'fas fa-door-closed',
            category: 'interior_features'
        },
        central_heating_ac: {
            label: t.properties.central_heating_ac,  // "Riscaldamento/aria condizionata centralizzati"
            icon: 'fas fa-snowflake',
            category: 'interior_features'
        },
        ceiling_fans: {
            label: t.properties.ceiling_fans,  // "Ventilatori a soffitto"
            icon: 'fas fa-fan',
            category: 'interior_features'
        },
        fireplace: {
            label: t.properties.fireplace,  // "Caminetto"
            icon: 'fas fa-fire',
            category: 'interior_features'
        },
        skylights: {
            label: t.properties.skylights,  // "Lucernari"
            icon: 'fas fa-sun',
            category: 'interior_features'
        },
        thermostats: {
            label: t.properties.thermostats,  // "Termostati"
            icon: 'fas fa-thermometer-half',
            category: 'interior_features'
        },
        security_systems: {
            label: t.properties.security_systems,  // "Sistemi di sicurezza"
            icon: 'fas fa-lock',
            category: 'interior_features'
        },
        smart_lighting: {
            label: t.properties.smart_lighting,  // "Illuminazione intelligente"
            icon: 'fas fa-lightbulb',
            category: 'interior_features'
        },
        garage: {
            label: t.properties.garage,  // "Garage"
            icon: 'fas fa-warehouse',
            category: 'exterior_features'
        },
        driveway: {
            label: t.properties.driveway,  // "Viale"
            icon: 'fas fa-road',
            category: 'exterior_features'
        },
        terrace: {
            label: t.properties.terrace,  // "Terrazza"
            icon: 'fas fa-tree',
            category: 'exterior_features'
        },
        balcony: {
            label: t.properties.balcony,  // "Balcone"
            icon: 'fas fa-mountain',
            category: 'exterior_features'
        },
        patio: {
            label: t.properties.patio,  // "Patio"
            icon: 'fas fa-chair',
            category: 'exterior_features'
        },
        swimming_pool: {
            label: t.properties.swimming_pool,  // "Piscina"
            icon: 'fas fa-swimmer',
            category: 'exterior_features'
        },
        outdoor_kitchen: {
            label: t.properties.outdoor_kitchen,  // "Cucina all'aperto o area BBQ"
            icon: 'fas fa-drumstick-bite',
            category: 'exterior_features'
        },
        fence: {
            label: t.properties.fence,  // "Recinzione"
            icon: 'fas fa-grip-lines',
            category: 'exterior_features'
        },
        landscaping: {
            label: t.properties.landscaping,  // "Paesaggistica"
            icon: 'fas fa-tree',
            category: 'exterior_features'
        },
        clubhouse: {
            label: t.properties.clubhouse,  // "Clubhouse"
            icon: 'fas fa-users',
            category: 'community_amenities'
        },
        fitness_center: {
            label: t.properties.fitness_center,  // "Centro fitness"
            icon: 'fas fa-dumbbell',
            category: 'community_amenities'
        },
        playground: {
            label: t.properties.playground,  // "Parco giochi"
            icon: 'fas fa-child',
            category: 'community_amenities'
        },
        tennis_court: {
            label: t.properties.tennis_court,  // "Campo da tennis"
            icon: 'fas fa-table-tennis',
            category: 'community_amenities'
        },
        basketball_court: {
            label: t.properties.basketball_court,  // "Campo da basket"
            icon: 'fas fa-basketball-ball',
            category: 'community_amenities'
        },
        walking_trails: {
            label: t.properties.walking_trails,  // "Sentieri pedonali"
            icon: 'fas fa-hiking',
            category: 'community_amenities'
        },
        gated_community: {
            label: t.properties.gated_community,  // "Comunità recintata"
            icon: 'fas fa-lock',
            category: 'security_features'
        },
        security_personnel: {
            label: t.properties.security_personnel,  // "Personale di sicurezza"
            icon: 'fas fa-user-shield',
            category: 'security_features'
        },
        furniture: {
            label: t.properties.furniture,  // "Mobili (se inclusi nella vendita/affitto)"
            icon: 'fas fa-couch',
            category: 'additional_features'
        },
        water_heater: {
            label: t.properties.water_heater,  // "Scaldabagno"
            icon: 'fas fa-tint',
            category: 'additional_features'
        },
        internet_cable_ready: {
            label: t.properties.internet_cable_ready,  // "Pronto per Internet/Cavo"
            icon: 'fas fa-wifi',
            category: 'additional_features'
        },
        phone_lines: {
            label: t.properties.phone_lines,  // "Linee telefoniche"
            icon: 'fas fa-phone',
            category: 'additional_features'
        },
        pet_friendly_features: {
            label: t.properties.pet_friendly_features,  // "Caratteristiche pet-friendly"
            icon: 'fas fa-paw',
            category: 'miscellaneous'
        },
        accessibility_features: {
            label: t.properties.accessibility_features,  // "Caratteristiche di accessibilità (es. rampe, porte larghe)"
            icon: 'fas fa-wheelchair',
            category: 'miscellaneous'
        },
        solar_panels: {
            label: t.properties.solar_panels,  // "Pannelli solari"
            icon: 'fas fa-solar-panel',
            category: 'miscellaneous'
        },
        home_warranty: {
            label: t.properties.home_warranty,  // "Garanzia sulla casa"
            icon: 'fas fa-shield-alt',
            category: 'miscellaneous'
        }
    };
}

export default ComfortOptions