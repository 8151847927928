import { useLanguage } from "../Translations/LenguageContext";


const ConditionsOptions = () =>{
    const {t} = useLanguage()
    let conditionsOptions
    return conditionsOptions = [
        { value: "new", label: t.properties.new },
        { value: "like_new", label: t.properties.like_new },
        { value: "excellent_condition", label: t.properties.excellent_condition },
        { value: "good_condition", label: t.properties.good_condition },
        { value: "fair_condition", label: t.properties.fair_condition },
        { value: "needs_renovation", label: t.properties.needs_renovation },
        { value: "to_renovate", label: t.properties.to_renovate },
        { value: "under_construction", label: t.properties.under_construction },
        { value: "partially_renovated", label: t.properties.partially_renovated },
        { value: "unfinished", label: t.properties.unfinished },
        { value: "move_in_ready", label: t.properties.move_in_ready },
    ];
}

export default ConditionsOptions
