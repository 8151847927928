import React, { useEffect, useRef, useState } from "react";
import { useLanguage } from "../Translations/LenguageContext";

const PropertyMarketing = () => {
    const { t } = useLanguage();
    const scrollingRef = useRef(null);
    const [scrollIndex, setScrollIndex] = useState(0);

    const backgroundImages = [
        "/IMG/roof_red_house.jpg",
        "/IMG/house2_pool.jpg",
        "/IMG/inside_house.jpg",
        "/IMG/japanese_shop.jpg",
        "/IMG/office_cubicle.jpg",
        "/IMG/land.jpg"
    ];

    const titles = [
        t.property_marketing.titles.house,
        t.property_marketing.titles.luxury_villa,
        t.property_marketing.titles.rooms_beds,
        t.property_marketing.titles.shop,
        t.property_marketing.titles.offices,
        t.property_marketing.titles.land,
    ];

    const descriptions = [
        t.property_marketing.descriptions_prop.house_desc,
        t.property_marketing.descriptions_prop.luxury_villa_desc,
        t.property_marketing.descriptions_prop.rooms_beds_desc,
        t.property_marketing.descriptions_prop.shop_desc,
        t.property_marketing.descriptions_prop.offices_desc,
        t.property_marketing.descriptions_prop.land_desc,
    ];

    useEffect(() => {
        const scroller = scrollingRef.current;
        if (scroller) {
            const elements = scroller.querySelectorAll('.property_marketing_card');
            if (elements.length > 0) {
                const elementWidth = elements[0].offsetWidth + parseInt(window.getComputedStyle(elements[0]).marginRight);
                const adjustedIndex = scrollIndex % backgroundImages.length;
                scroller.style.width = `${elements.length * elementWidth}px`;
                scroller.style.transform = `translateX(-${adjustedIndex * elementWidth}px)`;
            }
        }
    }, [scrollIndex]);

    const handleScrollLeft = () => {
        setScrollIndex(prevIndex => (prevIndex - 1 + backgroundImages.length) % backgroundImages.length);
    };

    const handleScrollRight = () => {
        setScrollIndex(prevIndex => (prevIndex + 1) % backgroundImages.length);
    };

    return (
        <div className="property_marketing">
            <p className="steps_title">{t.property_marketing.title}</p>
            <p className="steps_desc">{t.property_marketing.subtitle}</p>
            <div className="arrow_buttons">
                <button className="arrow_widget_scroller_prop" onClick={handleScrollLeft}>
                    <i className="fa-solid fa-chevron-left"></i>
                </button>
                <button className="arrow_widget_scroller_prop" onClick={handleScrollRight}>
                    <i className="fa-solid fa-chevron-right"></i>
                </button>
            </div>
            <div className="property_marketing_cont" ref={scrollingRef}>
                {backgroundImages.map((image, index) => (
                    <div key={index} className="property_marketing_card">
                        <div className="property_marketing_card_img_cont">
                            <div
                                className="property_marketing_card_img"
                                style={{
                                    backgroundImage: `url(${image})`,
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    height: "100%",
                                    width: "280px",
                                    margin: "auto"
                                }}
                            />
                        </div>
                        <div className="property_marketing_desc_cont">
                            <p className="description_title">{titles[index]}</p>
                            <p className="description_desc">{descriptions[index]}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PropertyMarketing;
