import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CompleteRegistration from "../../LLSections/CompleteRegistration/CompleteRegistration";
import { Gauge, gaugeClasses } from "@mui/x-charts";
import { FaBuildingUser } from "react-icons/fa6";
import { BsBuildingFillCheck } from "react-icons/bs";

function TenantHome({ userData, no_session }) {
    const navigate = useNavigate(); 
    const [no_home_session, SetNoHomeSession] = useState(false);
    const [registrationStatus, setRegistrationStatus] = useState(3);

    useEffect(() => {
        let isMounted = true;

        const fetchProgress = async () => {
            const user_id = sessionStorage.getItem("user_id");
            if (!user_id) {
                SetNoHomeSession(true)
                setTimeout(() => {
                    navigate(`/login`);
                }, 3000);
            }

            try {
                const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/check_registration", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                if (data.completed_steps !== undefined && isMounted) {
                    setRegistrationStatus(data.completed_steps);
                } else {
                    setRegistrationStatus(data.completed_steps)
                }
            } catch (error) {
                console.error("Error fetching registration progress:", error);
            }
        };

        fetchProgress();

        return () => {
            isMounted = false;
        };
    }, []);

    if (no_session || no_home_session) {
        return (
            <div className="main_view_section">
                <div className="no_session_cont">
                    <p className="no_session_mex">La tua sessione è scaduta... stai per essere reindirizzato nella login</p>
                    <div className='no_data_img'></div>
                </div>
            </div>
        )
    }

    const settings = {
        width: 200,
        height: 200,
        value: 60,
    };

    return (
        <div className="main_view_section">
            <div className="greetings_home_container">
                {
                    userData && (
                        <p className="title_main_view">Bentornato {userData.name}!</p>
                    )
                }
            </div>
            <div className="home_container">
                <div className="dash_container">
                    <div className="home_tenant">
                        <div className="top_home_tenant">

                        </div>
                        <div className="bottom_home_tenant">

                        </div>

                    </div>
                   

                    
                </div>
            </div>
        </div>
    );
}

export default TenantHome;
