import { useLanguage } from "../Translations/LenguageContext";


const HeatingOptions = () => {
    const {t} = useLanguage()
    let heatingOptions
    return heatingOptions = [
        { value: "autonomous", label: t.properties.autonomous },
        { value: "centralized", label: t.properties.centralized },
        { value: "absent", label: t.properties.absent },
    ];
}


export default HeatingOptions