import React, { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import { useNavigate, useParams } from "react-router-dom"; // Added useLocation
import { useLanguage } from "../Translations/LenguageContext";

const WaitlistConfirm = () => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const { waitlist_email } = useParams();
    const [showTextAndRocket, setShowTextAndRocket] = useState(false);
    const [loading, setLoading] = useState(false); // State for spinner loading
    const [loadingNo, setLoadingNo] = useState(false); // State for spinner loading

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowTextAndRocket(true);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    const handlePartnership = async () => {
        setLoading(true);

        try {

            const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/partner_save_waitlist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ waitlist_email }),
            });

            if (response.ok) {
                const result = await response.json();
                console.log("Waitlist save successful:", result);

                // Simulate a brief delay and navigate to the next screen
                setShowTextAndRocket(false);

                setTimeout(() => {
                    navigate("/check-email");
                }, 2000);
            } else {
                console.error("Failed to save waitlist:", response.statusText);
            }
        } catch (error) {
            console.error("Error in saving waitlist:", error);
        } finally {
            setLoading(false); // Stop spinner
        }
    };

    const handleDecline = async () => {
        setLoadingNo(true);

        try {

            const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/decline_partner_save_waitlist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ waitlist_email }),
            });

            if (response.ok) {
                const result = await response.json();
                console.log("Waitlist save successful:", result);
                setShowTextAndRocket(false);

                // Simulate a brief delay and navigate to the next screen
                setTimeout(() => {
                    navigate("/check-email");
                }, 2000);
            } else {
                console.error("Failed to save waitlist:", response.statusText);
            }
        } catch (error) {
            console.error("Error in saving waitlist:", error);
        } finally {
            setLoadingNo(false); // Stop spinner
        }
    };

    return (
        <div className="waitlist_confirm_main_cont">
            <div className="suprise_waitlist">
                <div className={`suprise_waitlist_cont ${showTextAndRocket ? 'show_text' : ''}`}>
                    <p className="explanation_waitlist_title">{t.waitlist_partner.free_title}</p>
                    <p className="explanation_waitlist_subtitle">{t.waitlist_partner.free_subtitle}</p>

                    <div className="explanation_waitlist">
                        <p>{t.waitlist_partner.explain_1}</p>
                        <p>{t.waitlist_partner.explain_2}</p>

                        <div className="waitlist_confirm_btn_cont">
                            <button className="waitin_list_btn_start" onClick={handlePartnership}>
                                <Spinner loading={loading} err={{ message: "", display: false }} />
                                <span>{t.waitlist_partner.become_partner_btn}</span>
                            </button>
                            <button className="waitin_list_btn_no" onClick={handleDecline}>
                                <Spinner loading={loadingNo} err={{ message: "", display: false }} />
                                <span>{t.waitlist_partner.no_btn}</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className={`suprise_waitlist_cont ${showTextAndRocket ? 'waitlist_animation' : ''}`}>
                    <div className={`rocket_img ${showTextAndRocket ? 'rocket_down' : 'rocket_up'}`}></div>
                </div>
            </div>
        </div>
    );
};

export default WaitlistConfirm;
