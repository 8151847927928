import React from "react";

const TenantsPayment = () => {
    return(
        <div>
            <p className="tenants_payment_title">Completa il tuo account!</p>
            <p className="tenants_payment_mex">Per poter registrare i tuoi inquilini devi prima completare il tuo account.</p>
            <div className="">

            </div>

        </div>
    )
}

export default TenantsPayment