import React, { useState } from "react";

const ActionsData = ({ tenantRentedProperties }) => {
    const [openDetailsMonth, setOpenDetailsMonth] = useState(false);
    console.log("tenantRentedProperties");
    console.log(tenantRentedProperties);

    const monthNames = [
        "january", "february", "march", "april",
        "may", "june", "july", "august",
        "september", "october", "november", "december"
    ];

    // Function to get ordered months starting from one month before current month, extending to one year ahead
    const getOrderedMonths = () => {
        const today = new Date();
        let month = today.getMonth(); // Current month (0-11)
        let year = today.getFullYear();

        const orderedMonths = [];

        // Start from one month before the current month
        for (let i = -1; i <= 12; i++) {
            const currentMonth = (month + i + 12) % 12;
            const currentYear = year + Math.floor((month + i) / 12);
            orderedMonths.push({ month: monthNames[currentMonth], year: currentYear });
        }

        return orderedMonths;
    };

    const orderedMonths = getOrderedMonths();

    return (
        <div className="request_cont">
            {Object.keys(tenantRentedProperties).map((key) => {
                const rentedPropActions = tenantRentedProperties[key];
                const docInformation = rentedPropActions.tenant_document_information;
                const propInfo = rentedPropActions.tenant_property_information;

                const timestamp = docInformation.timestamp;
                const date = new Date(timestamp);
                const today = new Date();

                const isToday =
                    date.getDate() === today.getDate() &&
                    date.getMonth() === today.getMonth() &&
                    date.getFullYear() === today.getFullYear();

                const formattedDate = date.toLocaleDateString("it-IT");

                let result;
                if (isToday) {
                    const time = date.getHours().toString().padStart(2, '0') + "." + date.getMinutes().toString().padStart(2, '0');
                    result = `${formattedDate} alle ${time}`;
                } else {
                    result = formattedDate;
                }

                return (
                    <>
                        <p className="request_cont_title">Azioni richieste</p>
                        <p className="request_cont_subtitle">Per poter accedere all'immobile completa le azioni richieste dal proprietario</p>
                        <div className="documents_request_cont">
                            <label><i className="fa-solid fa-plus"></i> E' stata inoltrata una richiesta di affitto il {result}</label>

                            <div
                                className={`months_payment_cont_toggle ${openDetailsMonth ? "open" : "closed"}`}
                                onClick={() => setOpenDetailsMonth(!openDetailsMonth)}
                            >
                                <p className="months_payment_cont_toggle_title">
                                    <span>Dettagli mensili</span> <i className="fa-solid fa-chevron-down"></i>
                                </p>
                                {
                                    orderedMonths.map(({ month, year }) => {
                                        const amount = propInfo.monthlyDetails.monthly_ammount[month];
                                        return amount !== undefined ? (
                                            <div className="month_to_pay" key={`${month}-${year}`}>
                                                <p className="month_to_pay_month">{`${month} ${year}`}</p>
                                                <p className="month_to_pay_amount">{amount} {propInfo.monthlyDetails.currency}</p>
                                            </div>
                                        ) : null;
                                    })
                                }
                            </div>
                        </div>
                        <div className="documents_request_cont">
                            {docInformation.uploaded_files.length > 0 && (
                                <>
                                    <label><i className="fa-solid fa-folder"></i> Scarica e firma i seguenti documenti</label>
                                    <div className="doc_cont">
                                        <div className="download_doc">
                                            <div className="to_sign_marker">
                                                <i className="fa-solid fa-pen"></i>
                                            </div>
                                            <i className="fa-solid fa-download"></i>
                                            <p> Documento 1.pdf</p>
                                        </div>
                                        <div className="download_doc">
                                            <i className="fa-solid fa-download"></i>
                                            <p> Test</p>
                                        </div>
                                        <div className="download_doc">
                                            <div className="to_sign_marker">
                                                <i className="fa-solid fa-pen"></i>
                                            </div>
                                            <i className="fa-solid fa-download"></i>
                                            <p> Documento da firmare.pdf</p>
                                        </div>
                                    </div>
                                </>
                            )}

                            <p className="requested_docs_cont_spec_title">Invia documenti</p>
                            <div className="requested_docs_cont_spec">
                                <div className="doc_single">
                                    <p>Allega tutti i documenti qui</p>
                                </div>
                            </div>
                        </div>
                        <div className="documents_request_cont">
                            <label><i className="fa-solid fa-upload"></i> Carica i documenti richiesti dal proprietario</label>
                            <div className="requested_docs_cont">

                                <p className="requested_docs_cont_spec_title">Documenti personali</p>
                                <div className="requested_docs_cont_spec">
                                    <div className="doc_front">
                                        <p>Fronte</p>

                                    </div>
                                    <div className="doc_front">
                                        <p>Retro</p>
                                    </div>
                                    <i className="fa-regular fa-circle"></i>
                                </div>

                                <p className="requested_docs_cont_spec_title">Contratto di lavoro</p>
                                <div className="requested_docs_cont_spec">
                                    <div className="doc_single">
                                        <p>Contratto di lavoro</p>
                                    </div>
                                    <i className="fa-regular fa-circle"></i>
                                </div>

                                <p className="requested_docs_cont_spec_title">Dichiarazione dei redditi</p>
                                <div className="requested_docs_cont_spec">
                                    <div className="doc_single">
                                        <p>Dichiarazione dei redditis</p>
                                    </div>
                                    <i className="fa-regular fa-circle"></i>
                                </div>

                                <p className="requested_docs_cont_spec_title">Documenti garante</p>
                                <div className="requested_docs_cont_spec">
                                    <div className="doc_front">
                                        <p>Fronte</p>

                                    </div>
                                    <div className="doc_front">
                                        <p>Retro</p>
                                    </div>
                                    <i className="fa-regular fa-circle"></i>
                                </div>

                            </div>
                        </div>
                        <button className="confirm_btn_tenant_upload">Conferma</button>
                    </>
                )
            })}
        </div>
    );
};

export default ActionsData;
