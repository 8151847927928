import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

function AboutUs() {

    return (
        <div>
            <Navbar></Navbar>
            <div className="about_us_container">
                <div className="about_us_conts">

                </div>

            </div>
            <Footer></Footer>
        </div>
    )
}

export default AboutUs