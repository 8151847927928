import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../../Translations/LenguageContext";

const FindContacts = ({ handleContactSelect, errorFields }) => {
    const { t } = useLanguage();
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedContactKey, setSelectedContactKey] = useState(null);
    const [guarantor, setGuarantor] = useState("");
    const [cf, setCf] = useState("");

    useEffect(() => {
        const userId = sessionStorage.getItem("user_id");

        const fetchContacts = async () => {
            try {
                const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_contact', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: userId }),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const contactsArray = Object.entries(data.contacts || {}).map(([key, value]) => ({
                    key,
                    ...value
                }));
                setContacts(contactsArray);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchContacts();
    }, []);

    const filteredContacts = contacts.filter(contact =>
        contact.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        contact.surname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        contact.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        contact.phone.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleContactChange = (e) => {
        const selectedKey = e.target.value;
        setSelectedContactKey(selectedKey);
        handleContactSelect({ contactKey: selectedKey, guarantor, cf });
    };

    const handleGuarantorChange = (e) => {
        const value = e.target.value;
        setGuarantor(value);
        handleContactSelect({ contactKey: selectedContactKey, guarantor: value, cf });
    };

    const handleCfChange = (e) => {
        const value = e.target.value;
        setCf(value);
        handleContactSelect({ contactKey: selectedContactKey, guarantor, cf: value });
    };

    if (loading) {
        return (
            <div className='loading_mex_cont'>
                <p className='loading_mex'>{t.loading}</p>
                <i className='fa-solid fa-box-open'></i>
            </div>
        )
    }

    if (error) {
        return (
            <div className='loading_mex_cont_err'>
                <p>{t.error}</p>
                <i className='fa-solid fa-box-open'></i>
            </div>
        );
    }

    return (
        <div className="tenant_info_cont_find_contacts">
            <div className="find_contact_tenant_cont">
                <div className="input_icon_cont">
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                        className={`${errorFields.contact_tenant ? "error_border" : ""}`}
                        type="text"
                        id="contact_tenant"
                        name="contact_tenant"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder={`${t.tenants.search_contacts}...`}
                    />
                </div>
                <select id="contacts" onChange={handleContactChange}>
                    <option value="">-</option>
                    {filteredContacts.map((contact) => (
                        <option key={contact.key} value={contact.key}>
                            {contact.name} {contact.surname} - {contact.email}
                        </option>
                    ))}
                </select>
            </div>

            <div className="tenant_info_cont_data_find_contacts">
                <div className="tenant_lab_in_cont">
                    <label className="tenant_label garant_label">{t.tenants.guarantor}</label>
                    <select
                        id="guarantor"
                        name="guarantor"
                        className={`tenant_input ${errorFields.guarantor ? "error_border" : ""}`}
                        value={guarantor}
                        onChange={handleGuarantorChange}
                    >
                        <option value={""}>-</option>
                        <option value={"yes"}>{t.yes}</option>
                        <option value={"no"}>{t.no}</option>
                    </select>
                </div>

                <div className="tenant_lab_in_cont">
                    <label className="tenant_label garant_label">Codice Fiscale</label>
                    <input
                        id="cf"
                        name="cf"
                        className={`tenant_input ${errorFields.cf ? "error_border" : ""}`}
                        value={cf}
                        onChange={handleCfChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default FindContacts;
