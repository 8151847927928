import React, { useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { TbTransformFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { HiMiniPaintBrush } from "react-icons/hi2";
import { HiSparkles } from "react-icons/hi";

const Screening = ({ userData }) => {
    const { t } = useLanguage()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleNewClick = () => {
        navigate("/control_panel/screening/new-screen")
    }


    if (loading) return (
        <div className='loading_mex_cont'>
            <p className='loading_mex'>{t.loading}</p>
            <i className='fa-solid fa-box-open'></i>
        </div>
    );

    if (error) return (
        <div className='loading_mex_cont_err'>
            <p>{t.error}</p>
            <i className='fa-solid fa-box-open'></i>
        </div>
    );

    return (
        <div className="main_view_section">
            <p className="title_main_view">Screening</p>
            <p className="subtitle_main_view">Lo screening ti consente di valutare tutti i potenziali inquilini tramite un unico link e ti suggerisce quello più adatto.</p>
            <div className="screening_cont">
                <p className="screening_blocks_cont_title">Pre-build</p>
                <div className="screening_blocks_cont">
                    <div className="screening_block">
                        <div className="screening_block_inner">
                            <p className="screening_block_inner_title">Standard  <i><TbTransformFilled /></i></p>
                            <p className="screening_block_inner_subtitle">Simple, quick screening for basic tenant suitability</p>
                        </div>
                        <button className="screening_block_btn">Get Link</button>
                    </div>
                    <div className="screening_block">
                        <div className="screening_block_inner">
                            <p className="screening_block_inner_title">Mild <i><TbTransformFilled /></i></p>
                            <p className="screening_block_inner_subtitle"> Covers essential financial and rental history</p>
                        </div>
                        <button className="screening_block_btn">Get Link</button>
                    </div>
                    <div className="screening_block">
                        <div className="screening_block_inner">
                            <p className="screening_block_inner_title">Super <i><TbTransformFilled /></i></p>
                            <p className="screening_block_inner_subtitle">Designed to minimize risk and ensure the tenant is highly reliable</p>
                        </div>
                        <button className="screening_block_btn">Get Link</button>
                    </div>
                    <div className="screening_block ai_block_screen">
                        <div className="screening_block_inner ai_block_screen_inner">
                            <p className="screening_block_inner_title ai_block_title">AI <i><HiSparkles /></i></p>
                            <p className="screening_block_inner_subtitle">Leverages advanced AI algorithms to provide the most efficient tenant evaluation</p>
                        </div>
                    </div>
                </div>
            </div>
            <p className="screening_blocks_cont_title">Custom</p>
            <div className="screening_cont">
                <div className="screening_blocks_cont">
                    <div className="screening_block">
                        <div className="screening_block_inner">
                            <p className="screening_block_inner_title">Custom ex <i><HiMiniPaintBrush /></i></p>
                            <p className="screening_block_inner_subtitle">Custom exemple</p>
                        </div>
                    </div>

                    <div className="plus_block" onClick={handleNewClick}>
                        <div className="plus_block_inner">
                            <i className="fa-solid fa-plus"></i>
                            <p className="screening_block_inner_title"> Add new screen</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Screening